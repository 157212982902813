import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import React from 'react';

const BackArrow = () => {
    const navigate = useNavigate();

    return (
        <>
            <IconButton sx={{ color: 'darkMode.500' }} onClick={() => navigate(-1)} size='small'><ArrowBackIcon /></IconButton>
        </>
    );
}

export default BackArrow;