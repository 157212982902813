import React from 'react';
import { Button, IconButton, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ReactComponent as MinimizeScreen } from "../../icons/MinimizeScreen.svg";
import { ReactComponent as CloseCross } from "../../icons/CloseCross.svg";


function PatternViewModal({ isShowed, handleCloseModal, handleMinimizeModal, pdfBase64 }) {


    return (
        <Modal open={isShowed} onClose={handleMinimizeModal}>
            <Box sx={{ width: "80vw", height: "90vh", padding: "0px", paddingTop: 1 }} className='modal' display={'flex'} flexDirection={'column'} >
                <Box display={'flex'} justifyContent={'flex-end'}>

                    <Box mb={1} mr={1}>
                        <IconButton onClick={handleMinimizeModal}>
                            <MinimizeScreen />
                        </IconButton>
                        <IconButton onClick={handleCloseModal}>

                            <CloseCross />
                        </IconButton>

                    </Box>
                </Box>
                <embed id="pdfDocument" type="application/pdf" width='100%' height='100%' src={'data:application/pdf;base64, ' + encodeURI(pdfBase64) + "#toolbar=1&navpanes=0&scrollbar=0'></embed></body></html>"} ></embed>

            </Box>
        </Modal>

    )
}

export default PatternViewModal;