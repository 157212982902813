import React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

//severity; error, warning, info, success

export default function PopupAlert(props) {
  //const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.setOpen(false);
  };
  return (
    <Stack
      spacing={2}
      sx={{ width: '100%' }}>
      <Snackbar
        open={props.open}
        onClose={handleClose}
        autoHideDuration={6000}>
        <Alert
          onClose={handleClose}
          variant="filled"
          severity={props.severity}
          sx={{ width: '100%' }}>
          {props.text}
        </Alert>
      </Snackbar>
    </Stack>
  )
} 
