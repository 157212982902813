import { DatePicker, DateTimePicker } from "@mui/lab";
import { Autocomplete, Button, Card, CardContent, createFilterOptions, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { apiService } from "../../services/api.service";
import { CustomHooksService } from "../../services/customHooks.serivce";
import { ReactComponent as CheckCircleOutline } from "../../assets/icons/CheckCircleOutline.svg";
import { ReactComponent as Block } from "../../assets/icons/Block.svg";
import { getIdPrac } from '../../redux/idPracFetch';
import ModalNeiobZast from "../modals/ModalNieobZast";
import moment from "moment";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    select: {
        "& .MuiSvgIcon-root": {
            color: "#8F9BBC",
        },
    },
});

function ZlozWniosekCard({ dateErrors, setDateErrors, dateRange, setDateRange, rodzajeNieobecnosci, listaZastepstw, addListaMoichWnioskow, setOpenPopup, przelozeni, convertDateToString }) {
    const activeUmowa = getIdPrac();
    const [formData, setFormData] = useState({
        typWniosku: 0,
        kodRodzajuNieobecnosci: '',
        idPracZastepstwo: 0,
        sendMail: false,
        sendMailTo: 0,
        opis: ""
    })
    const [dataRozpoczeciaDefault, setDataRozpoczeciaDefault] = useState();
    const [dataZakonczeniaDefault, setDataZakonczeniaDefault] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [nieobZast, setNieobZast] = useState([]);
    const [isWniosekSending, setIsWniosekSending] = useState(false);
    const [resetAutoComplete, setResetAutoComplete] = useState(false);
    const [isWniosekAdded, setIsWniosekAdded] = useState(false);
    const [isWniosekNotAdded, setIsWniosekNotAdded] = useState(false);
    const [responseErrors, setResponseErrors] = useState("");
    const [formErrors, setFormErrors] = useState({
        typWniosku: false,
        kodRodzajuNieobecnosci: false,
        zastepstwoError: false,

    })

    const classes = useStyles();


    useEffect(() => {
        setDates();
    }, [])

    CustomHooksService.useDidMountEffect(() => {
        addWniosek(); //dodawanie wniosku jest w useEffect aby mieć poprawną wartość formErrors
    }, [formErrors])

    const validate = values => {
        if (values.kodRodzajuNieobecnosci === '' && values.typWniosku === 0) {
            setFormErrors({ ...formErrors, kodRodzajuNieobecnosci: true })
        } else {
            setFormErrors({ ...formErrors, kodRodzajuNieobecnosci: false })
        }
    };

    const setDates = () => {
        let date = new Date();
        date.setHours(0, 0, 0, 0);
        setDataRozpoczeciaDefault(date);
        setDataZakonczeniaDefault(date);
        // document.getElementById("datetime-rozpoczecie").value = convertDateToString(new Date());
        // document.getElementById("datetime-zakonczenie").value = convertDateToString(new Date());
    }

    const handleTypWnioskuChange = (event) => {
        let data = Object.assign({}, formData);
        data.typWniosku = event.target.value
        if (event.target.value === 0) {
            data.kodRodzajuNieobecnosci = 0;
        }
        setFormData(data);
    };

    const handleRodzajNieobecnosciChange = (event) => {
        let data = Object.assign({}, formData);
        data.kodRodzajuNieobecnosci = event.target.value
        setFormData(data);
    };

    const handleZastepstwoChange = (event, newValue) => {

        let data = Object.assign({}, formData);
        if (newValue === null) {
            data.idPracZastepstwo = 0;
        }
        else {
            data.idPracZastepstwo = newValue.idPrac
        }
        setFormData(data);
    };

    const handleMailToPrzelozony = (event, newValue) => {
        let data = Object.assign({}, formData);
        if (newValue === null) {
            data.sendMailTo = '';
            data.sendMail = false;
        } else {
            data.sendMailTo = newValue.idPrac;
            data.sendMail = true;
        }
        setFormData(data);
    }

    const handleOpisChange = (event) => {
        let data = Object.assign({}, formData);
        data.opis = event.target.value
        setFormData(data);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsWniosekAdded(false);
        setIsWniosekNotAdded(false);
        validate(formData); //reszta handlera w useEffect
    }

    const resetForm = () => {
        let data = Object.assign({}, formData);
        data.typWniosku = 0;
        data.idPracZastepstwo = 0;
        data.kodRodzajuNieobecnosci = 0;
        data.opis = "";
        setResetAutoComplete(!resetAutoComplete);
        setFormData(data);
    }

    const addWniosek = () => {
        let isFormInvalid = Object.values(formErrors).every(item => item === false)
        if (isFormInvalid) {
            setIsWniosekSending(true);
            let dataOd = moment(document.getElementById("datetime-rozpoczecie").value).toDate();
            let dataDo = moment(document.getElementById("datetime-zakonczenie").value).toDate();
            let hhmmOd = moment(dataOd).format("HH:mm").toString()
            let hhmmDo = moment(dataDo).format("HH:mm").toString()
            let idPracZastepstwo = formData['idPracZastepstwo']
            let opis = formData['opis']
            let kodRodzajuNieobecnosci = formData['kodRodzajuNieobecnosci']
            let typWniosku = formData['typWniosku']
            let sendMail = formData['sendMail']
            let sendMailTo = formData['sendMailTo']
            if (idPracZastepstwo === null) {
                idPracZastepstwo = 0;
            }
            let checkZastepstwo = { DataOdWniosku: dataOd, DataDoWniosku: dataDo, hhmmOd: hhmmOd, hhmmDo: hhmmDo, IdPrac: idPracZastepstwo, "wymagZast": true }
            apiService.getNieobecnosciZastepujacego(checkZastepstwo).then(response => {
                setNieobZast(response.data)

                if (response.data.length === 0) {
                    let wniosek = { idPrac: activeUmowa, dataOd, dataDo, typWniosku, hhmmOd: hhmmOd, hhmmDo: hhmmDo, idPracZastepstwo, opis, kodRodzajuNieobecnosci, sendMail, sendMailTo };
                    apiService.addWniosek(wniosek).then(() => {
                        addListaMoichWnioskow();
                        setIsWniosekAdded(true);
                        setOpenPopup(true);
                        resetForm();
                    }, err => {
                        setIsWniosekNotAdded(true);
                        if (err.response.data)
                            setResponseErrors(err.response.data)
                        console.log(err);
                    }).finally(() => {
                        setIsWniosekSending(false);
                    })
                } else {
                    setOpenModal(true);
                    setIsWniosekSending(false);
                }
            })
        }
    }

    const getDefaultDateString = () => {
        let date = new Date();
        date.setHours(0, 0, 0, 0);
        //let dateString = date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate() + "T00:00";
        let dateString = (new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()).slice(0, -1);

        return dateString;
    }


    const convertStringToDate = (stringDate) => {
        let date = moment(stringDate);

        return date.toDate();


    }

    const handleDataOd = (stringDate) => {
        try {
            let date = moment(stringDate).toDate();
            //setDataRozpoczecia(date)
            setDateErrors({ ...dateErrors, dataOd: false })

            //ERRORY NA RAZIE WYKOMENTOWANE W JSX
            if (isNaN(date)) {
                setDateErrors({ ...dateErrors, dataOd: true })
                date = dataRozpoczeciaDefault;
                document.getElementById("datetime-rozpoczecie").value = convertDateToString(date);
            }
            else if (date.getFullYear() > 2100) {
                setDateErrors({ ...dateErrors, dataOd: true })
                date.setFullYear(2100)
                document.getElementById("datetime-rozpoczecie").value = convertDateToString(date);
            }


            let dates = dateRange.slice();      //shallow copy of an array

            dates[0] = date;
            setDateRange(dates);


        }
        catch (exc) {
            setDateErrors({ ...dateErrors, dataOd: true })

        }
    }
    const handleDataDo = (stringDate) => {
        try {
            let date = moment(stringDate).toDate();
            setDateErrors({ ...dateErrors, dataDo: false })

            //ERRORY NA RAZIE WYKOMENTOWANE W JSX
            if (isNaN(date)) {
                setDateErrors({ ...dateErrors, dataDo: true })
                date = dataZakonczeniaDefault;
                document.getElementById("datetime-zakonczenie").value = convertDateToString(date);
            }
            else if (date.getFullYear() > 2100) {
                setDateErrors({ ...dateErrors, dataDo: true })
                date.setFullYear(2100)
                document.getElementById("datetime-zakonczenie").value = convertDateToString(date);
            }


            let dates = dateRange.slice();      //shallow copy of an array
            dates[1] = date;
            setDateRange(dates);

        }
        catch (exc) {
            setDateErrors({ ...dateErrors, dataDo: true })
        }
    }


    return (
        <React.Fragment>
            <Grid container item lg={4} md={12}>
                <Grid item md={12}>
                    <>
                        <Card elevation={2} sx={{ minWidth: "200px" }}>
                            <CardContent >
                                <Grid className="wniosekFormInput" item md={12}>
                                    <Typography mt={2} variant="h1" fontWeight={'bold'}>Złóż wniosek</Typography>
                                </Grid>
                                <Grid container item spacing={3} md={12} alignItems="center" justifyContent="center" sx={{ margin: 'auto', width: "100%" }}>
                                    {/* <Grid className="wniosekFormInput" item md={12} >
                                        <DatePicker
                                            mask="__/__/____"
                                            label="Data rozpoczęcia"
                                            inputFormat="dd/MM/yyyy"
                                            value={dataRozpoczecia}
                                            onChange={(newValue) => {
                                                setDataRozpoczecia(newValue);
                                            }}
                                            renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                                        />
                                    </Grid>
                                    <Grid className="wniosekFormInput" item md={12} >
                                        <DatePicker
                                            mask="__/__/____"
                                            label="Data zakończenia"
                                            inputFormat="dd/MM/yyyy"
                                            value={dataZakonczenia}
                                            onChange={(newValue) => {
                                                setDataZakonczenia(newValue);
                                            }}
                                            renderInput={(params) => <TextField size="small" fullWidth  {...params} />}
                                        />
                                    </Grid> */}
                                    {/* <Grid className="wniosekFormInput" item md={12} >   //WERSJA Z ZEGAREM
                                        <DateTimePicker
                                            openTo="hours"
                                            showToolbar={false}

                                            mask="__/__/____ __:__"
                                            label="Data rozpoczęcia"
                                            inputFormat="dd/MM/yyyy HH:mm"
                                            value={dataRozpoczecia}

                                            onChange={(newValue) => {

                                                let date = new Date(newValue);
                                                date.setDate(dataRozpoczecia.getDate())
                                                date.setMonth(dataRozpoczecia.getMonth())
                                                date.setFullYear(dataRozpoczecia.getFullYear());
                                                setDataRozpoczecia(date);
                                            }}

                                            renderInput={(params) => <TextField fullWidth {...params} />}
                                        />
                                    </Grid>
                                    <Grid className="wniosekFormInput" item md={12} >
                                        <DateTimePicker
                                            openTo="hours"
                                            showToolbar={false}
                                            mask="__/__/____ __:__"
                                            label="Data zakończenia"
                                            inputFormat="dd/MM/yyyy HH:mm"
                                            value={dataZakonczenia}
                                            onChange={(newValue) => {
                                                let date = new Date(newValue);
                                                date.setDate(dataZakonczenia.getDate())
                                                date.setMonth(dataZakonczenia.getMonth())
                                                date.setFullYear(dataZakonczenia.getFullYear());
                                                setDataZakonczenia(date);
                                            }}
                                            renderInput={(params) => <TextField fullWidth  {...params} />}
                                        />
                                    </Grid> */}
                                    {(dataRozpoczeciaDefault && dataZakonczeniaDefault) && <>
                                        <Grid className="wniosekFormInput wniosekCalendarInput" item md={12} >
                                            <TextField
                                                sx={{
                                                    '& input[type="datetime-local"]::-webkit-calendar-picker-indicator': {
                                                        filter:
                                                            'invert(77%) sepia(4%) saturate(3357%) hue-rotate(190deg) brightness(85%) contrast(67%)',
                                                    }, width: '100%',

                                                }}
                                                // error={dateErrors.dataOd}
                                                // helperText={dateErrors.dataOd && 'Nieprawidłowa data'}
                                                id="datetime-rozpoczecie"
                                                label="Data rozpoczęcia"
                                                type="datetime-local"
                                                onBlur={(e) => {
                                                    handleDataOd(e.target.value);
                                                }}
                                                defaultValue={convertDateToString(dataRozpoczeciaDefault)}
                                                inputProps={{
                                                    min: convertDateToString(new Date())
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid className="wniosekFormInput wniosekCalendarInput" item md={12} >
                                            <TextField
                                                id="datetime-zakonczenie"
                                                label="Data zakończenia"
                                                type="datetime-local"
                                                // error={dateErrors.dataDo}
                                                // helperText={dateErrors.dataDo && 'Nieprawidłowa data'}
                                                onBlur={(e) => {
                                                    handleDataDo(e.target.value);

                                                }}
                                                defaultValue={convertDateToString(dataZakonczeniaDefault)}
                                                sx={{
                                                    '& input[type="datetime-local"]::-webkit-calendar-picker-indicator': {
                                                        filter:
                                                            'invert(77%) sepia(4%) saturate(3357%) hue-rotate(190deg) brightness(85%) contrast(67%)',
                                                    }, width: '100%'
                                                }}
                                                inputProps={{
                                                    min: convertDateToString(new Date())
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </>}
                                    {/* {(dataRozpoczecia && dataZakonczenia) && <>
                                        <Grid className="wniosekFormInput" item md={12} >
                                            <TextField
                                                id="datetime-rozpoczecie"
                                                label="Data rozpoczęcia"
                                                type="datetime-local"
                                                onChange={(e) => {
                                                    let date = convertStringToDate(e.target.value);
                                                    setDataRozpoczecia(date);
                                                }}
                                                value={convertDateToString(dataRozpoczecia)}
                                                sx={{ width: '100%' }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid className="wniosekFormInput" item md={12} >
                                            <TextField
                                                id="datetime-rozpoczecie"
                                                label="Data zakończenia"
                                                type="datetime-local"
                                                onChange={(e) => {
                                                    let date = convertStringToDate(e.target.value);
                                                    setDataZakonczenia(date);
                                                }}
                                                value={convertDateToString(dataZakonczenia)}
                                                sx={{ width: '100%' }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </>} */}
                                    <Grid className="wniosekFormInput" item md={12} >
                                        <Box >
                                            <FormControl fullWidth size="small">
                                                <InputLabel id="typWniosku">Typ</InputLabel>
                                                <Select
                                                    className={classes.select}
                                                    error={formData['typWniosku'] === null}
                                                    required
                                                    labelId="typWniosku"
                                                    name="typWniosku"
                                                    id="typWnioskuSelect"
                                                    value={formData.typWniosku}
                                                    label="Typ"
                                                    onChange={handleTypWnioskuChange}
                                                >
                                                    <MenuItem sx={{ margin: '5px', borderRadius: '5px' }} value={0}>Urlop</MenuItem>
                                                    <MenuItem sx={{ margin: '5px', borderRadius: '5px' }} value={1}>Nadgodziny</MenuItem>
                                                    <MenuItem sx={{ margin: '5px', borderRadius: '5px' }} value={2}>Wyjście prywatne</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    {(formData.typWniosku === 0 && rodzajeNieobecnosci) ?
                                        <Grid className="wniosekFormInput" item md={12} >
                                            <Box >
                                                <FormControl size="small" fullWidth error={formErrors.kodRodzajuNieobecnosci}>
                                                    <InputLabel shrink={formData.kodRodzajuNieobecnosci != 0} id="kodRodzajuNieobecnosci">Rodzaj urlopu</InputLabel>
                                                    <Select
                                                        label={formData.kodRodzajuNieobecnosci != 0 ? "RodzajNieobecnosci" : null}

                                                        className={classes.select}
                                                        error={formErrors.kodRodzajuNieobecnosci}
                                                        required
                                                        labelId="kodRodzajuNieobecnosci"
                                                        id="kodRodzajuNieobecnosciSelect"
                                                        name="kodRodzajuNieobecnosci"
                                                        value={formData.kodRodzajuNieobecnosci}
                                                        onChange={handleRodzajNieobecnosciChange}

                                                    >
                                                        {/* <MenuItem value={0}>Wybierz rodzaj nieobecności</MenuItem> */}
                                                        {rodzajeNieobecnosci.map((item) => {
                                                            return (
                                                                <MenuItem sx={{ margin: '5px', borderRadius: '5px' }} key={item.kod} value={item.kod}>{item.nazwa}</MenuItem>
                                                            )
                                                        })
                                                        }
                                                    </Select>
                                                    {formErrors.kodRodzajuNieobecnosci && <FormHelperText sx={{ fontSize: "13px" }}>Pole wymagane</FormHelperText>}
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        : ""}
                                    <Grid className="wniosekFormInput" item md={12} >
                                        {listaZastepstw && <Autocomplete
                                            className={classes.select}
                                            renderOption={(props, option) => {
                                                return <Box sx={{ margin: '5px', borderRadius: '5px' }} component="li" {...props} key={option.idPrac + option.label}>
                                                    {option.label} - {option.idPrac}
                                                </Box>
                                            }}
                                            onChange={(event, newValue) => {
                                                handleZastepstwoChange(event, newValue);
                                            }}
                                            filterOptions={createFilterOptions({
                                                stringify: (option) => option.label + " - " + option.idPrac,
                                            })}
                                            size="small"
                                            disablePortal
                                            key={resetAutoComplete}
                                            id="zastepstwoAutoComplete"
                                            name="idPracZastepstwo"
                                            options={listaZastepstw}
                                            renderInput={(params) => <TextField error={openModal} helperText={openModal ? 'Osoba nie jest dostępna w wybranym terminie.' : null} {...params} label="Zastępca" />}
                                        />
                                        }
                                    </Grid>
                                    <Grid className="wniosekFormInput" item md={12} >
                                        {przelozeni && <Autocomplete
                                            className={classes.select}
                                            renderOption={(props, option) => {
                                                return <Box sx={{ margin: '5px', borderRadius: '5px' }} component="li" {...props} key={option.idPrac + option.nazwisko}>
                                                    {option.imie} {option.nazwisko} - {option.idPrac}
                                                </Box>
                                            }}
                                            getOptionLabel={(option) => `${option.imie} ${option.nazwisko} - ${option.idPrac}` || ""}
                                            // getOptionLabel={(option) => (`${option.imie} ${option.nazwisko} - ${option.idPrac}`)}
                                            onChange={(event, newValue) => {
                                                handleMailToPrzelozony(event, newValue);
                                            }}
                                            size="small"
                                            disablePortal
                                            key={resetAutoComplete}
                                            id="przelozonyAutoComplete"
                                            name="sendMailTo"
                                            options={przelozeni}
                                            renderInput={(params) => <TextField  {...params} label="Wyślij powiadomienie E-mail" placeholder="Przełożony" />}

                                        />}
                                    </Grid>
                                    <Grid className="wniosekFormInput" item md={12} >
                                        <TextField size="small" fullWidth id="outlined-basic" multiline label="Komentarz" variant="outlined" name="opis" value={formData.opis} onChange={(e) => handleOpisChange(e)} />

                                    </Grid>
                                    <Grid className="wniosekFormInput" item md={12} >
                                        <Button fullWidth sx={{ height: "56px" }} variant="contained" type="submit" onClick={(event) => handleSubmit(event)}>Złóż wniosek</Button>

                                    </Grid>
                                    {isWniosekSending &&
                                        <Grid container item md={12}>
                                            <Grid item md={12}><Typography variant="h4">Wysyłanie...</Typography></Grid>
                                        </Grid>
                                    }
                                    {openModal &&
                                        <Grid container item md={12}>
                                            <Grid className="wniosekFormInput" item md={1} >
                                                <Block />
                                            </Grid>
                                            <Grid item md={10}>
                                                <Typography variant="h4" color="error.main">
                                                    Osoba zastępująca będzie nieobecna w pracy!
                                                </Typography >
                                            </Grid>

                                        </Grid>
                                    }
                                    {isWniosekAdded &&
                                        <Grid container item md={12}>
                                            <Grid className="wniosekFormInput" item md={1} >
                                                <CheckCircleOutline />
                                            </Grid>
                                            <Grid className="wniosekFormInput" item md={11} >
                                                <Typography variant="h4" color="success.main">
                                                    Twój wniosek został pomyślnie zgłoszony do akceptacji.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                    {isWniosekNotAdded &&
                                        <Grid container item md={12}>
                                            <Grid className="wniosekFormInput" item md={1} >
                                                <Block />
                                            </Grid>
                                            <Grid className="wniosekFormInput" item md={11} >
                                                <Typography variant="h4" color="error.main">
                                                    Twój wniosek nie został zgłoszony do akceptacji.
                                                </Typography >
                                                <Typography variant="h4" color="error.main">
                                                    {responseErrors}
                                                </Typography >
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                    </>
                </Grid>
            </Grid>
            {(document.getElementById("datetime-rozpoczecie") && document.getElementById("datetime-zakonczenie")) ?
                <ModalNeiobZast openModal={openModal} setOpenModal={setOpenModal}
                    dataRozpoczecia={moment(document.getElementById("datetime-rozpoczecie").value).toDate()}
                    dataZakonczenia={moment(document.getElementById("datetime-zakonczenie").value).toDate()}
                    typWniosku={formData['typWniosku']} idPrac={activeUmowa} idPracZastepstwo={formData['idPracZastepstwo']}
                    opis={formData['opis']} kodRodzajuNieobecnosci={formData['kodRodzajuNieobecnosci']}
                    addListaMoichWnioskow={addListaMoichWnioskow} resetForm={resetForm} setIsWniosekAdded={setIsWniosekAdded}
                    setOpenPopup={setOpenPopup} setIsWniosekSending={setIsWniosekSending} setIsWniosekNotAdded={setIsWniosekNotAdded}
                    setResponseErrors={setResponseErrors} nieobZast={nieobZast} sendMail={formData['sendMail']} sendMailTo={formData['sendMailTo']} />
                : ""}
        </React.Fragment>

    )
}

export default ZlozWniosekCard;