import React, { Component } from 'react';
import PatternService from '../services/pattern-service/patternService';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LoadingSpinner from './loading-spinner/loadingSpinner'
let patternService;

const patternPrinterURL = "/wzorce/patternPrinter/";

class PatternsDropdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patterns: [],
            base64EncodedPDF: '',
            name: '',
            printURL: patternPrinterURL,
        }


    }

    componentDidMount() {
        this.getPatterns();

    }

    openPrintDocument() {
        //this.getDocument();

        let pdfWindow = window.open(this.state.printURL + this.state.name)

       setTimeout(() => {   

       pdfWindow.document.write("<html<head><title>"+ this.state.name +"</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
       pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(this.state.base64EncodedPDF)+"#toolbar=0&navpanes=0&scrollbar=0'></embed></body></html>");

   }, 1); 


   }

   getDocument(id_Pattern) {
    PatternService.getInstance(this.props.apiurl, this.props.apiAuthHeader).getConvertedPatternWithData(id_Pattern, this.props.printParams).then(response => {
        this.setState({
            base64EncodedPDF: response.data.base64EncodedPdf,
            name: response.data.name
        })
        console.log(response)
        this.openPrintDocument();
    })
        .catch(error => {
            console.log(error);
        });

}
    async getPatterns() {
        PatternService.getInstance(this.props.apiurl).getAllPatternsByIdSystemu().then(response => {
            this.setState({
                patterns: response.data
            })
        })
            .catch(error => {
                console.log(error);
            })
    }

    onItemClick(id_Pattern){

        this.getDocument(id_Pattern);
        //this.openPrintDocument();


    }

    render() {
        const { patterns } = this.state;

        return (

            <div>
                <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        Wzorce
                     </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {patterns.map(pattern =>
                            <Dropdown.Item key={pattern.id_Pattern} onClick={() => this.onItemClick(pattern.id_Pattern)}>{pattern.name}</Dropdown.Item>
                        )}


                    </Dropdown.Menu>
                </Dropdown>
            </div>


        )
    }
}

export default PatternsDropdown;