import { TreeItem } from "@mui/lab";
import { styled } from "@mui/system";

export const StyledTreeItem = styled(TreeItem)(() => {
    return {
        ".MuiTreeItem-content .MuiTreeItem-label": {
            fontWeight: 'bold'
        },
        // ".MuiTreeItem-root:hover": {
        //     backgroundColor: '#fff !important'

        // },
        // ".MuiTreeItem-content.Mui-selected": {
        //     backgroundColor: '#fff'
        // },
        // ".MuiTreeItem-content.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label": {
        //     backgroundColor: '#fff'
        // },
        // ".MuiTreeItem-content.Mui-selected.Mui-focused": {
        //     backgroundColor: '#fff'

        // },

        ".Mui-selected svg path": {
            color: '#273B4A',
            fill: '#273B4A'
        },
        ".MuiTreeItem-content": {
            paddingTop: '12px',
            paddingBottom: '12px'
        }

    };
});