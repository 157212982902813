import React from 'react';
import { Avatar, Card, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import '../../styles/twojeKonto.css';
import DaneOsoboweZatrudnienia from './DaneOsoboweIZatrudnienia.js';
import { Converter } from '../../submodules/template/services/Converter';

function InfoUzytkownikaCard({ daneUzytkownika, foto, zatrudnienie, histZatrudnienia }) {

    return (
        <Box mb={3}>
            <Card elevation={2} className='calyProfil' sx={{ borderRadius: '4px 4px 0 0', borderBottom: 1, borderBottomColor: 'neutral.200' }}>
                <Box className='daneProfilu'>
                    <Stack ml={4} mt={2}>
                        <Typography mt={3} fontWeight={'bold'} variant='h2'>
                            {`${Converter.onlyFirstLetterCapital(daneUzytkownika.imie)} ${Converter.onlyFirstLetterCapital(daneUzytkownika.nazwisko)}`}
                        </Typography>
                        <Typography variant='h4' mt={0.5} color={'neutral.600'}>
                            {Converter.onlyFirstLetterCapital(zatrudnienie[0].stanowiskoPracownika)}
                        </Typography>
                        {(daneUzytkownika.email) ? (<Typography variant='h4' color={'neutral.600'} mt={3} >{daneUzytkownika.email}</Typography>) : null}
                        {(daneUzytkownika.telefon) ? (<Typography variant='h4' color={'neutral.600'} mt={1} >{daneUzytkownika.telefon}</Typography>) : null}
                    </Stack>
                    <Avatar sx={{ width: 90, height: 90, marginTop: '37px', marginRight: '32px', marginBottom: '37px' }} src={`data:image/png;base64,${foto}`} />
                </Box>
            </Card>
            <DaneOsoboweZatrudnienia dane={daneUzytkownika} histZatrudnienia={histZatrudnienia} />
        </Box>
    )
};

export default InfoUzytkownikaCard;

