import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import PomocCard from '../modules/cards/PomocCard';
import phone from '../assets/phone.png';
import mail from '../assets/mail.png';
import BackArrow from '../components/BackArrow';

function CentrumPomocy() {
    return (
        <>
            <Box mb={5} display={'flex'} alignItems={'center'}><BackArrow /><Typography sx={{ fontWeight: 'bold' }} variant="h1">Centrum Pomocy</Typography></Box>

            <Grid container spacing={5} alignItems="stretch" direction="row">
                <Grid item >
                    <PomocCard
                        imageUrl={phone}
                        title="Infolinia"
                        content="Zadzwoń do nas i zgłoś problem"
                        href="tel:22 784 49 44"
                        text="22 784 49 44"

                    />
                </Grid>
                <Grid item >
                    <PomocCard
                        imageUrl={mail}
                        title="Email"
                        content="Napisz email i wygodnie zgłoś usterkę"
                        href="mailto:serwis@groszek.pl"
                        text="serwis@groszek.pl"
                    />
                </Grid>

            </Grid>
        </>
    );
}
export default CentrumPomocy;