import React, { useState } from "react";
import { apiService } from "../../services/api.service";
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, FormControl, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as ExpandIcon } from "../../assets/icons/Expand_up.svg";
import { ReactComponent as Eye } from "../../assets/icons/Eye_password.svg";
import { ReactComponent as CheckCircleOutline } from "../../assets/icons/CheckCircleOutline.svg";
import { ReactComponent as Block } from "../../assets/icons/Block.svg";

function HasloAccordion() {
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [responseErrors, setResponseErrors] = useState(null);
    const [isPasswordChanged, setIsPasswordChanged] = useState(false);
    const [isPasswordSending, setIsPasswordSending] = useState(false);

    const [formData, setFormData] = useState({
        currentPassword: "",
        newPassword: "",
        repeatPassword: ""
    })

    const handleCurrentPassword = (event) => {
        let data = Object.assign({}, formData);
        data.currentPassword = event.target.value
        setFormData(data);
    }

    const handleNewPassword = (event) => {
        let data = Object.assign({}, formData);
        data.newPassword = event.target.value
        setFormData(data);
    }

    const handleRepeatPassword = (event) => {
        let data = Object.assign({}, formData);
        data.repeatPassword = event.target.value
        setFormData(data);
    }

    const onSubmit = (event) => {
        setIsPasswordChanged(false);
        setResponseErrors(null);
        let idKadr = JSON.parse(localStorage.getItem('ekadry2_LoginResponse')).koD_OPER;
        let model = { KOD_OPER: idKadr, OldPassword: formData.currentPassword, NewPassword: formData.newPassword, ConfirmPassword: formData.repeatPassword }
        sendPassword(model);
        let data = Object.assign({}, formData);
        setFormData(data);
    }

    const sendPassword = (model) => {
        setIsPasswordSending(true)
        apiService.changePassword(model).then(res => {
            setIsPasswordChanged(true);
            if (res.status === 200) {
                setFormData({
                    currentPassword: "",
                    newPassword: "",
                    repeatPassword: ""
                })
            }
        }, err => {
            console.log(err);
            if (err.response.data.length > 0) {
                setResponseErrors(err.response.data);
            }
        }).finally(() => {
            setIsPasswordSending(false)

        })
    }

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography variant="h3" m={2} fontWeight={'700'}>Hasło</Typography>
            </AccordionSummary>
            <AccordionDetails>

                <FormControl>
                    <Box m={1} mb={2} mt={1} sx={{ display: 'flex', flexDirection: { xs: 'column' } }} minWidth={'250px'} >
                        <TextField size="small" id="outlined-basic" type={showCurrentPassword ? "text" : "password"} value={formData.currentPassword} label="Aktualne hasło" variant="outlined" onChange={(e) => handleCurrentPassword(e)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            style={{ marginRight: "-10px" }}
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                                        >
                                            {<Eye />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }} />

                    </Box>
                    <Box m={1} mb={2} sx={{ display: 'flex', flexDirection: { xs: 'column' } }} minWidth={'250px'} >
                        <TextField size="small" id="outlined-basic" type={showNewPassword ? "text" : "password"} value={formData.newPassword || ''} label="Nowe hasło" variant="outlined" onChange={(e) => handleNewPassword(e)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            style={{ marginRight: "-10px" }}
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowNewPassword(!showNewPassword)}
                                        >
                                            {<Eye />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />

                    </Box>
                    <Box m={1} mb={2} sx={{ display: 'flex', flexDirection: { xs: 'column' } }} minWidth={'250px'} >
                        <TextField size="small" id="outlined-basic" type={showRepeatPassword ? "text" : "password"} value={formData.repeatPassword || ''} label="Powtórz nowe hasło" variant="outlined" onChange={(e) => handleRepeatPassword(e)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            style={{ marginRight: "-10px" }}
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                                        >
                                            {<Eye />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />

                    </Box>
                    <Box m={1} sx={{ display: 'flex', flexDirection: { xs: 'column' } }} width={'180px'}>
                        <Button className="buttonWithoutTextTransform" type="submit" size="large" variant="contained" disableElevation onClick={(e) => onSubmit(e)}>Zapisz zmiany</Button>
                    </Box>

                </FormControl>

                {isPasswordSending &&
                    <Box>
                        <Typography variant="h4">Wysyłanie... <CircularProgress size={15} /></Typography>
                    </Box>
                }
                {isPasswordChanged &&
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'row' } }}>
                        <CheckCircleOutline style={{ marginRight: "10px", minWidth: "20px", minHeight: "20px" }} />

                        <Typography variant="h4" color="success.main">
                            Hasło zostało zmienione.
                        </Typography >
                    </Box>
                }
                {(responseErrors && responseErrors.length > 0) &&
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'row' } }}>
                        <Block style={{ marginRight: "10px", minWidth: "20px", minHeight: "20px" }} />
                        <Typography variant="h4" color="error.main">
                            {responseErrors.map((item) => {
                                return (<>
                                    {item}
                                    <br />
                                </>)
                            })}
                        </Typography >
                    </Box>}
            </AccordionDetails>
        </Accordion>
    )
}

export default HasloAccordion;