import React from 'react';
import { TableCell, TableRow, Box } from '@mui/material';

export default function DaneOsoboweTableRow({ rodzajDane, uzytkownikDane }) {
    return (
        <TableRow sx={{ 'td, th': { borderBottomColor: 'neutral.100' }, '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell><Box ml={-2}>{rodzajDane}</Box></TableCell>
            <TableCell align="left">{uzytkownikDane}</TableCell>
        </TableRow>
    )
};