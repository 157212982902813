import { act } from "@testing-library/react"


const rodzajeNieobecnosciReducer = (state = null, action) => {
    switch (action.type) {
        case "addRodzajeNieobecnosci":
            return action.payload
        case "removeRodzajeNieobecnosci":
            return null
        default:
            return state
    }
}

// const aktualnieDostepnyUrlopReducer = (state = null, action) => {
//     switch (action.type) {
//         case "addAktualnieDostepnyUrlop":
//             return action.payload
//         case "removeAktualnieDostepnyUrlop":
//             return null
//         default:
//             return state
//     }
// }

const listaDostepnychUrlopowReducer = (state = null, action) => {
    switch (action.type) {
        case "addListaDostepnychUrlopow":
            return action.payload
        case "removeListaDostepnychUrlopow":
            return null
        default:
            return state
    }
}

const listaZastepstw = (state = null, action) => {
    switch (action.type) {
        case "addListaZastepstw":
            return action.payload
        case "removeListaZastepstw":
            return null
        default:
            return state
    }
}

const listaZlozonychWnioskow = (state = null, action) => {
    switch (action.type) {
        case "addListaZlozonychWnioskow":
            return action.payload
        case "removeListaZlozonychWnioskow":
            return null
        default:
            return state
    }
}

const listaMoichWnioskow = (state = null, action) => {
    switch (action.type) {
        case "addListaMoichWnioskow":
            return action.payload
        case "removeListaMoichWnioskow":
            return null
        default:
            return state
    }
}

const listaNadgodzin = (state = null, action) => {
    switch (action.type) {
        case "addListaNadgodzin":
            return action.payload
        case "removeListaNadgodzin":
            return null
        default:
            return state
    }
}

const podsumowanieRoku = (state = null, action) => {
    switch (action.type) {
        case "addPodsumowanieRoku":
            return action.payload
        case "removePodsumowanieRoku":
            return null
        default:
            return state
    }
}





const listaPracownikow = (state = null, action) => {
    switch (action.type) {
        case "addListaPracownikow":
            return action.payload
        case "removeListaPracownikow":
            return null
        default:
            return state
    }
}


const dostepneNadgodziny = (state = null, action) => {
    switch (action.type) {
        case "addDostepneNadgodziny":
            return action.payload
        case "removeDostepneNadgodziny":
            return null
        default:
            return state
    }
}

const danePrac = (state = null, action) => {
    switch (action.type) {
        case "addDanePrac":
            return action.payload
        case "removeDanePrac":
            return null
        default:
            return state
    }
}
const activeUmowa = (state = localStorage.getItem('ekadry2_idPrac'), action) => {
    switch (action.type) {
        case "updateActiveUmowa": return { ...state, umowa: action.payload };
        case "removeActiveUmowa": return { ...state, umowa: {} };
        default: return state
    }
}

const infoZKadrReducer = (state = null, action) => {
    switch (action.type) {
        case "addInfoZKadr":
            return action.payload
        case "removeInfoZKadr":
            return null
        default:
            return state
    }
}

const danePracownikaReducer = (state = null, action) => {
    switch (action.type) {
        case "addDanePracownika":
            return action.payload
        case "removeDanePracownika":
            return null
        default:
            return state
    }
}

const infoReducer = (state = null, action) => {
    switch (action.type) {
        case "addInfo":
            return action.payload
        case "removeInfo":
            return null
        default:
            return state
    }
}

const badaniaReducer = (state = null, action) => {
    switch (action.type) {
        case "addBadania":
            return action.payload
        case "removeBadania":
            return null
        default:
            return state
    }
}

const pozyczkiReducer = (state = null, action) => {
    switch (action.type) {
        case "addPozyczki":
            return action.payload
        case "removePozyczki":
            return null
        default:
            return state
    }
}

const wynagrodzeniaReducer = (state = null, action) => {
    switch (action.type) {
        case "addWynagrodzenia":
            return action.payload
        case "removeWynagrodzenia":
            return null
        default:
            return state
    }
}

const pityReducer = (state = null, action) => {
    switch (action.type) {
        case "addPity":
            return action.payload
        case "removePity":
            return null
        default:
            return state
    }
}

const zatrudnienieReducer = (state = null, action) => {
    switch (action.type) {
        case "addZatrudnienie":
            return action.payload
        case "removeZatrudnienie":
            return null
        default:
            return state
    }
}

const szkoleniaReducer = (state = null, action) => {
    switch (action.type) {
        case "addSzkolenia":
            return action.payload
        case "removeSzkolenia":
            return null
        default:
            return state
    }
}

const nieobecnosciReducer = (state = null, action) => {
    switch (action.type) {
        case "addNieobecnosci":
            return action.payload
        case "removeNieobecnosci":
            return null
        default:
            return state
    }
}

const ApiVersion = (state = null, action) => {
    switch (action.type) {
        case "addApiVersion":
            return action.payload
        case "removeApiVersion":
            return null
        default:
            return state
    }
}

const przelozeniReducer = (state = null, action) => {
    switch (action.type) {
        case "addPrzelozeni":
            return action.payload
        case "removePrzelozeni":
            return null
        default:
            return state
    }
}

export const responseDataReducer = {
    rodzajeNieobecnosciReducer, listaDostepnychUrlopowReducer, listaZastepstw, listaZlozonychWnioskow, listaMoichWnioskow, listaNadgodzin, podsumowanieRoku,
    dostepneNadgodziny, listaPracownikow, danePrac, infoZKadrReducer, danePracownikaReducer, infoReducer, badaniaReducer,
    pozyczkiReducer, wynagrodzeniaReducer, pityReducer, zatrudnienieReducer, szkoleniaReducer, nieobecnosciReducer, activeUmowa, ApiVersion, przelozeniReducer
}
