import { Button, Card, CircularProgress, Drawer, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Divider } from '@mui/material';
import { ReactComponent as ArrowsRight } from "../icons/ArrowsRight.svg";
import { ReactComponent as FitToScreen } from "../icons/FitToScreen.svg";
import { ReactComponent as ArrowRight } from "../icons/ArrowRight.svg";
import { ReactComponent as ArrowLeft } from "../icons/ArrowLeft.svg";
import { ReactComponent as TrashCan } from "../icons/TrashCan.svg";
import { ReactComponent as DownloadIcon } from "../icons/DownloadIcon.svg";
import { ReactComponent as EditIcon } from "../icons/EditIcon.svg";

import print from 'print-js'
import PatternService from "../services/pattern-service/patternService";
import { useNavigate } from 'react-router-dom';
import { withStyles } from "@mui/styles";
import PatternViewModal from "./modals/patternViewModal";

const CustomButton = withStyles({
    root: {
        "&.Mui-disabled span svg path": { fill: "rgba(0, 0, 0, 0.26)" }
    }
})(props => <Button {...props} />);

const CustomIconButton = withStyles({
    root: {
        "&.Mui-disabled path": { fill: "rgba(0, 0, 0, 0.26)" }
    }
})(props => <IconButton {...props} />);

function PatternPreviewer({ apiurl, pattern, patterns, isOpened, setIsOpened, apiAuthHeader, handleShowModal, setCurrentPatternIndex, permission }) {



    const navigate = useNavigate();

    const [pdfBase64, setPdfBase64] = useState("");
    const [pdfName, setPdfName] = useState("");
    const [isPdfLoading, setIsPdfLoading] = useState(true);
    const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

    useEffect(() => {
        if (pattern.idPattern) {
            getDocument()
        }
        if (pattern.idPattern === null) {
            setIsOpened(false)
        }

    }, [pattern.idPattern])



    const getDocument = async () => {
        setIsPdfLoading(true);
        await PatternService.getInstance(apiurl, apiAuthHeader).getConvertedPatternWithData(pattern.idPattern).then(response => {
            setPdfBase64(response.data.base64EncodedPdf)
            setPdfName(response.data.name)
            setIsPdfLoading(false);

        })
            .catch(error => {
                console.log(error);
            });
    }
    function printDocument(documentId) {
        print({ printable: pdfBase64, type: 'pdf', base64: true })


    }

    const downloadPdf = () => {
        const linkSource = `data:application/pdf;base64,${pdfBase64}`;
        const downloadLink = document.createElement("a");
        let filename = "";
        if (pattern.name.length > 255) {
            filename = pattern.name.slice(0, 255)
        }
        else {
            filename = pattern.name;
        }
        filename = filename + ".pdf"
        downloadLink.href = linkSource;
        downloadLink.download = filename;
        downloadLink.click();
    }

    const editPattern = () => {
        if (permission) {
            navigate("/wzorce/lista/patternEditor/" + pattern.idPattern)

        }
    }

    const handleNextButton = () => {
        if (pattern.index + 1 == patterns.length) {
            return
        }
        else {
            setCurrentPatternIndex(pattern.index + 1)
        }
    }

    const handlePreviousButton = () => {
        if (pattern.index - 1 < 0) {
            return
        }
        else {
            setCurrentPatternIndex(pattern.index - 1)
        }
    }

    const handleOpenPrintModal = () => {
        setIsPrintModalOpen(true);
        setIsOpened(false);
    }

    const handleClosePrintModal = () => {
        setIsPrintModalOpen(false);
    }
    const handleMinimizePrintModal = () => {
        setIsPrintModalOpen(false);
        setIsOpened(true);
    }
    return (
        <>
            <Drawer
                anchor='right'
                open={isOpened}
                onClose={() => setIsOpened(false)}
            >
                <Box m={2} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                    <Box>
                        <IconButton title="Zamknij" onClick={() => setIsOpened(false)}>
                            <ArrowsRight />

                        </IconButton>

                        <CustomIconButton disabled={isPdfLoading} title="Pełny ekran" onClick={() => handleOpenPrintModal()}>
                            <FitToScreen />

                        </CustomIconButton >

                    </Box>

                    {/* test idPattern {idPattern} */}
                    <Box>
                        <CustomButton sx={{ textTransform: 'none' }} disabled={pattern.index - 1 < 0} onClick={() => handlePreviousButton()} startIcon={<ArrowLeft /*style={{ "& path": { fill: "red !important" } }}*/ />}>
                            Poprzedni

                        </CustomButton>
                        <CustomButton sx={{ textTransform: 'none' }} disabled={pattern.index + 1 == patterns.length} onClick={() => handleNextButton()} endIcon={<ArrowRight />}>
                            Następny

                        </CustomButton>

                    </Box>
                    <Box>
                        &nbsp;
                    </Box>
                </Box>
                <Box height={'100%'} width={'35vw'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    {isPdfLoading ? <CircularProgress /> :
                        <embed download="adad.pdf" id="pdfDocument" type="application/pdf" width='100%' height='100%' src={'data:application/pdf;base64, ' + encodeURI(pdfBase64) + "#toolbar=1&navpanes=0&scrollbar=0'></embed></body></html>"} ></embed>

                    }

                </Box>
                <Divider />

                <Box m={2} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                    <Box display={'flex'} minWidth={'50%'} flexDirection={'row'} >
                        <Button disabled={isPdfLoading ? true : false} size="medium" sx={{ textTransform: 'none', width: '100%' }} variant="contained" onClick={() => printDocument('pdfDocument')}>Drukuj</Button>
                        <IconButton title="Pobierz pdf" onClick={() => downloadPdf()}>
                            <DownloadIcon />
                        </IconButton>
                        <CustomIconButton disabled={!permission} title="Edytuj wzorzec" onClick={() => editPattern()}>
                            <EditIcon />
                        </CustomIconButton >
                    </Box>
                    <Box>
                        <CustomIconButton disabled={!permission} title="Usuń wzorzec" onClick={() => handleShowModal(pattern.idPattern, pattern.idSystemu, pattern.name)}>
                            <TrashCan />
                        </CustomIconButton >
                    </Box>
                </Box>
            </Drawer>
            <PatternViewModal isShowed={isPrintModalOpen} handleCloseModal={handleClosePrintModal} handleMinimizeModal={handleMinimizePrintModal} pdfBase64={pdfBase64} />
        </>
    )
}
export default PatternPreviewer;