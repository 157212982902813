import { cacheService } from '../services/cache.service';
import { actionCreators } from '../submodules/template/loginRedux/actionCreators';
import { store } from '../redux/mainStore'



export default function UnauthorizedInterceptor(axiosResponse) {
    var segment_str = window.location.pathname;
    var segment_array = segment_str.split('/').filter(e => String(e).trim());;
    var last_segment = segment_array.at(-1);
    const appPrefix = localStorage.getItem('appPrefix').slice(0, -1);
    console.log(axiosResponse.response);
    if (axiosResponse && axiosResponse.response) {
        if (axiosResponse.response.status === 401) {
            if ((last_segment) != appPrefix) {    //jesli ostatni segment urla to appPrefix to jestesmy na stronie logowania
                cacheService.clearCache();
                store.dispatch(actionCreators.removeToken());

            }
            else if ((last_segment) == appPrefix) {
                return Promise.reject("Niepoprawny login lub hasło");
            }
        }
        else {
            return Promise.reject(axiosResponse);
        }

    }

}