import Axios from 'axios'
import { UnauthorizedInterceptor, NotFoundInterceptor, InternalServerErrorInterceptor, ForbiddenInterceptor, NoConnectionInterceptor } from './index'

export const interceptorService = { AddInterceptors }

function AddInterceptors() {
    Axios.interceptors.response.use(undefined, NoConnectionInterceptor);
    Axios.interceptors.response.use(undefined, UnauthorizedInterceptor);
    Axios.interceptors.response.use(undefined, ForbiddenInterceptor);
    Axios.interceptors.response.use(undefined, InternalServerErrorInterceptor);
    Axios.interceptors.response.use(undefined, NotFoundInterceptor);

}