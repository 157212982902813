
export default function InternalServerErrorInterceptor(axiosResponse) {
    var segment_str = window.location.pathname;
    var segment_array = segment_str.split('/').filter(e => String(e).trim());;

    if (axiosResponse && axiosResponse.response) {
        if (axiosResponse.response.status === 500 && segment_array.length > 1 && segment_array[1] !== "internalServerError") {
            let newUrl = '/' + segment_array[0] + '/internalServerError';
            window.location.replace(newUrl);
        }
        else {
            return Promise.reject(axiosResponse);
        }

    }

}