import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import '../styles/spinner.css'

function Spinner() {
    return (
        <Box className='spinner' >
            <CircularProgress sx={{ m: 5 }} />
            <Typography variant='h5'>Ładowanie danych...</Typography>
        </Box>
    );
}

export default Spinner;