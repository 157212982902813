
export default function NoConnectionInterceptor(axiosResponse) {
    var segment_str = window.location.pathname;
    var segment_array = segment_str.split('/').filter(e => String(e).trim());;

    if (!axiosResponse.response && axiosResponse.message === "Network Error" && segment_array[1] !== "noServerConnection" && segment_array[1]) {    //wykluczenie sytuacji jeśli jesteśmy na ekranie logowania lub na ekranie z brakiem połączenia
        let newUrl = '/' + segment_array[0] + '/noServerConnection';
        window.location.replace(newUrl);


    }
    else {
        return Promise.reject(axiosResponse);
    }
}