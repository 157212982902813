import React from 'react';
import { TableCell, TableRow, Box } from '@mui/material';

export default function TableRowZatrudnienie(props) {

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: (props.border) } }}>
            <TableCell width={'50%'}><Box sx={{ ml: -2, my: 0.5, }}>{props.title}</Box></TableCell>
            <TableCell><Box>
                {(props.value) ? (props.value) : '-'}</Box></TableCell>
        </TableRow>
    )
} 