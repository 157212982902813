import { apiService } from "../services/api.service";
const appPrefix = localStorage.getItem('appPrefix');



function addRodzajeNieobecnosci() {
    return function (dispatch) {

        return apiService.getDaneRodzajeNieobecnosci().then(response => {
            dispatch({
                type: "addRodzajeNieobecnosci",
                payload: response.data,
            });
        }).catch(error => {
            console.log(error);
            dispatch({
                type: "removeRodzajeNieobecnosci",
            });
        })

    }
}


// function addAktualnieDostepnyUrlop(idPrac) {
//     return function (dispatch) {

//         return apiService.getAktualnieDostepnyUrlop(idPrac).then(response => {
//             dispatch({
//                 type: "addAktualnieDostepnyUrlop",
//                 payload: response.data,
//             });
//         }).catch(error => {
//             console.log(error);
//             dispatch({
//                 type: "removeAktualnieDostepnyUrlop",
//             });
//         })

//     }
// }

function addListaDostepnychUrlopow(idPrac) {
    return function (dispatch) {

        return apiService.getWszystkieUrlopy(idPrac).then(response => {
            dispatch({
                type: "addListaDostepnychUrlopow",
                payload: response.data,
            });
        }).catch(error => {
            console.log(error);
            dispatch({
                type: "removeListaDostepnychUrlopow",
            });
        })

    }
}


function addListaZastepstw() {
    return function (dispatch) {

        return apiService.getZastepstwa().then(response => {
            dispatch({
                type: "addListaZastepstw",
                payload: response.data,
            });
        }).catch(error => {
            console.log(error);
            dispatch({
                type: "removeListaZasteptw",
            });
        })

    }
}

function addListaZlozonychWnioskow(idPrac) {
    return function (dispatch) {

        return apiService.getDaneWnioskiPodrzedne(idPrac).then(response => {
            dispatch({
                type: "addListaZlozonychWnioskow",
                payload: response.data,
            });
        }).catch(error => {
            console.log(error);
            dispatch({
                type: "removeListaZlozonychWnioskow",
            });
        })

    }
}

function addListaMoichWnioskow() {
    return function (dispatch) {

        return apiService.getDaneWnioski().then(response => {
            dispatch({
                type: "addListaMoichWnioskow",
                payload: response.data,
            });
        }).catch(error => {
            console.log(error);
            dispatch({
                type: "removeListaMoichWnioskow",
            });
        })

    }
}


function addListaNadgodzin(idPrac) {
    return function (dispatch) {

        return apiService.getNadgodziny(idPrac).then(response => {
            dispatch({
                type: "addListaNadgodzin",
                payload: response.data,
            });
        }).catch(error => {
            console.log(error);
            dispatch({
                type: "removeListaNadgodzin",
            });
        })

    }
}

function addPodsumowanieRoku(idPrac) {
    return function (dispatch) {

        return apiService.getPodsumowanieRoku(idPrac).then(response => {
            dispatch({
                type: "addPodsumowanieRoku",
                payload: response.data,
            });
        }).catch(error => {
            console.log(error);
            dispatch({
                type: "removePodsumowanieRoku",
            });
        })

    }
}



function addDostepneNadgodziny(idPrac) {
    return function (dispatch) {

        return apiService.getDostepneNadgodziny(idPrac).then(response => {
            dispatch({
                type: "addDostepneNadgodziny",
                payload: response.data,
            });
        }).catch(error => {
            console.log(error);
            dispatch({
                type: "removeDostepneNadgodziny",
            });
        })

    }
}

function addListaPracownikow() {
    return function (dispatch) {
        return apiService.getDaneStruktura().then(response => {
            dispatch({
                type: 'addListaPracownikow',
                payload: response.data,
            });
        }).catch(error => {
            console.log(error);
            dispatch({
                type: "removeListaPracownikow",
            });
        })
    }
}

function addDanePrac() {
    return function (dispatch) {
        return apiService.getDanePrac().then(response => {
            dispatch({
                type: 'addDanePrac',
                payload: response.data,
            })
            if (!localStorage.getItem(appPrefix + 'idPrac')) {
                localStorage.setItem(appPrefix + 'idPrac', response.data[0].idPrac);
            }
        }).catch(error => {
            console.log(error);
            dispatch({
                type: "removeDanePrac",
            });
        })
    }
}

function updateActiveUmowa(activeIdPrac) {
    return function (dispatch) {
        return dispatch({
            type: 'updateActiveUmowa',
            payload: activeIdPrac
        })
    }
}

function removeActiveUmowa() {
    return (dispatch) => {
        dispatch({
            type: 'DELETE'
        })
    }
}

function addInfoZKadr(idPrac) {
    return function (dispatch) {
        return apiService.getInfoZKadr(idPrac).then(response => {
            dispatch({
                type: "addInfoZKadr",
                payload: response.data,
            });
        }).catch(error => {
            console.log(error);
            dispatch({
                type: "removeInfoZKadr",
            });
        })
    }
}

function addDanePracownika() {
    return function (dispatch) {
        return apiService.getDanePracownika().then(response => {
            dispatch({
                type: "addDanePracownika",
                payload: response.data,
            });
        }).catch(error => {
            console.log(error);
            dispatch({
                type: "removeDanePracownika",
            });
        })
    }
}

function addInfo(idPrac) {
    return function (dispatch) {
        return apiService.getInfo(idPrac).then(response => {
            dispatch({
                type: "addInfo",
                payload: response.data,
            });
        }).catch(error => {
            console.log(error);
            dispatch({
                type: "removeInfo",
            });
        })
    }
}

function addBadania(idPrac) {
    return function (dispatch) {
        return apiService.getBadania(idPrac).then(response => {
            dispatch({
                type: "addBadania",
                payload: response.data,
            });
        }).catch(error => {
            console.log(error);
            dispatch({
                type: "removeBadania",
            });
        })
    }
}

function addPozyczki(idPrac) {
    return function (dispatch) {
        return apiService.getPozyczki(idPrac).then(response => {
            dispatch({
                type: "addPozyczki",
                payload: response.data,
            });
        }).catch(error => {
            console.log(error);
            dispatch({
                type: "removePozyczki",
            });
        })
    }
}

function addWynagrodzenia(idPrac) {
    return function (dispatch) {
        return apiService.getWynagrodzenia({ idPrac: idPrac.idPrac, rok: new Date().getFullYear() }).then(response => {
            dispatch({
                type: "addWynagrodzenia",
                payload: response.data,
            });
        }).catch(error => {
            console.log(error);
            dispatch({
                type: "removeWynagrodzenia",
            });
        })
    }
}

function addPity(idPrac) {
    return function (dispatch) {
        return apiService.getPity(idPrac).then(response => {
            dispatch({
                type: "addPity",
                payload: response.data,
            });
        }).catch(error => {
            console.log(error);
            dispatch({
                type: "removePity",
            });
        })
    }
}
function addZatrudnienie(idPrac) {
    return function (dispatch) {
        return apiService.getZatrudnienie(idPrac).then(response => {
            dispatch({
                type: "addZatrudnienie",
                payload: response.data,
            });
        }).catch(error => {
            console.log(error);
            dispatch({
                type: "removeZatrudnienie",
            });
        })
    }
}

function addSzkolenia(idPrac) {
    return function (dispatch) {
        return apiService.getSzkolenia(idPrac).then(response => {
            dispatch({
                type: "addSzkolenia",
                payload: response.data,
            });
        }).catch(error => {
            console.log(error);
            dispatch({
                type: "removeSzkolenia",
            });
        })
    }
}

function addNieobecnosci(data) {
    return function (dispatch) {
        return apiService.getNieobecnosci(data).then(response => {
            dispatch({
                type: "addNieobecnosci",
                payload: response.data,
            });
        }).catch(error => {
            console.log(error);
            dispatch({
                type: "removeNieobecnosci",
            });
        })
    }
}

function removeNieobecnosci(data) {
    return (dispatch) => {
        dispatch({
            type: "removeNieobecnosci"
        });
    }
}

function addApiVersion() {
    return function (dispatch) {
        return apiService.getApiVersion().then(response => {
            dispatch({
                type: "addApiVersion",
                payload: response.data,

            });
        }).catch(error => {
            console.log(error);
            dispatch({
                type: "removeApiVersion",
            });
        })
    }
}

function addPrzelozeni(idPrac) {
    return function (dispatch) {
        return apiService.getPrzelozeni(idPrac).then(res => {
            dispatch({
                type: "addPrzelozeni",
                payload: res.data
            })
        }).catch(error => {
            console.log(error);
            dispatch({
                type: "removePrzelozeni",
            });
        })
    }
}


export const actionCreators = {
    addRodzajeNieobecnosci, addListaDostepnychUrlopow, addListaZastepstw, addListaZlozonychWnioskow, addListaMoichWnioskow, addListaNadgodzin,
    addPodsumowanieRoku, addDostepneNadgodziny, addListaPracownikow, addDanePrac, addInfoZKadr, addDanePracownika, addInfo,
    addBadania, addPozyczki, addWynagrodzenia, addPity, addZatrudnienie, addSzkolenia, addNieobecnosci, removeNieobecnosci, updateActiveUmowa, removeActiveUmowa, addApiVersion, addPrzelozeni
}
