import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { store } from './redux/mainStore';
import { Provider } from 'react-redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App.js';
import { history } from './history';
import { BrowserRouter } from "react-router-dom";
import { interceptorService } from './interceptors/InterceptorService';

interceptorService.AddInterceptors();

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
      <BrowserRouter basename={''} history={history}>
        <App />
      </BrowserRouter>
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
