import { createTheme } from "@mui/material";
import { plPL } from "@mui/material/locale";


const theme = createTheme({
    palette: {
        primary: {
            main: '#3879F6',
            "50": '#ECF4FD',
            "100": '#E1EBFE',
            "200": '#C3D7FC',
            "300": '#88AFFA',
            "400": '#6A9AF8',
            "500": '#3879F6',
            "600": '#1561F4',
            "700": '#003DB4',
            "800": '#041F55',
            "900": '#15203b',
        },
        success: {
            main: '#10B981',
            "50": '#ECFDF5',
            "100": '#D1FAE5',
            "200": '#A7F3D0',
            "300": '#6EE7B7',
            "400": '#34D399',
            "500": '#10B981',
            "600": '#059669',
            "700": '#047857',
            "800": '#065F46',
            "900": '#064E3B',
        },
        warning: {
            main: '#F59E0B',
            "50": '#FFFBEB',
            "100": '#FEF3C7',
            "200": '#FDE68A',
            "300": '#FCD34D',
            "400": '#FBBF24',
            "500": '#F59E0B',
            "600": '#D97706',
            "700": '#B45309',
            "800": '#92400E',
            "900": '#78350F',
        },
        error: {
            main: '#EF4444',
            "50": '#FEF2F2',
            "100": '#FEE2E2',
            "200": '#FECACA',
            "300": '#FCA5A5',
            "400": '#F87171',
            "500": '#EF4444',
            "600": '#DC2626',
            "700": '#B91C1C',
            "800": '#991B1B',
            "900": '#7F1D1D',
        },
        shades: {
            "0": '#FFFFFF',
            "100": '#000000',
        },
        darkMode: {
            "50": '#e9eff4',
            "100": '#bed0dd',
            "200": '#7da0ba',
            "300": '#5580a1',
            "400": '#3e5e75',
            "500": '#273B4A',
            "600": '#223441',
            "700": '#18252e',
            "800": '#0a0f12',
            "900": '#000000',
        },
        secondaryColors: {
            "50": '#F3F5F9',
            "100": '#edf0f8',
            "200": '#D6DDEF',
            "300": '#5580a1',
            "400": '#adb6ce',
            "500": '#8F9BBC',
            "600": '#53618a',
            "700": '#3e4968',
            "800": '#1f2534',
            "900": '#0a0c11',
        },
        text: {
            primary: '#273B4A',
            secondary: '#6A6F7B',
        },
        gray: {
            "500": '#6A6F7B'
        },
        neutral: {
            "50": '#f5f5f6',
            "100": '#E1EBFE',
            "200": '#CDCFD4',
            "300": '#AFB2BA',
            "400": '#9095A0',
            "500": '#6A6F7B',
            "600": '#50535c',
            "700": '#3e4148',
            "800": '#2c2e33',
            "900": '#141515'
        }

    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1620,
        },
    },
    typography: {
        fontFamily: "Mulish",
        display: {
            fontWeight: 600,
            fontSize: '60px',
            fontStyle: 'normal',
            lineHeight: '72px',
        },
        display2: {
            fontWeight: 600,
            fontSize: '48px',
            fontStyle: 'normal',
            lineHeight: '56px',
        },
        display3: {
            fontWeight: 600,
            fontSize: '39px',
            fontStyle: 'normal',
            lineHeight: '47px',
        },
        display4: {
            fontWeight: 600,
            fontSize: '33px',
            fontStyle: 'normal',
            lineHeight: '40px',
        },
        display5: {
            fontWeight: 600,
            fontSize: '23px',
            fontStyle: 'normal',
            lineHeight: '28px',
        },
        display6: {
            fontWeight: 600,
            fontSize: '60px',
            fontStyle: 'normal',
            lineHeight: '72px',
        },
        h1: {
            fontWeight: 600,
            fontSize: '20px',
            fontStyle: 'normal',
            lineHeight: '24px',
            color: '#273B4A',
        },
        h2: {
            fontWeight: 400,
            fontSize: '18px',
            fontStyle: 'normal',
            lineHeight: '21.6px',
            color: '#273B4A',
        },
        h3: {
            fontWeight: 400,
            fontSize: '16px',
            fontStyle: 'normal',
            lineHeight: '19px',
            // color: '#273B4A',
        },
        h4: {
            fontWeight: 400,
            fontSize: '14px',
            fontStyle: 'normal',
            lineHeight: '17px',
        },
        h5: {
            fontWeight: 400,
            fontSize: '12px',
            fontStyle: 'normal',
            lineHeight: '14px',
        },
        h6: {
            fontWeight: 400,
            fontSize: '10px',
            fontStyle: 'normal',
            lineHeight: '12px',
        },
        h7: {
            fontSize: '14px'
        },
        caption1: {
            fontWeight: 400,
            fontSize: '10px',
            fontStyle: 'normal',
            lineHeight: '12px',
        },
        caption2: {
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '14px',

        },
        caption3: {
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '17px',
        }
    },
    shadows: [
        "none",
        ...Array(24).fill("0px 3px 1px -2px #D6DDEF,0px 2px 2px 0px #D6DDEF,0px 1px 5px 0px #D6DDEF")
    ],

},
    plPL
);

export default theme;
