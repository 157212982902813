import React, { useState } from 'react';
import { Button, IconButton, InputAdornment, Paper, Typography } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CustomTextField } from '../layout/styles/CustomTextField';
import { MailIcon, PasswordEye, Wrong } from '../layout/styles/icons';
import { actionCreators } from '../loginRedux/actionCreators';
import '../layout/styles/login.css';

function LoginForm() {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [showPass, setShowPass] = useState(true);
    const [failLogin, setFailLogin] = useState(false);

    const dispatch = useDispatch();
    const { addToken } = bindActionCreators(actionCreators, dispatch);
    const handleClickShowPassword = () => setShowPass(!showPass);
    const onSub = (event) => {
        event.preventDefault()
        addToken(username, password, setFailLogin);
        clearCache();
    }
    function clearCache() {
        caches.keys().then(function (names) {
            for (let name of names)
                caches.delete(name);
        });
    };

    return (
        <form onSubmit={onSub}>
            <Paper sx={{ minWidth: '400px', minHeight: '344 px' }} elevation={2}>
                <Box display={'flex'} flexDirection={'column'} m={3} p={2}>
                    <Typography mb={3} mt={2} fontSize={28} fontWeight={700}>Witaj z powrotem!</Typography>
                    <CustomTextField id="username" sx={{ minWidth: '270px', marginBottom: '30px' }} variant='outlined' label="Nazwa użytkownika" value={username || ''} onChange={(e) => { setUsername(e.target.value); setFailLogin(false) }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <MailIcon />
                                </InputAdornment>
                            ),
                        }} />
                    <CustomTextField id="password" sx={{ minWidth: '270px', marginBottom: '30px' }} label="Hasło" type={showPass ? "password" : "text"} value={password || ''} onChange={(e) => { setPassword(e.target.value); setFailLogin(false) }} InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Box mr={-1}>
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                    >
                                        {showPass ? < PasswordEye /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </Box>
                            </InputAdornment>
                        ),
                    }} />
                    <Button type="submit" variant="contained" color="primary" sx={{ width: 1, padding: '15px', marginBottom: '30px' }} >Zaloguj</Button>
                    {failLogin ?
                        <Box mb={2} className='failLogin'>
                            <Wrong />
                            <Typography fontSize={12} color={'red'} sx={{ marginLeft: '10px' }}> Błędny login lub hasło.</Typography>
                        </Box> : null}
                </Box>
            </Paper>
        </form>
    );
};

export default LoginForm;