import React, { useState } from 'react';
import { Button, IconButton, List, ListItem, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { HexWithQuestionMark } from '../../styles/icons.js';
import { apiService } from '../../services/api.service.js';
import moment from 'moment';
import SpinnerWithoutText from '../../components/SpinnerWithoutText.js';





const ModalNeiobZast = (props) => {
    const [loading, setLoading] = useState(false);



    const handleClose = () => {
        props.setOpenModal(false)
    }

    const handleSubmit = () => {
        let wniosek = { idPrac: props.idPrac, dataOd: props.dataRozpoczecia, dataDo: props.dataZakonczenia, typWniosku: props.typWniosku, hhmmOd: "00:00", hhmmDo: "00:00", idPracZastepstwo: props.idPracZastepstwo, opis: props.opis, kodRodzajuNieobecnosci: props.kodRodzajuNieobecnosci, sendMail: props.sendMail, sendMailTo: props.sendMailTo };
        setLoading(true)
        apiService.addWniosek(wniosek).then((res) => {
            props.addListaMoichWnioskow();
            props.setIsWniosekAdded(true);
            props.setOpenPopup(true);
            props.resetForm();
        }, err => {
            props.setIsWniosekNotAdded(true);
            if (err.response.data)
                props.setResponseErrors(err.response.data)
            console.log(err);
        }).finally(() => {
            props.setIsWniosekSending(false);
            props.setOpenModal(false);
            setLoading(false)

        })
    }

    return (
        <>
            <Modal
                open={props.openModal}
                onClose={handleClose}
                aria-labelledby='modal-nieobZast'
                aria-describedby='modal-nieobZast'
            >
                <Box display='flex' flexDirection='column' alignItems='center' className='modal'>
                    <Box display='flex' width='100%' alignItems='center' justifyContent='space-between' >
                        <Box flexGrow={1} />
                        <Box><IconButton onClick={handleClose}><CloseIcon /></IconButton></Box>
                    </Box>
                    <Box mb={1}>
                        <HexWithQuestionMark />
                    </Box>
                    <Box display='flex' flexDirection='column' justifyContent='space-between'>
                        <Typography variant='h1' mb={2} textAlign='center' fontWeight='bold'>Wybrana osoba nie będzie dostepna w danym terminie.</Typography>
                        <Typography variant="h4" color="error.main">
                            Osoba zastępująca będzie nieobecna w pracy w terminie:<br />
                            <List>
                                {props.nieobZast && props.nieobZast.map((item, index) => {
                                    return <ListItem key={index}>{`od ${moment(item.dataOd).format('DD.MM.YYYY')} do ${moment(item.dataDo).format('DD.MM.YYYY')
                                        }`
                                    }</ListItem>
                                })}
                            </List>
                        </Typography >
                    </Box>
                    {loading ?
                        <SpinnerWithoutText /> :
                        <Box mt={5} display='flex' flexDirection='column' justifyContent='space-around' minWidth='300px'>

                            <Button size='large' sx={{ marginBottom: '15px' }} onClick={() => handleClose()} variant='contained'>Powrót do edycji </Button>
                            <Button size='large' variant='outlined' onClick={() => handleSubmit()}>Złóż wniosek</Button>
                        </Box>
                    }


                </Box>
            </Modal>
        </>
    );
}

export default ModalNeiobZast;