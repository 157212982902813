import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import BackArrow from '../components/BackArrow';
import PaginationComponent from '../components/PaginationComponent';
import Spinner from '../components/Spinner';
import { actionCreators } from '../redux/actionCreators';
import { getIdPrac } from '../redux/idPracFetch';
import { Converter } from '../submodules/template/services/Converter';

function Szkolenia() {
    //const [szkolenia, setSzkolenia] = useState();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const activeUmowa = getIdPrac();

    const szkolenia = useSelector((state) => state.szkolenia);
    const dispatch = useDispatch();
    const { addSzkolenia } = bindActionCreators(actionCreators, dispatch);

    useEffect(() => {
        getDane();
    }, []);

    const getDane = () => {
        if (szkolenia == null) {
            addSzkolenia({ idPrac: activeUmowa });
        }
    }

    return (
        <>
            <Box mb={2} display={'flex'} alignItems={'center'}><BackArrow /><Typography variant="h1" >Szkolenia</Typography></Box>
            {szkolenia ?
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ pl: 7 }} style={{ fontWeight: 'bold' }}>Opis</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Kategoria</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Zrealizowane</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Następne</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {szkolenia.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                                    <TableRow
                                        sx={{
                                            '&:nth-of-type(even)': { backgroundColor: 'secondaryColors.50' },
                                            "&:hover": { backgroundColor: "primary.50" },
                                            "& td": { border: 0 }
                                        }} s
                                        key={index}>
                                        <TableCell sx={{ pl: 7 }}>{item.opis}</TableCell>
                                        <TableCell>{item.rodzaj}</TableCell>
                                        <TableCell>{Converter.formatDateToDDMMYYYY(item.data)}</TableCell>
                                        <TableCell>{Converter.formatDateToDDMMYYYY(item.dataNastepnego)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <PaginationComponent page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} data={szkolenia} />
                </Paper>
                : <Spinner />
            }

        </>

    );
}

export default Szkolenia;