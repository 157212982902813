import React from 'react';
import {
  Route,
  Routes
} from "react-router-dom";
import {
  PatternCreator,
  PatternEditor,
  PatternListFunc,
} from './patternExport';
import { Component } from 'react';
import PatternLoading from './common/patternLoading';

class RouterLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPromptActivated: false,
      showReturnModal: false,
    }
    this.promptActivate = this.promptActivate.bind(this);
    this.promptDeactivate = this.promptDeactivate.bind(this);
  }
  async promptActivate() {
    this.setState({
      isPromptActivated: true
    })
  }

  async promptDeactivate() {
    this.setState({
      isPromptActivated: false
    })

  }

  render() {
    return (
      <>
        <Routes>
          <Route exact path="/lista" element={<PatternListFunc searchTextField={this.props.searchTextField} apiurl={this.props.apiurl} navigation={this.props.navigation} printParams={this.props.printParams} apiAuthHeader={this.props.apiAuthHeader} />}>
          </Route>
          <Route path="/lista/patternCreator" element={<PatternCreator apiurl={this.props.apiurl} navigation={this.props.navigation} promptActivate={this.props.promptActivate} promptDeactivate={this.props.promptDeactivate} apiAuthHeader={this.props.apiAuthHeader} />}>
          </Route>
          <Route path="/lista/patternEditor/:id" element={<PatternEditor params={this.props.params} apiurl={this.props.apiurl} navigation={this.props.navigation} promptActivate={this.props.promptActivate} promptDeactivate={this.props.promptDeactivate} apiAuthHeader={this.props.apiAuthHeader} />}>
          </Route>
          <Route path="/lista/wczytywanie" element={<PatternLoading apiurl={this.props.apiurl} navigation={this.props.navigation} promptActivate={this.props.promptActivate} promptDeactivate={this.props.promptDeactivate} apiAuthHeader={this.props.apiAuthHeader} />}>
          </Route>
        </Routes>
      </>
    )
  };
}

export default RouterLinks;
