import React, { useEffect, useState } from 'react';
import { Avatar, IconButton, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function UserAvatarContent({ removeToken }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [foto, setFoto] = useState('');
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    function clearCache() {
        caches.keys().then(function (names) {
            for (let name of names)
                caches.delete(name);
        });
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        removeToken();
        //navigate('/');
        clearCache();
    }
    return <>
        <IconButton
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
        >
            <Avatar src={`data:image/png;base64,${foto}` || null} />
        </IconButton>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <MenuItem onClick={handleClose}>Mój Profil</MenuItem>
            <MenuItem onClick={() => logout()}>Wyloguj</MenuItem>
        </Menu>
    </>


}

export default UserAvatarContent;