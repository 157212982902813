import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../loginRedux/actionCreators';
import { config } from '../../../config/configApp';
import { Typography } from "@mui/material";

export const Timer = forwardRef((props, ref) => {
  const czasSesji = config.czasSesji;

  const [minutes, setMinutes] = useState(czasSesji);
  const dispatch = useDispatch();
  const { removeToken } = bindActionCreators(actionCreators, dispatch);

  function reset() {
    setMinutes(czasSesji);
  }

  useEffect(() => {
    let interval = null;

    interval = setInterval(() => {
      setMinutes(minutes => minutes - 1);
    }, 60000);

    if (minutes === 0) {
      alert('Czas sesji upłynął. Zaloguj się ponownie.');
      removeToken()
    }

    return () => clearInterval(interval);
  }, [minutes]);


  useImperativeHandle(ref, () => ({
    resetTimer() {
      reset()
    }
  }));

  return (
    <>
      <Typography variant='h5' >Czas sesji: {minutes} min</Typography>

    </>
  );
});