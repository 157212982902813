import React from "react";
import { Grid, Typography } from '@mui/material';
import FiltrSzczegolywnioski from "./FiltrSzczegolyWnioski";
import moment from "moment";
import { StatusyWnioskow } from "../services/statusyWnioskow";

export default function InfoWnioskiFilters({ handlersDetailsFilters: { dataOd, dataDo, kodRodzajuNieobecnosci, statusWnios,
    CloseFilterDataDo, CloseFilterDataOd, CloseFilterRodzaj, CloseFilterStatus, cancelFilters, open, nazwaUrlopu } }) {

    return (
        <Grid container pr={2}>
            <Grid item xs={10.5}>
                <Grid
                    container
                    columnSpacing={2}
                    rowSpacing={1}
                    direction="row"
                    alignItems="center"
                    pl={4}>
                    {dataOd ? <Grid item>
                        <FiltrSzczegolywnioski
                            text={"Data od: "}
                            info={moment(dataOd).format('DD-MM-YYYY')}
                            onClick={CloseFilterDataOd} />
                    </Grid> : null}
                    {dataDo ? <Grid item>
                        <FiltrSzczegolywnioski
                            text={"Data do: "}
                            info={moment(dataDo).format('DD-MM-YYYY')}
                            onClick={CloseFilterDataDo} />
                    </Grid> : null}
                    {kodRodzajuNieobecnosci ? <Grid item>
                        <FiltrSzczegolywnioski
                            text={"Rodzaj: "}
                            info={nazwaUrlopu}
                            onClick={CloseFilterRodzaj} />
                    </Grid> : null}
                    {statusWnios || statusWnios === 0 ? <Grid item>
                        <FiltrSzczegolywnioski
                            text={"Status: "}
                            info={StatusyWnioskow.getTextFromInt(statusWnios)}
                            onClick={CloseFilterStatus} />
                    </Grid> : null}
                </Grid>
            </Grid>
            <Grid item xs={1.5}>
                {!open && dataOd || dataDo || kodRodzajuNieobecnosci || statusWnios ?
                    <Typography
                        whiteSpace={"nowrap"}
                        align="right"
                        onClick={cancelFilters}
                        style={{ cursor: "pointer" }}
                        variant="h5" color="#8F9BBC">Wyczyść filtry</Typography>
                    : null}
            </Grid>
        </Grid>
    );
}