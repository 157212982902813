

function filterArrayByWords(array, wordsArray) {
    let filteredArray = []
    wordsArray.forEach(word => {
        array.forEach((object, index) => {
            if (filteredArray.some(item => item == object)) {
                return;     //continue ale w foreachu
            }
            else {
                for (const property in object) {
                    if (object[property] == null) {
                        continue
                    }

                    if (object[property].toString().toLowerCase().includes(word.toLowerCase())) {
                        //tempArray.splice(index, 1);

                        filteredArray.push(object);
                        break
                    }
                }
            }

        });
    });

    return filteredArray;
}


function prioritizeResults(array, input) {
    let priorArr = [];   //wyniki z większym priorytetem
    let restArr = [];   //reszta wyników
    array.forEach((object, index) => {

        let count = 0;
        for (const property in object) {
            count++;
            if (!property || !object[property]) {
                continue
            }

            if (object[property].toString().toLowerCase().includes(input.toLowerCase())) {  //cały string pasuje, czyli wynik z priorytetem
                priorArr.push(object)
                break
            }
            if (Object.keys(object).length == count) {  //wynik bez priorytetu do drugiej tablicy
                restArr.push(object)

            }

        }
    });


    const mergedArray = [...priorArr, ...restArr]  //wyniki z większym priorytetem na początek i reszta na koniec
    return mergedArray;
}

function ArraySearcher(array, input) { //szukajka wyrazów wewnątrz tablicy
    let filteredArray = []
    const wordsArray = input.split(" ").filter(element => element); //ten filter usuwa puste znaki
    if (wordsArray.length == 0) {
        wordsArray.push("");
    }
    filteredArray = filterArrayByWords(array, wordsArray);
    filteredArray = prioritizeResults(filteredArray, input);
    return filteredArray;






}

export default ArraySearcher;