import React, { useState } from 'react';
import { TextField, Button, Stack, Typography, InputAdornment, IconButton } from '@mui/material';
import { useParams } from "react-router-dom";
import '../layout/styles/login.css';
import { PasswordEye } from '../layout/styles/icons';
import axios from 'axios';
import apiurl from '../../../apiurl';

export default RecoverPass;

function RecoverPass() {
  const [password, setPassword] = useState();
  const [repassword, setRepassword] = useState();
  const [showPass, setShowPass] = useState(true)
  const { hash } = useParams();

  const handleClickShowPassword = () => setShowPass(!showPass);

  function changePassword(pass, repass) {
    if (pass === repass) {
      return axios.put(apiurl + "api/PwdRecovery",
        { token: hash, password: pass, newPassword: repass },
        { headers: { 'Content-Type': 'application/json' } })
        .then(res => {
          alert("Hasło zostało pomyślnie zmienione!");
        })
        .catch(error => {
          alert(error.response.data)
        });
    } else {
      alert('Hasła nie zgadzają się.')
    }
  }


  return (<>
    <div className='mainBox'>
      <div className='centerBox'>
        <div className='herbBox'>
          <img alt='herb' src='./herb.png'></img>
        </div>

        <div className='formBox'>
          <Stack spacing={5}>
            <Typography fontSize={28}>Podaj nowe hasło</Typography>

            <TextField id="password" label="Hasło" variant="outlined" type={showPass ? "password" : "text"} value={password} onChange={(e) => setPassword(e.target.value)} InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    <PasswordEye />
                  </IconButton>
                </InputAdornment>
              ),
            }} />
            <TextField id="repassword" label="Powtórz hasło" variant="outlined" type={showPass ? "password" : "text"} value={repassword} onChange={(e) => setRepassword(e.target.value)} InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    <PasswordEye />
                  </IconButton>
                </InputAdornment>
              ),
            }} />

            <Button variant="contained" color="primary" style={{ padding: '15px' }} onClick={() => (changePassword(password, repassword))}>Zmień hasło</Button>
          </Stack>
        </div>

        <div className='logoBox'>
          <img alt='logo' src='./logo.png'></img>
        </div>

      </div>
    </div>

  </>);

}

