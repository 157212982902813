import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import moment from 'moment';

function WynagrodzenieAccordion({ handlers: { finanse, handleShowValue, expanded, handleChangeHideDetails }, showWynagrodzenie }) {
    const panel = "panel1df";

    return (
        <>
            {finanse.wynagrodzenia &&
                finanse.wynagrodzenia.map((row, index) => (
                    <Accordion key={index} expanded={expanded === panel} onChange={handleChangeHideDetails(panel)}>
                        <AccordionSummary
                            sx={{ pt: 2, pb: 2 }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${panel}-content`}
                            id={`${panel}-header`}
                        >
                            <Typography ml={2} variant='h3' fontWeight={'bold'}>Twoje wynagrodzenie</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} mt={-2}>
                                <Typography ml={2} variant='h4'>{!showWynagrodzenie ? `${row.brutto} zł brutto/${moment().format('MMMM')}` : '********************'}</Typography>
                                <Box mr={-0.5} ><IconButton
                                    aria-label="toggle visibility"
                                    onClick={() => handleShowValue('wynagrodzenie')}
                                >
                                    {!showWynagrodzenie ? < VisibilityIcon style={{ height: '18px', width: '18px' }} /> : <VisibilityOffIcon style={{ height: '18px', width: '18px' }} />}
                                </IconButton>
                                </Box>
                            </Box>
                            {/* <Box display={'flex'} flexDirection={'column'} alignItems={'left'}>
                                <Typography variant='caption2'>{row.nazwa}</Typography>
                                <Typography variant='caption2'>Opis: {row.opis}</Typography>
                                <Typography variant='caption2'>{!showWynagrodzenie ? `Netto: ${row.netto} zł` : '*********'}</Typography>
                                <Typography variant='caption2'>Podatek: {row.podatek} </Typography>
                            </Box> */}
                        </AccordionDetails>
                    </Accordion>
                ))}
        </>
    );
}

export default WynagrodzenieAccordion;