import React, { Component } from 'react';
import { PatternService } from '../../services/pattern-service/patternService';

let patternService;

class PatternPrinter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            base64EncodedPDF: '',
            fullDocument: ''
        }
        patternService = new PatternService(this.props.apiAuthHeader);
    }

    async componentDidMount() {
        await this.getConvertedPattern();

    }

    getConvertedPattern(){
        patternService.getConvertedPattern(this.props.patternValues, this.props.match.params.id).then(response => {
            this.setState({
                base64EncodedPDF: response.data,
            })
            this.printDocument();
            window.top.close();

        })
            .catch(error => {
                console.log(error);
            });
    }
    printDocument() {

        /*window.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
            encodeURI(this.state.base64EncodedPDF) + "'></iframe>"
        )*/
        this.setState({
            fullDocument: "<iframe width='100%' height='100%' src='data:application/pdf;base64, "+encodeURI(this.state.base64EncodedPDF) + "'></iframe>"
        })
    }

    render() {

        return (
            <div dangerouslySetInnerHTML={{ __html: this.state.fullDocument}} style={{height: '100VH'}}>
                
            </div>
        )
    }
}

export default PatternPrinter;