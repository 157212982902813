import React from 'react';


export const ImportIcon = () => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.50008 11.0833L8.79297 11.7904L9.50008 12.4975L10.2072 11.7904L9.50008 11.0833ZM10.5001 3.95825C10.5001 3.40597 10.0524 2.95825 9.50008 2.95825C8.9478 2.95825 8.50008 3.40597 8.50008 3.95825L10.5001 3.95825ZM4.83464 7.83203L8.79297 11.7904L10.2072 10.3761L6.24885 6.41781L4.83464 7.83203ZM10.2072 11.7904L14.1655 7.83203L12.7513 6.41781L8.79297 10.3761L10.2072 11.7904ZM10.5001 11.0833L10.5001 3.95825L8.50008 3.95825L8.50008 11.0833L10.5001 11.0833Z" fill="#8F9BBC" />
            <path d="M3.95825 12.6667L3.95825 13.4584C3.95825 14.3329 4.66713 15.0417 5.54159 15.0417L13.4583 15.0417C14.3327 15.0417 15.0416 14.3329 15.0416 13.4584V12.6667" stroke="#8F9BBC" strokeWidth="2" />
        </svg>
    )
}

export const AccountDetails = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0833 8.25008C10.0833 9.77175 8.85492 11.0001 7.33325 11.0001C5.81159 11.0001 4.58325 9.77175 4.58325 8.25008C4.58325 6.72841 5.81159 5.50008 7.33325 5.50008C8.85492 5.50008 10.0833 6.72841 10.0833 8.25008ZM12.8333 18.3334H1.83325V16.5001C1.83325 14.4742 4.29909 12.8334 7.33325 12.8334C10.3674 12.8334 12.8333 14.4742 12.8333 16.5001V18.3334ZM20.1666 11.0001V12.8334H11.9166V11.0001H20.1666ZM20.1666 7.33341V9.16675H11.9166V7.33341H20.1666ZM20.1666 3.66675V5.50008H11.9166V3.66675H20.1666Z" fill="#8F9BBC" />
        </svg>

    )
}

export const ModalHex = () => {
    return (
        <svg width="80" height="72" viewBox="0 0 80 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2713_20797)">
                <path d="M32.9766 6.94338C34.5236 6.05021 36.4296 6.05021 37.9766 6.94338L61.9884 20.8066C63.5354 21.6998 64.4884 23.3504 64.4884 25.1368V52.8632C64.4884 54.6496 63.5354 56.3002 61.9884 57.1934L37.9766 71.0566C36.4296 71.9498 34.5236 71.9498 32.9766 71.0566L8.96471 57.1934C7.41771 56.3002 6.46471 54.6496 6.46471 52.8632V25.1368C6.46471 23.3504 7.41771 21.6998 8.96471 20.8066L32.9766 6.94338Z" fill="#FEF3C7" />
                <path d="M73.1016 52C73.1016 61.734 65.2106 69.625 55.4766 69.625C45.7425 69.625 37.8516 61.734 37.8516 52C37.8516 42.266 45.7425 34.375 55.4766 34.375C65.2106 34.375 73.1016 42.266 73.1016 52Z" fill="#F59E0B" />
            </g>
            <defs>
                <clipPath id="clip0_2713_20797">
                    <rect width="78.0488" height="80" fill="white" transform="translate(0.976562 -8)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export const HexWithQuestionMark = () => {
    return (
        <svg width="70" height="67" viewBox="0 0 70 67" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.9756 1.50185C28.5226 0.608683 30.4286 0.608684 31.9756 1.50185L55.9874 15.3651C57.5344 16.2583 58.4874 17.9089 58.4874 19.6952V47.4217C58.4874 49.208 57.5344 50.8587 55.9874 51.7518L31.9756 65.6151C30.4286 66.5083 28.5226 66.5083 26.9756 65.6151L2.96374 51.7518C1.41673 50.8587 0.463736 49.208 0.463736 47.4217V19.6952C0.463736 17.9089 1.41673 16.2583 2.96374 15.3651L26.9756 1.50185Z" fill="#FEF3C7" />
            <circle cx="48.4756" cy="45.5585" r="20.625" fill="#F59E0B" />
            <path d="M48.4427 52.4987C47.7674 52.4987 47.1904 52.7132 46.7128 53.1411C46.2356 53.5686 45.9956 54.0863 45.9956 54.6966C45.9956 55.3844 46.237 55.9241 46.7192 56.3168C47.2028 56.7095 47.766 56.9077 48.4097 56.9077C49.0641 56.9077 49.6351 56.7124 50.1236 56.325C50.6119 55.9358 50.8558 55.3936 50.8558 54.6963C50.8558 54.0859 50.6225 53.5682 50.1556 53.1407C49.6887 52.7136 49.1184 52.4987 48.4417 52.4987H48.4427ZM55.341 37.3681C54.7197 36.425 53.8316 35.6709 52.7 35.1244C51.5748 34.5829 50.2642 34.3085 48.8035 34.3085C47.2309 34.3085 45.8312 34.6373 44.6452 35.286C43.4579 35.9358 42.5404 36.7695 41.9183 37.7637C41.2934 38.7628 40.9756 39.7677 40.9756 40.7512C40.9756 41.2757 41.1943 41.7653 41.6232 42.2056C42.0543 42.6487 42.5904 42.8728 43.2168 42.8728C44.2856 42.8728 45.0138 42.2457 45.3845 41.0158C45.7222 39.9985 46.1387 39.2227 46.6201 38.7085C47.0732 38.2242 47.8082 37.9785 48.8035 37.9785C49.662 37.9785 50.3395 38.2192 50.8771 38.7153C51.4065 39.2045 51.6643 39.7876 51.6643 40.4977C51.6643 40.8563 51.5805 41.1784 51.4125 41.483C51.2339 41.8026 51.0113 42.0962 50.751 42.3576C50.4759 42.6331 50.0217 43.0468 49.4011 43.5907C48.6781 44.2245 48.0948 44.7795 47.6701 45.2404C47.2263 45.7194 46.8666 46.2825 46.5978 46.9135C46.3293 47.5487 46.193 48.3068 46.193 49.1678C46.193 49.9025 46.3979 50.4713 46.8027 50.8587C47.2032 51.2421 47.702 51.4374 48.2861 51.4374C49.4028 51.4374 50.1045 50.8271 50.3132 49.6852C50.419 49.2104 50.4993 48.8788 50.5526 48.6903C50.6008 48.5188 50.6708 48.3426 50.7606 48.1669C50.848 47.9957 50.9861 47.8019 51.1725 47.5899C51.3667 47.3658 51.6291 47.1031 51.9508 46.8094C53.1542 45.7336 54.0031 44.9553 54.474 44.4944C54.9597 44.0197 55.3833 43.4483 55.733 42.7979C56.0931 42.1321 56.2756 41.3477 56.2756 40.4689C56.2763 39.3572 55.9606 38.313 55.3393 37.3667" fill="white" />
        </svg>
    )
}

export const LeftArrow = () => {
    return (
        <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.41 1.41L6 0L0 6L6 12L7.41 10.59L2.83 6L7.41 1.41Z" fill="#8F9BBC" fill-opacity="0.54" />
        </svg>
    )
}

export const RightArrow = () => {
    return (
        <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.00009 0L0.590088 1.41L5.17009 6L0.590088 10.59L2.00009 12L8.00009 6L2.00009 0Z" fill="#8F9BBC" fill-opacity="0.54" />
        </svg>
    )
}

export const QuestionMark = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.25 12.5H8.75V11H7.25V12.5ZM8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14 3.86 15.5 8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 8 0.5ZM8 14C4.6925 14 2 11.3075 2 8C2 4.6925 4.6925 2 8 2C11.3075 2 14 4.6925 14 8C14 11.3075 11.3075 14 8 14ZM8 3.5C6.3425 3.5 5 4.8425 5 6.5H6.5C6.5 5.675 7.175 5 8 5C8.825 5 9.5 5.675 9.5 6.5C9.5 8 7.25 7.8125 7.25 10.25H8.75C8.75 8.5625 11 8.375 11 6.5C11 4.8425 9.6575 3.5 8 3.5Z" fill="#273B4A" />
        </svg>
    )
}

export const SettingsMenu = () => {
    return (
        <svg width="22" height="14" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.2225 0.25H3.7775C1.97 0.25 0.5 1.93 0.5 4C0.5 6.07 1.97 7.75 3.7775 7.75H12.2225C14.03 7.75 15.5 6.07 15.5 4C15.5 1.93 14.03 0.25 12.2225 0.25ZM4.43 5.845L2.5025 3.9175L3.0275 3.3925L4.43 4.795L7.07 2.155L7.595 2.68L4.43 5.845Z" fill="#273B4A" />
        </svg>
    )
}