import { Box, Button, Accordion, AccordionDetails, createStyles, FormControl, Grid, InputAdornment, InputLabel, makeStyles, MenuItem, Select, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StyledNoBorderAccordion, StyledAccordionDetails, StyledAccordionSummary } from "../../components/CustomAccordion";
import { DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { actionCreators } from '../../redux/actionCreators';
import { apiService } from '../../services/api.service';
import SearchTextField from '../../submodules/template/components/SearchTextField';
import FilterListIcon from '@mui/icons-material/FilterList';
import { pl } from 'date-fns/locale';
import ArraySearcher from '../../submodules/template/services/arraySearcher';
import { ReactComponent as SearchIcon } from '../../submodules/template/assets/Search_loupe.svg';
import { useTheme } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';
import { DataFormatter } from '../../services/dataFormatter';

const miesiace = DataFormatter.getMonth();

const FiltersZestawieniaAccordion = ({ open, setOpen, nieobecnosci, nieobecnosciFiltered, setNieobecnosciFiltered, setOpenModal, year, month, setYear, setMonth }) => {

    const theme = useTheme();

    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        if (inputValue) {
            let filteredArray = []
            filteredArray = filterByText(nieobecnosci, inputValue)
            setNieobecnosciFiltered(filteredArray)
        }
        else {
            setNieobecnosciFiltered(nieobecnosci)
        }
    }, [nieobecnosci])


    // useEffect(() => {
    //     apiService.getNieobecnosciWszystkichPracownikow({ year, month }).then(res => setData(res.data))
    // }, [year, month]);

    // useEffect(() => {
    //     setNieobecnosciFiltered(data)
    // }, [data])

    // const handleYearChange = (value) => {
    //     setYear(value.getFullYear().toString())
    // };

    // const handleMonthChange = (event, props) => {
    //     setMonth(event.target.value);
    // };
    const handleYearChange = (value) => {
        if (value) {
            setYear(value.getFullYear().toString())
        }
        else {
            setYear(null)
        }

    };

    const handleMonthChange = (event, props) => {
        setMonth(event.target.value);
    };


    const handleInputChange = (value) => {
        setInputValue(value)
        let filteredArray = []
        filteredArray = filterByText(nieobecnosci, value)

        setNieobecnosciFiltered(filteredArray);
    }



    const filterByText = (array, input) => {
        let filteredArray = []
        filteredArray = ArraySearcher(array, input)
        return filteredArray;
    }






    return (
        <>
            <Accordion expanded={open} style={{ border: "none", elevation: 0, boxShadow: "none" }}>
                <Grid container direction={'row'} alignItems="center" pt={4} pb={1}>
                    <Grid item xs={8} ml={3}>
                        <TextField sx={{ '.MuiFormLabel-root[data-shrink=false]': { top: "-5px" } }}
                            fullWidth
                            id="outlined-basic"
                            label="Szukaj"
                            variant="outlined"
                            onChange={(e) => handleInputChange(e.target.value)}
                            value={inputValue}
                            InputProps={{ style: { height: "44px" }, endAdornment: <SearchIcon style={{ width: "25px", height: "25px" }} />, }}
                        />
                    </Grid>
                    <Grid item xs ml={3}>
                        <Button
                            startIcon={<FilterListIcon />}
                            sx={open ? { backgroundColor: theme.palette.primary["50"] } : {}} variant="text" onClick={() => setOpen(!open)}>Filtruj</Button>
                    </Grid>
                    <Grid item xs pl={3} pr={2}>
                        <Button
                            onClick={() => setOpenModal(true)}
                            variant='contained'
                            style={{ textTransform: 'capitalize' }}>Eksportuj</Button>
                    </Grid>
                </Grid>
                <StyledAccordionSummary>
                </StyledAccordionSummary>
                <AccordionDetails>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid container direction={'row'} alignItems="center">
                            <Grid item xs={4.5} ml={0.75} mr={2.5}>
                                <DatePicker
                                    views={['year']}
                                    size='small'
                                    label='Rok'
                                    value={year}
                                    onChange={(newValue) => handleYearChange(newValue)}
                                    renderInput={(params) =>
                                        <TextField
                                            fullWidth
                                            sx={{ svg: { color: '#c2c9db' } }}
                                            size='small' {...params} />} />
                            </Grid>
                            <Grid item xs={4.5} ml={0.75} >
                                <FormControl fullWidth
                                    size='small'>
                                    <InputLabel id='miesiac'>Miesiąc</InputLabel>
                                    <Select
                                        defaultValue=''
                                        labelId='month'
                                        id='monthcSelect'
                                        value={month}
                                        label='Miesiąc'
                                        onChange={(e, newValue) => handleMonthChange(e, newValue)}
                                    >
                                        {miesiace.map((item, index) => (
                                            <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </LocalizationProvider>
                </AccordionDetails>
            </Accordion>

        </>
    );
}

export default FiltersZestawieniaAccordion;