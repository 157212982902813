import React, { Component } from 'react';
import PatternService from '../services/pattern-service/patternService';
import PrintIcon from '@mui/icons-material/Print';
const patternPrinterURL = "/wzorce/patternPrinter/";

class PrinterButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            base64EncodedPDF: '',
            name: '',
            printURL: patternPrinterURL + this.props.patternId
        }

        this.getDocument = this.getDocument.bind(this);
        this.openPrintDocument = this.openPrintDocument.bind(this);
    }


    async openPrintDocument(e) {
        e.stopPropagation();
        await this.getDocument();
        const firstPath = "/" + window.location.pathname.split('/')[1];
        let pdfWindow = await window.open(firstPath + "/wzorce/druk/wczytywanie")
        setTimeout(() => {
            pdfWindow.document.write("<html><head><title>" + this.state.name + "</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
            pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(this.state.base64EncodedPDF) + "#toolbar=0&navpanes=0&scrollbar=0'></embed></body></html>");
            pdfWindow.history.pushState({}, "", firstPath + "/wzorce/druk");
        }, 500);
    }



    async getDocument() {
        await PatternService.getInstance(this.props.apiurl, this.props.apiAuthHeader).getConvertedPatternWithData(this.props.patternId, this.props.printParams).then(response => {
            this.setState({
                base64EncodedPDF: response.data.base64EncodedPdf,
                name: response.data.name
            })
        })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        return (
            <>
                <PrintIcon titleAccess='Drukuj' fontSize="inherit" onClick={(e) => this.openPrintDocument(e)} />
            </>
        )
    }
}

export default PrinterButton;