import React from 'react';
import { Typography, Box } from '@mui/material';

export default function MojProfilTitleAccordion({ title, handleModalZgloszenie, showZgloszenie }) {

    const zglosZmianyStyles = {
        '&:hover': {
            color: 'primary.700'
        }
    };

    return (
        <Box
            width={'100%'}
            py={1}
            pl={2}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
        >
            <Typography
                fontWeight={'bold'}
                variant='h3'>{title}</Typography>

            {showZgloszenie && <Typography
                mr={2}
                onClick={handleModalZgloszenie}
                variant='h5'
                color={'primary'}
                sx={zglosZmianyStyles}>Zgłoś zmianę adminstratorowi</Typography>
            }
        </Box>
    )
};