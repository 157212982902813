import React, { Component } from 'react';
import { Box, Button } from '@mui/material';
import PatternService from '../../services/pattern-service/patternService';
import ActionModal from '../../common/modals/actionModal';
import TextEditor from '../../common/textEditor';
import PatternForm from '../../common/patternForm';
import { useParams } from 'react-router-dom';
import Spinner from '../../../../components/Spinner';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


var initialState = {
    id_Pattern: '',
    typ_Wzorca: 0,
    name: '',
    description: '',
    id_Systemu: '',
    editorValue: '',
    nameError: '',
    descriptionError: '',
    id_SystemuError: '',
    isPortrait: true,
    base64EncodedPDF: '',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    showReturnModal: false,
    isMacrosLoaded: false,
    isEndOfDocumentVisible: true,
    permission: ''
}

const patternPreviewerURL = "/wzorce/patternPreviewer";
let patternService;

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />
    }
}

class PatternEditor extends Component {




    constructor(props) {
        super(props);
        this.state = initialState;
        //patternService = new PatternService(this.props.apiAuthHeader);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleid_SystemuChange = this.handleid_SystemuChange.bind(this);
        this.changeOrientationToPortrait = this.changeOrientationToPortrait.bind(this);
        this.changeOrientationToLandscape = this.changeOrientationToLandscape.bind(this);
        this.handleMarginBottomChange = this.handleMarginBottomChange.bind(this);
        this.handleMarginLeftChange = this.handleMarginLeftChange.bind(this);
        this.handleMarginRightChange = this.handleMarginRightChange.bind(this);
        this.handleMarginTopChange = this.handleMarginTopChange.bind(this);
        this.handleShowReturnModal = this.handleShowReturnModal.bind(this);
        this.handleCloseReturnModal = this.handleCloseReturnModal.bind(this);
        this.restoreMargins = this.restoreMargins.bind(this);
        this.getPreviewPattern = this.getPreviewPattern.bind(this);
        this.handleModalAction = this.handleModalAction.bind(this);
        this.onChangeIsEndOfDocumentVisible = this.onChangeIsEndOfDocumentVisible.bind(this);
        this.handleTypWzorcaChange = this.handleTypWzorcaChange.bind(this);
    }

    async componentDidMount() {
        await PatternService.getInstance(this.props.apiurl, this.props.apiAuthHeader).getRola().then(res => {
            if (res.data.dostepneRole.includes(1)) {
                this.setState({ permission: true })
            }
            else {
                this.setState({ permission: false })
            }
        })
        this.getPatternMacros();
        window.addEventListener("beforeunload", this.onUnload);
        //this.props.promptActivate();
        this.getEditorValue();
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onUnload);
        //this.props.promptDeactivate();

    }

    onUnload = e => {
        e.preventDefault();
        e.returnValue = '';
    }

    getPatternMacros() {
        PatternService.getInstance(this.props.apiurl, this.props.apiAuthHeader).getPatternMacros().then(response => {
            this.setState({
                patternMacros: response.data,
                isMacrosLoaded: true,
            })
        });
    }

    async getEditorValue() {
        await PatternService.getInstance(this.props.apiurl, this.props.apiAuthHeader).getPatternById(this.props.match.params.id).then(response => {
            let that = this;
            this.setState({
                id_Pattern: that.props.match.params.id,
                typ_Wzorca: response.data.typ_Wzorca,
                name: response.data.name,
                description: response.data.description,
                id_Systemu: response.data.id_Systemu,
                editorValue: response.data.patternDocument,
                marginTop: response.data.marginTop,
                marginBottom: response.data.marginBottom,
                marginLeft: response.data.marginLeft,
                marginRight: response.data.marginRight,
                isEndOfDocumentVisible: response.data.isEndOfDocumentVisible

            });

            initialState.marginRight = this.state.marginRight;
            initialState.marginLeft = this.state.marginLeft;
            initialState.marginTop = this.state.marginTop;
            initialState.marginBottom = this.state.marginBottom;

            if (response.data.isPortrait === '0') {
                this.setState({
                    isPortrait: false
                })
            }
            else {
                this.setState({
                    isPortrait: true
                })
            }

        });
    }

    handleCloseReturnModal() {
        this.setState({ showReturnModal: false })
    }
    handleShowReturnModal() {
        this.setState({ showReturnModal: true });
    }
    handleNameChange(event) {
        this.setState({ name: event.target.value })

    }
    handleDescriptionChange(event) {
        this.setState({ description: event.target.value })

    }
    handleid_SystemuChange(event) {
        this.setState({ id_Systemu: event.target.value })

    }
    handleTypWzorcaChange(event) {
        this.setState({ typ_Wzorca: event.target.value })

    }
    onChangeIsEndOfDocumentVisible() {

        this.setState({
            isEndOfDocumentVisible: !this.state.isEndOfDocumentVisible
        })
    }
    validate() {
        let nameError = "";
        let descriptionError = "";

        if (this.state.name.length === 0) {
            nameError = "Pole nazwa nie może być puste";
        }
        if (this.state.name.length > 100) {
            nameError = "Nazwa przekracza 100 znaków";
        }
        if (this.state.description.length === 0) {
            descriptionError = "Pole opis nie może być puste";
        }
        if (this.state.description.length > 500) {
            descriptionError = "Opis przekracza 500 znaków";
        }

        if (nameError || descriptionError) {
            this.setState({ nameError, descriptionError });
            return false;
        }


        return true;
    }

    async onSaveClick(event) {

        event.preventDefault();
        const isValid = this.validate();
        if (isValid) {
            var isportrait = '1';
            if (this.state.isPortrait === true)
                isportrait = '1';
            else
                isportrait = '0';
            await PatternService.getInstance(this.props.apiurl, this.props.apiAuthHeader).updatePattern(this.state.id_Pattern, this.state.typ_Wzorca, this.state.name, this.state.description, this.state.id_Systemu, this.state.editorValue, isportrait, this.state.marginTop, this.state.marginBottom, this.state.marginLeft, this.state.marginRight, this.state.isEndOfDocumentVisible);
        }
    }
    handleEditorChange(content) {
        if (content) {
            this.setState({ editorValue: content.level.content });
        }
    }
    handleMarginTopChange(event) {
        this.setState({ marginTop: event.target.value })
        if (event.target.value > 200) {
            this.setState({ marginTop: 200 })
        }
        if (event.target.value < 0) {
            this.setState({ marginTop: 0 })
        }

        if (event.target.value === '')
            this.setState({ marginTop: 0 })

    }
    handleMarginBottomChange(event) {
        this.setState({ marginBottom: event.target.value })
        if (event.target.value > 200) {
            this.setState({ marginBottom: 200 })
        }
        if (event.target.value < 0) {
            this.setState({ marginBottom: 0 })
        }

        if (event.target.value === '')
            this.setState({ marginBottom: 0 })
    }
    handleMarginRightChange(event) {
        this.setState({ marginRight: event.target.value })
        if (event.target.value > 200) {
            this.setState({ marginRight: 200 })
        }
        if (event.target.value < 0) {
            this.setState({ marginRight: 0 })
        }
        if (event.target.value === '')
            this.setState({ marginRight: 0 })
    }
    handleMarginLeftChange(event) {
        this.setState({ marginLeft: event.target.value })
        if (event.target.value > 200) {
            this.setState({ marginLeft: 200 })
        }
        if (event.target.value < 0) {
            this.setState({ marginLeft: 0 })
        }
        if (event.target.value === '')
            this.setState({ marginLeft: 0 })
    }



    changeOrientationToPortrait(e) {
        this.setState({
            isPortrait: true
        })
    }
    changeOrientationToLandscape(e) {
        this.setState({
            isPortrait: false
        })
    }

    restoreMargins() {
        this.setState({
            marginBottom: initialState.marginBottom,
            marginTop: initialState.marginTop,
            marginLeft: initialState.marginLeft,
            marginRight: initialState.marginRight
        })
    }

    getPreviewPattern() {
        var isportrait = '1';
        if (this.state.isPortrait === true)
            isportrait = '1';
        else
            isportrait = '0';

        var data = {
            Name: this.state.name,
            PatternDocument: this.state.editorValue,
            IsPortrait: isportrait,
            MarginTop: this.state.marginTop,
            MarginBottom: this.state.marginBottom,
            MarginLeft: this.state.marginLeft,
            MarginRight: this.state.marginRight,
            IsEndOfDocumentVisible: this.state.isEndOfDocumentVisible
        }

        PatternService.getInstance(this.props.apiurl, this.props.apiAuthHeader).getPreviewPattern(data).then(response => {

            this.setState({
                base64EncodedPDF: response.data,
            })
            this.printDocument();

        })
            .catch(error => {
                console.log(error);
            });
    }

    async printDocument() {

        //localStorage.setItem("pdf", this.state.base64EncodedPDF);
        //window.open(patternPreviewerURL, "_blank");
        /*let pdfWindow;
        if(this.state.name === '' || !this.state.name){
            pdfWindow = await window.open('PrintPreview', "Dokument bez nazwy");
        }
        else{
            pdfWindow = await window.open('PrintPreview', this.state.name);
        }*/
        const firstPath = "/" + window.location.pathname.split('/')[1];
        let pdfWindow = await window.open(firstPath + "/wzorce/lista/wczytywanie")
        setTimeout(() => {
            pdfWindow.document.write("<html<head><title>" + this.state.name + "</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
            pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(this.state.base64EncodedPDF) + "#toolbar=0&navpanes=0&scrollbar=0'></embed></body></html>");
            pdfWindow.history.pushState({}, "", firstPath + "/wzorce/lista");
        }, 1);
    }

    handleModalAction() {
        //window.location.href = "/wzorce";
        this.props.navigation("/wzorce/lista");
    }

    render() {
        if (!this.state.permission) {
            return (
                <div>
                    Brak dostępu
                </div>
            )
        }
        else {
            return (
                <Box>
                    <PatternForm onSaveClick={this.onSaveClick}
                        typWzorca={this.state.typ_Wzorca} handleTypWzorcaChange={this.handleTypWzorcaChange}
                        marginTop={this.state.marginTop} handleMarginTopChange={this.handleMarginTopChange}
                        marginBottom={this.state.marginBottom} handleMarginBottomChange={this.handleMarginBottomChange}
                        marginLeft={this.state.marginLeft} handleMarginLeftChange={this.handleMarginLeftChange}
                        marginRight={this.state.marginRight} handleMarginRightChange={this.handleMarginRightChange}
                        isPortrait={this.state.isPortrait} changeOrientationToPortrait={this.changeOrientationToPortrait} changeOrientationToLandscape={this.changeOrientationToLandscape}
                        restoreMargins={this.restoreMargins} getPreviewPattern={this.getPreviewPattern}
                        name={this.state.name} nameError={this.state.nameError} handleNameChange={this.handleNameChange}
                        description={this.state.description} descriptionError={this.state.descriptionError} handleDescriptionChange={this.handleDescriptionChange}
                        Id_Systemu={this.state.id_Systemu} handleId_SystemuChange={this.handleid_SystemuChange}
                        isEndOfDocumentVisible={this.state.isEndOfDocumentVisible}
                        onChangeIsEndOfDocumentVisible={this.onChangeIsEndOfDocumentVisible}
                    ></PatternForm>
                    <Box>
                        {this.state.isMacrosLoaded ? <TextEditor
                            patternMacros={this.state.patternMacros}
                            editorValue={this.state.editorValue}
                            updateFunction={this.handleEditorChange} />
                            : <Box style={{ display: "flex", justifyContent: "center" }} >
                                <Box className="spinner-border" role="status">
                                    <Spinner />
                                </Box>
                            </Box>}
                    </Box>
                    <Box mt={2} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                        <Box>
                            <Button sx={{ textTransform: 'none' }} onClick={() => this.handleModalAction()} variant='text' startIcon={<ArrowBackIosIcon />}>Powrót</Button>
                        </Box>
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-around'}>
                            <Button sx={{ textTransform: 'none', ml: 2, mr: 2 }} variant='outlined' onClick={this.getPreviewPattern}>Podgląd wydruku</Button>
                            <Button sx={{ textTransform: 'none' }} variant='contained' onClick={this.onSaveClick}>Zapisz</Button>
                        </Box>

                    </Box>
                </Box>
            )
        }
    }

}

export default withRouter(PatternEditor);