import { Redirect } from 'react-router-dom'


export default function ForbiddenInterceptor(axiosResponse) {
    var segment_str = window.location.pathname;
    var segment_array = segment_str.split('/').filter(e => String(e).trim());
    if (axiosResponse && axiosResponse.response) {
        if (axiosResponse.response.status === 403 && segment_array.length > 1 && segment_array[1] !== "forbiddenPath") {
            let newUrl = '/' + segment_array[0] + '/forbiddenPath';
            window.location.replace(newUrl);
        }
        else {
            return Promise.reject(axiosResponse);
        }
    }
}