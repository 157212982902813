import React from 'react';

export const MailIcon = () => {
    return (
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 14H2V4L10 9L18 4V14ZM10 7L2 2H18L10 7Z" fill="#273B4A" />
        </svg>
    )
}

export const PasswordEye = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.257 10.962C21.731 11.582 21.731 12.419 21.257 13.038C19.764 14.987 16.182 19 12 19C7.81801 19 4.23601 14.987 2.74301 13.038C2.51239 12.7411 2.38721 12.3759 2.38721 12C2.38721 11.6241 2.51239 11.2589 2.74301 10.962C4.23601 9.013 7.81801 5 12 5C16.182 5 19.764 9.013 21.257 10.962V10.962Z" stroke="#273B4A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#273B4A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const LeftArrow = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3275 2.8275L12 1.5L4.5 9L12 16.5L13.3275 15.1725L7.155 9L13.3275 2.8275Z" fill="#273B4A" />
        </svg>
    )
}

export const Wrong = () => {
    return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.49998 2.08334C5.12998 2.08334 1.58331 5.63001 1.58331 10C1.58331 14.37 5.12998 17.9167 9.49998 17.9167C13.87 17.9167 17.4166 14.37 17.4166 10C17.4166 5.63001 13.87 2.08334 9.49998 2.08334ZM3.16665 10C3.16665 6.50084 6.00081 3.66668 9.49998 3.66668C10.9646 3.66668 12.3104 4.16543 13.3791 5.00459L4.50456 13.8792C3.6654 12.8104 3.16665 11.4646 3.16665 10ZM9.49998 16.3333C8.0354 16.3333 6.68956 15.8346 5.62081 14.9954L14.4954 6.12084C15.3346 7.18959 15.8333 8.53543 15.8333 10C15.8333 13.4992 12.9991 16.3333 9.49998 16.3333Z" fill="#EF4444"/>
    </svg>
    )
}

export const LifeBuoy =() => {
    return(
        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.4175 4.4165C-1.4725 10.3065 -1.4725 19.8585 4.4175 25.7495C10.3075 31.6405 19.8575 31.6375 25.7475 25.7475C31.6375 19.8575 31.6405 10.3075 25.7495 4.4175C19.8595 -1.4725 10.3095 -1.4725 4.4175 4.4175V4.4165ZM25.0425 5.1245C25.8517 5.94427 26.558 6.8597 27.1455 7.8505L23.0655 11.9305C22.636 10.8365 21.9851 9.84302 21.1535 9.0125C20.3226 8.18013 19.3284 7.5288 18.2335 7.0995L22.3135 3.0195C23.3059 3.60728 24.2227 4.31419 25.0435 5.1245H25.0425ZM9.7225 20.4445C8.30106 19.022 7.50243 17.0934 7.50205 15.0824C7.50168 13.0714 8.29959 11.1425 9.7205 9.7195C11.1438 8.2993 13.0723 7.5017 15.083 7.5017C17.0937 7.5017 19.0222 8.2993 20.4455 9.7195C21.8647 11.1431 22.6616 13.0713 22.6616 15.0815C22.6616 17.0917 21.8647 19.0199 20.4455 20.4435C19.0228 21.8641 17.0945 22.6622 15.0839 22.6626C13.0733 22.663 11.1448 21.8656 9.7215 20.4455L9.7225 20.4445ZM5.1235 25.0425C4.31349 24.2214 3.60661 23.3047 3.0185 22.3125L7.0985 18.2325C7.53011 19.3259 8.1817 20.3189 9.01289 21.1501C9.84407 21.9813 10.8371 22.6329 11.9305 23.0645L7.8485 27.1445C6.8785 26.5645 5.9585 25.8745 5.1225 25.0415L5.1235 25.0425ZM7.8515 3.0165L11.9335 7.0985C10.8395 7.52869 9.84579 8.17967 9.01445 9.01085C8.18311 9.84202 7.53192 10.8356 7.1015 11.9295L3.0195 7.8495C3.5995 6.8795 4.2895 5.9595 5.1245 5.1215C5.94507 4.31177 6.86114 3.6049 7.8525 3.0165H7.8515ZM22.3155 27.1445L18.2335 23.0625C19.3277 22.6329 20.3216 21.9822 21.153 21.1512C21.9844 20.3201 22.6354 19.3265 23.0655 18.2325L27.1475 22.3125C26.5675 23.2825 25.8775 24.2045 25.0425 25.0425C24.2219 25.8516 23.3058 26.5578 22.3145 27.1455L22.3155 27.1445Z" fill="white"/>
        </svg>
    )
}

export const Phone =()=>{
    return(
        <svg width="12.75" height="12.75" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.62 7.79C5.06 10.62 7.38 12.93 10.21 14.38L12.41 12.18C12.68 11.91 13.08 11.82 13.43 11.94C14.55 12.31 15.76 12.51 17 12.51C17.55 12.51 18 12.96 18 13.51V17C18 17.55 17.55 18 17 18C7.61 18 0 10.39 0 1C0 0.45 0.45 0 1 0H4.5C5.05 0 5.5 0.45 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79Z" fill="#273B4A"/>
        </svg>
    )
}

export const Letter =()=>{
    return(
        <svg width="12.75" height="12.75" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 4L10 9L2 4V2L10 7L18 2V4Z" fill="#273B4A"/>
        </svg>
    )
}


