import { Avatar, Divider, FormControl, FormControlLabel, IconButton, Menu, MenuItem, Radio, RadioGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../redux/actionCreators';
import { apiService } from '../services/api.service';
import { Box } from '@mui/system';
import { cacheService } from '../services/cache.service';
import { QuestionMark, SettingsMenu } from '../styles/icons';


function HeaderMenu({ removeToken }) {

    const [anchorEl, setAnchorEl] = useState(null);
    const [user, setUser] = useState([]);
    const [foto, setFoto] = useState('');
    const open = Boolean(anchorEl);
    const danePrac = useSelector((state) => state.danePrac);
    const dispatch = useDispatch();
    const { addDanePrac, updateActiveUmowa } = bindActionCreators(actionCreators, dispatch);
    let navigate = useNavigate();

    useEffect(() => {
        getDane();
    }, [danePrac]);

    const getDane = () => {
        if (danePrac === null) {
            addDanePrac();
        }
        apiService.getDanePracownika().then(res => {
            setUser(res.data);
        });
        cacheService.getFotoPracownika().then(res => setFoto(res))
    };

    // function clearCache() {
    //     caches.keys().then(function (names) {
    //         for (let name of names)
    //             caches.delete(name);
    //     });
    // };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        removeToken();
        cacheService.clearCache();
    };

    const handleChangeIdPrac = (e) => {
        localStorage.setItem('ekadry2_idPrac', e.target.value)
        updateActiveUmowa(e.target.value)
        window.location.reload(false);
    }

    return (
        <Box>
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <Avatar src={`data:image/png;base64,${foto}` || null} />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <Box sx={{ pl: 2, pr: 2, pt: 1, width: "18vw", maxWidth: "350px", minWidth: "220px" }} >
                    <Typography m={2} ml={1} variant={'h3'} fontWeight={'bold'}>{user.imie} {user.nazwisko}</Typography>
                    <Typography ml={1} mb={'3px'} variant={'h5'} fontWeight={'bold'}>Twoje Profile</Typography>
                    <Divider style={{ marginLeft: '6px', marginRight: '10px' }} />
                    <Box m={2} fontSize={'small'} >
                        <RadioGroup defaultValue={localStorage.getItem('ekadry2_idPrac')}>
                            <FormControl>
                                {danePrac && danePrac.map((item, index) => {
                                    return (
                                        <FormControlLabel
                                            sx={item.idPrac == localStorage.getItem('ekadry2_idPrac') ? {
                                                backgroundColor: '#ECF4FD',
                                                borderRadius: '4px',
                                                width: '100%',
                                                mr: 0
                                            } : { mr: 0 }}
                                            key={index} size='small' onClick={handleChangeIdPrac} value={item.idPrac} control={<Radio size='small' sx={{
                                                mr: 1,
                                                "&.Mui-checked": {
                                                    "&, & + .MuiFormControlLabel-label": {
                                                        fontWeight: 'bold',
                                                        color: '#3879F6',
                                                    },
                                                }
                                            }} />} label={`IdPrac: ${item.idPrac}, Umowa: ${item.umowa}`} />
                                    )
                                })}
                            </FormControl>
                        </RadioGroup>
                    </Box>
                    <Typography ml={1} mb={'3px'} variant={'h5'} fontWeight={'bold'}>Szybkie Linki</Typography>
                    <Divider sx={{ marginLeft: '6px', marginRight: '10px', marginBottom: '7px' }} />
                    <MenuItem sx={{
                        mr: '9px',
                        ml: '5px',
                        maxHeight: "45px",
                        "&:hover": {
                            fontWeight: 'bold',
                            color: '#273B4A',
                            borderRadius: '4px',
                            backgroundColor: '#ECF4FD',
                        }
                    }} onClick={() => navigate('/profil/centrum-pomocy')} ><QuestionMark /><Typography mt={2} mb={2} ml={1} variant='h3' fontWeight={'bold'} color={'darkMode.500'}>Centrum Pomocy</Typography></MenuItem>
                    <MenuItem sx={{
                        mr: '9px',
                        ml: '5px',
                        maxHeight: "45px",
                        "&:hover": {
                            fontWeight: 'bold',
                            color: '#273B4A',
                            borderRadius: '4px',
                            backgroundColor: '#ECF4FD',
                        }
                    }} onClick={() => navigate('/twoje-konto/ustawienia')} ><SettingsMenu /><Typography mt={2} mb={2} ml={1} variant='h3' fontWeight={'bold'} color={'darkMode.500'}>Ustawienia</Typography></MenuItem>
                    <Divider style={{ marginLeft: '6px', marginRight: '10px' }} />
                    <MenuItem sx={{
                        mt: 2,
                        mr: '9px',
                        ml: '5px',
                        mb: 2,
                        maxHeight: "45px",
                        "&:hover": {
                            fontWeight: 'bold',
                            color: '#3879F6',
                            borderRadius: '4px',
                            backgroundColor: '#ECF4FD',
                        }
                    }} onClick={() => logout()}><LogoutIcon fontSize='small' color='primary' /><Typography mt={2} mb={2} ml={1} variant='h' color={'primary'}>Wyloguj</Typography></MenuItem>

                </Box>
            </Menu>
        </Box >
    )
}

export default HeaderMenu;