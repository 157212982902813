import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { withStyles } from '@mui/styles';

export const CustomAccordion = withStyles({
    root: {
        borderBottom: '1px solid #cdd1da',
        boxShadow: '0px 0px 5px 0px #d6ddef',
        "&$expanded": {
            margin: "auto"
        }
    },
    expanded: {}
})(MuiAccordion);

export const StyledNoBorderAccordion = withStyles({
    root: {
        border: 'none',
        boxShadow: "none",
        "&$expanded": {
            margin: "auto"
        },

    },
    expanded: {}
})(MuiAccordion);

export const StyledAccordionSummary = withStyles({
    root: {
        border: '1px solid white',
        boxShadow: 'none',
        minHeight: 0,
        maxHeight: 0,
        backgroundColor: '#ffffff',
        '&.Mui-expanded': {
            minHeight: 0,
            maxHeight: 0,
            backgroundColor: '#ffffff',
            borderRadious: '0px'
        },
    },
})(AccordionSummary);

export const StyledAccordionDetails = withStyles({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        border: '1px solid white',
        boxShadow: 'none',
        minHeight: 40,
        maxHeight: 40,
        backgroundColor: '#ffffff',
        '&::before': {
            border: 'none',
            boxShadow: 'none',

        },
        '&.Mui-expanded': {
            alignItems: 'center',
            boxShadow: 'none',

            backgroundColor: '#ffffff',
            borderRadious: '0px'
        },
    }
})(AccordionDetails);
