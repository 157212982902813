import styled from '@emotion/styled';
import { TextField } from '@mui/material';



export const CustomTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#9095a0 ',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#9095a0',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#9095a0',
        },
        '&:hover fieldset': {
            borderColor: '#2c2e33',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#518af7',
        },
    },
});