import React, { useState, useEffect } from 'react';
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary, Table, TableBody, TableContainer } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/system';
import { withStyles } from '@mui/styles';
import Spinner from '../../components/Spinner';
import { Converter } from '../../submodules/template/services/Converter';
import '../../styles/printModalZatrudnienie.css'
import DaneOsoboweTableRow from '../DaneOsoboweTableRow';
import MojProfilTitleAccordion from '../MojProfilTitlelAccordion';
import ModalZgloszenie from '../modals/ModalZgloszenie';

const Accordion = withStyles({
    root: {
        "&$expanded": {
            margin: "auto"
        }
    },
    expanded: {}
})(MuiAccordion);

export default function DaneOsobowe({ handlers: { dane, handleChangeHideDetails, expanded } }) {

    const [openModal, setOpenModal] = useState(false);
    const [showZgloszenie, setShowZgloszenie] = useState(false);
    const panel = 'panel2';

    const handleExpandAccordion = () => {
        setShowZgloszenie(!showZgloszenie);
    }

    useEffect(() => {
        if (expanded !== panel) {
            setShowZgloszenie(false);
        }
    }, [expanded])

    const handleModalZgloszenie = (e) => {
        e.stopPropagation();
        setOpenModal(true);
    }

    return (
        <>
            <Accordion sx={{ borderBottom: 1, borderBottomColor: 'neutral.200' }} expanded={expanded === panel} onChange={handleChangeHideDetails(panel)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${panel}a-content`}
                    id={`${panel}a-header`}
                    onClick={handleExpandAccordion}
                >
                    <MojProfilTitleAccordion
                        title="Dane osobowe"
                        showZgloszenie={showZgloszenie}
                        handleModalZgloszenie={handleModalZgloszenie} />
                </AccordionSummary>
                {dane ?
                    <Box px={2}>
                        <AccordionDetails>
                            <TableContainer sx={{ width: "100%" }} >
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <DaneOsoboweTableRow rodzajDane="PESEL"
                                            uzytkownikDane={dane.pesel} />
                                        <DaneOsoboweTableRow rodzajDane="Seria i numer dowodu"
                                            uzytkownikDane={dane.dowodNr} />
                                        <DaneOsoboweTableRow rodzajDane="Imię matki"
                                            uzytkownikDane={Converter.onlyFirstLetterCapital(dane.imieMatki)} />
                                        <DaneOsoboweTableRow rodzajDane="Imię ojca"
                                            uzytkownikDane={Converter.onlyFirstLetterCapital(dane.imieOjca)} />
                                        <DaneOsoboweTableRow rodzajDane="Oddział NFZ"
                                            uzytkownikDane={dane.nazwaOddzialNFZ} />
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Box>
                    : <Spinner />}
            </Accordion>
            {openModal && <ModalZgloszenie openModal={openModal} setOpenModal={setOpenModal} />}
        </>
    )
};