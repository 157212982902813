import { combineReducers } from "redux";
import loginReducer from "../submodules/template/loginRedux/loginReducer";
import { responseDataReducer } from "./responseDataReducer";

const reducers = combineReducers({
    loginData: loginReducer,
    rodzajeNieobecnosci: responseDataReducer.rodzajeNieobecnosciReducer,
    listaDostepnychUrlopow: responseDataReducer.listaDostepnychUrlopowReducer,
    listaZastepstw: responseDataReducer.listaZastepstw,
    listaZlozonychWnioskow: responseDataReducer.listaZlozonychWnioskow,
    listaMoichWnioskow: responseDataReducer.listaMoichWnioskow,
    listaNadgodzin: responseDataReducer.listaNadgodzin,
    podsumowanieRoku: responseDataReducer.podsumowanieRoku,
    dostepneNadgodziny: responseDataReducer.dostepneNadgodziny,
    listaPracownikow: responseDataReducer.listaPracownikow,
    danePrac: responseDataReducer.danePrac,
    infoZKadr: responseDataReducer.infoZKadrReducer,
    danePracownika: responseDataReducer.danePracownikaReducer,
    info: responseDataReducer.infoReducer,
    badania: responseDataReducer.badaniaReducer,
    pozyczki: responseDataReducer.pozyczkiReducer,
    wynagrodzenia: responseDataReducer.wynagrodzeniaReducer,
    pity: responseDataReducer.pityReducer,
    zatrudnienie: responseDataReducer.zatrudnienieReducer,
    szkolenia: responseDataReducer.szkoleniaReducer,
    nieobecnosci: responseDataReducer.nieobecnosciReducer,
    activeUmowa: responseDataReducer.activeUmowa,
    ApiVersion: responseDataReducer.ApiVersion,
    listaPrzelozeni: responseDataReducer.przelozeniReducer,

})

export default reducers