import React from 'react';
import { Button, IconButton, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

import './modal.css';
import { HexWithQuestionMark } from '../../../../styles/icons';

const DeleteModal = ({ open, handleClose, currentid_Pattern, currentPatternid_Systemu, currentPatternName, handleDeleteModal }) => {
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
            >

                <Box className='modal' display={'flex'} flexDirection={'column'} >
                    <Box display={'flex'} justifyContent={'flex-end'}>
                        <Box p={2}><IconButton onClick={handleClose}><CloseIcon /></IconButton></Box>
                    </Box>
                    <Box m={4} display={'flex'} justifyContent={'center'}>
                        <HexWithQuestionMark />
                    </Box>
                    <Box
                        display={"flex"}
                        sx={{ m: 4, textAlign: 'center' }}
                    >
                        <Typography>Czy na pewno chcesz usunąć wzorzec nr {currentid_Pattern} z ID Systemu {currentPatternid_Systemu} o nazwie "{currentPatternName}"? </Typography>
                    </Box>
                    <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} alignItems={'center'}>
                        <Box width={'50%'} display={'flex'} justifyContent={'space-around'} flexDirection={'column'}>
                            <Button sx={{ backgroundColor: 'primary.500' }} size='large' variant="contained" onClick={handleDeleteModal}>
                                Usuń!
                            </Button>
                            <Button
                                onClick={handleClose}
                                sx={{ textTransform: 'capitalize', mt: 2 }}
                                size='large'

                                variant="outlined">Zamknij</Button>
                        </Box>
                    </Box>

                </Box>
            </Modal>
        </>
    );
}

export default DeleteModal;