import { Box, Typography } from '@mui/material';
import React from 'react';


function PulpitBoxy(props) {

    return (
        <>
            <Box sx={{ flexDirection: 'column' }}>
                <Box style={{
                    position: 'absolute',
                    left: '50%',
                    top: '45%',
                    transform: 'translate(-50%, -50%)'
                }} >{props.icon}</Box>
                <Box sx={{
                    position: 'absolute',
                    height: 0,
                    width: "80%",
                    top: '60%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}>
                    <Typography variant='h3' fontWeight={'bold'} textTransform={'none'}>{props.name}</Typography>
                </Box>
            </Box>
        </>
    );
}
export default PulpitBoxy;