import React from 'react';
import { Typography, Card, CardActions, CardContent, CardMedia, Link } from '@mui/material';

function PomocCard(props) {
    return (
        <>
            <Card>
                <CardMedia
                    component="img"
                    height="140"
                    image={props.imageUrl}
                />
                <CardContent sx={{ ml: 1 }}>
                    <Typography sx={{ py: 1, fontWeight: 'bold' }} variant="h2">{props.title}</Typography>
                    <Typography variant="h4" color="neutral.600">{props.content}</Typography>
                </CardContent>
                <CardActions sx={{ ml: 2, mb: 2 }}>
                    <Link
                        href={props.href}
                        style={{ cursor: 'pointer' }}
                        underline="hover"
                        variant="h4">
                        {props.text}
                    </Link>
                </CardActions>
            </Card>
        </>
    );
}
export default PomocCard;