import React, { Component } from 'react';

class PatternLoading extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }


    render() {
        return (
            <div>
                <h2>Trwa ładowanie dokumentu...</h2>
            </div>
        )
    }
}

export default PatternLoading;