import React from 'react';
import { Editor } from "@tinymce/tinymce-react";


function TextEditor(props){

let tablica = props.patternMacros;
    return (
        <Editor
                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                        initialValue={props.editorValue}
                        selector="textEditor"
                        toolbar_mode='floating'
                        init={{
                            language: 'pl',
                            height: 500,
                            menubar: true,
                            plugins: [
                                'advlist autolink lists link image',
                                'charmap print preview anchor help',
                                'searchreplace visualblocks code',
                                'insertdatetime media table paste wordcount',
                                'fullscreen pagebreak',
                            ],
                            table_default_attributes: {
                                'cellpadding': '6',
                                'border': '1'
                              },
                            setup: editor => {
                                
                                editor.ui.registry.addMenuButton('mybutton', {
                                    text: 'Wstaw wartość specjalną',
                                    fetch: function (callback) {
                                        var items1 = [];
                                        props.patternMacros.forEach(function (item) {
                                            var elem = {
                                                type: 'menuitem',
                                                text: item,
                                                onAction: function () {
                                                    editor.insertContent(item);
                                                }
                                            }
                                            items1.push(elem);
                                        })
                                        /*var items = [
                                          {
                                            type: 'menuitem',
                                            text: 'Menu item 1',
                                            onAction: function () {
                                              editor.insertContent('&nbsp;<em>You clicked menu item 1!</em>');
                                            }
                                          },
                                          
                                        ];*/
                                        callback(items1);
                                    }
                                });
                            },

                            toolbar1:
                                'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help | mybutton pagebreak'

                        }}
                        onChange={(content) => props.updateFunction(content)}

                    />
    )
}

export default TextEditor;