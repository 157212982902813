import { Component } from "react";
import Axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

toast.configure();

export class PatternService extends Component {

  static classInstance = null;
  static apiAuthHeader = null;
  static url = null;
  static getInstance(apiurl, apiAuthHeader) {

    if (PatternService.classInstance === null) {
      PatternService.classInstance = new PatternService();
    }
    PatternService.classInstance.apiAuthHeader = apiAuthHeader;
    PatternService.classInstance.url = apiurl;
    return this.classInstance;
  }

  async getPatternById(id) {

    return Axios.get(this.url + "api/PatternService/Pattern/?ID_PATTERN=" + id, { headers: this.apiAuthHeader });
  }
  getAllPatternsByIdSystemu() {

    return Axios.get(this.url + "api/PatternService/GetAllPatternsByIdSystemu", { headers: this.apiAuthHeader });
  }

  getPatternMacros() {
    return Axios.get(this.url + "api/PatternService/GetPatternMacros", { headers: this.apiAuthHeader });
  }

  addPattern(name, typ_Wzorca, description, Id_Systemu, patternDocument, isPortrait, marginTop, marginBottom, marginLeft, marginRight, isEndOfDocumentVisible) {

    Axios.post(this.url + "api/PatternService/Pattern", {
      Name: name,
      Typ_Wzorca: typ_Wzorca,
      Description: description,
      Id_Systemu: Id_Systemu,
      PatternDocument: patternDocument,
      isPortrait,
      MarginTop: marginTop,
      MarginBottom: marginBottom,
      MarginLeft: marginLeft,
      MarginRight: marginRight,
      IsEndOfDocumentVisible: isEndOfDocumentVisible,

    },
      { headers: this.apiAuthHeader })
      .then(function (response) {
        return true;
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Coś poszło nie tak");

        return false;
      });
  }

  updatePattern(ID_PATTERN, typ_Wzorca, name, description, Id_Systemu, patternDocument, isPortrait, marginTop, marginBottom, marginLeft, marginRight, isEndOfDocumentVisible) {

    Axios.put(this.url + "api/PatternService/Pattern", {
      ID_PATTERN,
      typ_Wzorca,
      name,
      description,
      Id_Systemu,
      patternDocument,
      isPortrait,
      marginTop,
      marginBottom,
      marginLeft,
      marginRight,
      isEndOfDocumentVisible,
    },
      { headers: this.apiAuthHeader })
      .then(function (response) {
        toast.success("Wzorzec zaktualizowany pomyślnie");
      })
      .catch(function (error) {
        toast.error("Coś poszło nie tak");
        console.log(error);
      });
  }

  deletePattern(ID_PATTERN) {
    Axios.delete(this.url + "api/PatternService/Pattern/?ID_PATTERN=" + ID_PATTERN, { headers: this.apiAuthHeader })
      .then(function (response) {
        toast.success("Wzorzec usunięto pomyślnie");
      })
      .catch(function (error) {
        toast.error("Coś poszło nie tak");
        console.log(error);
      });
  }

  getConvertedPatternWithData(id, data) {
    return Axios.post(this.url + "api/PatternService/Print/?ID_PATTERN=" + id, JSON.stringify(data), { headers: this.apiAuthHeader });
  }

  getConvertedPatternWithDataDlaWniosku(IdPrac, IdWniosek, TypWniosku, KodRodzajuNieobecnosci, NazwaRodzajuNieobecnosci, DataOd, DataDo, DataWn, hhmmOd, hhmmDo) {
    return Axios.post(this.url + "api/PatternService/PrintWniosek", {
      IdPrac,
      IdWniosek,
      TypWniosku,
      KodRodzajuNieobecnosci,
      NazwaRodzajuNieobecnosci,
      DataOd,
      DataDo,
      DataWn,
      hhmmOd,
      hhmmDo
    }, { headers: this.apiAuthHeader });
  }
  getConvertedPattern(id) {
    return Axios.post(this.url + "api/PatternService/Print/?ID_PATTERN=" + id, {}, { headers: this.apiAuthHeader });
  }

  getPreviewPattern(data) {
    return Axios.post(this.url + "api/PatternService/PrintPreview/", data, { headers: this.apiAuthHeader });
  }
  getRola() {
    const requestOptions = { method: 'GET', headers: this.apiAuthHeader };
    return Axios.get(this.url + 'api/GetRole', requestOptions);
  }

  getExportedPatternById(id) {
    return Axios.get(this.url + "api/PatternService/ExportPattern/?ID_PATTERN=" + id, { headers: this.apiAuthHeader });
  }

  addImportedPattern(json) {
    this.apiAuthHeader["Content-Type"] = 'application/json';
    return Axios.post(this.url + "api/PatternService/ImportPattern", json, { headers: this.apiAuthHeader })
  }

  getIdSystemu() {
    return Axios.get(this.url + "api/PatternService/GetIdSystemu", { headers: this.apiAuthHeader });
  }
}

export default PatternService;