import React, { useCallback, useEffect, useState } from 'react';
import { Box, Card, Typography } from '@mui/material';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ToolbarKalendarz from '../modules/ToolbarKalendarz';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../redux/actionCreators';
import Spinner from '../components/Spinner';
import CalendarPopover from '../components/CalendarPopover';
import BackArrow from '../components/BackArrow';

require('moment/locale/pl.js');
const localizer = momentLocalizer(moment);

function Kalendarz() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [events, setEvents] = useState([]);
    const [popUpEvent, setPopUpEvent] = useState();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [rok, setRok] = useState(new Date().getFullYear().toString());
    const [allPersons, setAllPersons] = useState([]);
    const [kodRodzajuNieobecnosci, setKodRodzajuNieobecnosci] = useState([]);
    const rodzajeNieobecnosci = useSelector((state) => state.rodzajeNieobecnosci);
    const listaPracownikow = useSelector((state) => state.listaPracownikow);
    const dispatch = useDispatch();
    const { addRodzajeNieobecnosci, addListaPracownikow } = bindActionCreators(actionCreators, dispatch);
    var allData = []
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [selectedPerson, setSelectedPerson] = useState(null);

    useEffect(() => {
        if (listaPracownikow) {
            getRecurPersons(listaPracownikow[0]);
        }
    }, [listaPracownikow])

    useEffect(() => {
        getData();
    }, []);

    const fillEvents = (nieobecnosci) => {
        let eventsData = []
        let title = ''
        let dataOd = null
        let dataDo = null
        let allDay = true
        if (nieobecnosci.length > 0) {
            nieobecnosci.map((nieobecnosc, index) => {
                if (nieobecnosc.typ === '0') {
                    title = 'Dzień roboczy'
                    allDay = false
                    dataOd = new Date(nieobecnosc.dataOd)
                    dataDo = new Date(nieobecnosc.dataDo)
                }
                else {
                    title = nieobecnosc.nazwa
                    dataOd = new Date(nieobecnosc.dataOd)
                    dataDo = new Date(nieobecnosc.dataDo)
                    dataDo.setHours(dataDo.getHours() + 1)
                }
                return eventsData.push({ title: title, start: dataOd, end: dataDo, allDay: allDay, typ: nieobecnosc.typ })
            })
        }
        setEvents(eventsData)
    };


    const setOnNavigate = useCallback((newDate) => {
        setSelectedDate(newDate)
    }, [])

    const handleSelectedEvent = (data, event) => {
        setPopUpEvent(data);
        setAnchorEl(event.currentTarget);
    }

    const getRecurPersons = (person) => {
        if (person.osoby) {
            allData.push(...person.osoby)
        }
        if (person.children) {
            person.children.forEach(child => getRecurPersons(child))
        }
        setAllPersons(allData)
    };

    const getData = () => {
        if (rodzajeNieobecnosci === null) {
            addRodzajeNieobecnosci();
        }
        if (listaPracownikow === null) {
            addListaPracownikow();
        }

    }

    return (
        <>
            <Box mt={2} mb={2} display={'flex'} alignItems={'center'}><BackArrow /><Typography variant="h1">Kalendarz Nieobecności</Typography></Box>
            {rodzajeNieobecnosci && listaPracownikow ?
                <Card elevation={2}>
                    <Calendar
                        localizer={localizer}
                        style={{ height: "65vh", margin: '15px' }}
                        components={{
                            toolbar: props => (<ToolbarKalendarz {...props}
                                rodzajeNieobecnosci={rodzajeNieobecnosci} listaPracownikow={allPersons} kodRodzajuNieobecnosci={kodRodzajuNieobecnosci}
                                setKodRodzajuNieobecnosci={setKodRodzajuNieobecnosci} rok={rok} setRok={setRok} setEvents={setEvents} events={events} selectedDate={selectedDate} setSelectedDate={setSelectedDate} fillEvents={fillEvents} selectedPerson={selectedPerson} setSelectedPerson={setSelectedPerson} />)
                        }}
                        culture='pl-PL'
                        events={events || ''}
                        step={60}
                        onShowMore
                        messages={{ showMore: (target) => <Typography variant='h4' fontWeight={'bold'}> ...{target} więcej</Typography> }}
                        popup
                        showMultiDayTimes
                        defaultDate={new Date()}
                        date={selectedDate}
                        onNavigate={setOnNavigate}
                        onSelectEvent={(data, e) => handleSelectedEvent(data, e)}
                        min={new Date()}
                        eventPropGetter={
                            (event, start, end, isSelected) => {
                                let newStyle = {};
                                if (isSelected) {
                                    newStyle.border = 'solid 3px #0d6582';
                                    newStyle.borderRadius = '4px';
                                }
                                if (event.typ === '3') {
                                    newStyle.backgroundColor = 'DarkOrange';
                                    newStyle.height = 'auto'
                                }
                                if (event.typ === '2') {
                                    newStyle.backgroundColor = 'ForestGreen';
                                    newStyle.height = 'auto'
                                }
                                if (event.typ === '1') {
                                    newStyle.backgroundColor = 'DeepSkyBlue';
                                    newStyle.height = 'auto'
                                }
                                return {
                                    className: '',
                                    style: newStyle
                                };
                            }
                        }
                    />
                </Card>
                : <Spinner />}
            <CalendarPopover id={id} open={open} anchorEl={anchorEl} setAnchorEl={setAnchorEl} handleSelectedEvent={handleSelectedEvent} popUpEvent={popUpEvent} />
        </>
    );
}

export default Kalendarz;