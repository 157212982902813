import React, { useEffect, useState, useRef } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Box, Checkbox, Paper } from '@mui/material';
import BackArrow from '../components/BackArrow';
import { apiService } from '../services/api.service';
import { Converter } from '../submodules/template/services/Converter';
import PaginationComponent from '../components/PaginationComponent';
import Spinner from '../components/Spinner';
import ModalZestawienia from '../modules/modals/ModalZestawienia';
import { useReactToPrint } from 'react-to-print';
import { withStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion as MuiAccordion } from '@mui/material';
import FiltersZestawieniaAccordion from '../modules/accordions/FiltersZestawieniaAccordion';

const headCells = [
    {
        id: 1,
        name: 'Pracownik'
    },
    {
        id: 2,
        name: 'Typ nieobecności'
    },
    {
        id: 3,
        name: 'Rozpoczęcie'
    },
    {
        id: 4,
        name: 'Zakończenie'
    },
    //  {
    //      id: 5,
    //      name: 'Ilość'
    //  },
]

// const TableAccordion = withStyles({
//     root: {
//         "&$expanded": {
//             margin: "auto"
//         },
//         border: 'none'
//     },
//     expanded: {}
// })(MuiAccordion);


const Zestawienia = () => {

    const monthNumber = new Date().getMonth() + 1;
    const [year, setYear] = useState(new Date().getFullYear().toString())
    const [month, setMonth] = useState(monthNumber.toString())
    const [openFilters, setOpenFilters] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const nieobecnosciRef = useRef();
    const isSelected = (name) => selected.indexOf(name) !== -1;

    useEffect(() => {
        if (!year || year.toString().length < 4 || year.toString().length > 4) {
            setData([])
        }
        else {
            apiService.getNieobecnosciWszystkichPracownikow({ year, month }).then(res => setData(res.data))

        }
    }, [year, month]);



    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.idNieobecnosci)
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handlePrint = useReactToPrint({
        content: () => nieobecnosciRef.current,
    });


    return (
        <>
            <Box
                mb={2}
                display={'flex'}
                alignItems={'center'}
            >< BackArrow />
                <Typography variant='h1'>Zestawienia nieobecności</Typography>
            </Box>
            <Paper>
                <FiltersZestawieniaAccordion
                    setOpenModal={setOpenModal}
                    open={openFilters}
                    setOpen={setOpenFilters}
                    nieobecnosciFiltered={filteredData}
                    setNieobecnosciFiltered={setFilteredData}
                    nieobecnosci={data}
                    setYear={setYear}
                    year={year}
                    month={month}
                    setMonth={setMonth}
                />
                <Table ref={nieobecnosciRef}>
                    <TableHead>
                        <TableRow >
                            <TableCell padding='checkbox'>
                                <Checkbox onChange={handleSelectAllClick}
                                    inputProps={{
                                        'aria-label': 'select all persons',
                                    }} />
                            </TableCell>
                            {headCells.map((item) => (
                                <TableCell
                                    sx={{ fontWeight: 'bold' }}
                                    key={item.id}
                                    checked={false}
                                >{item.name}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData ? filteredData
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                const isItemSelected = isSelected(row.idNieobecnosci);
                                let bgColor = '';
                                if (index % 2 === 0) {
                                    bgColor = 'neutral.50';
                                }
                                return (
                                    <TableRow sx={{
                                        backgroundColor: bgColor,
                                        cursor: 'pointer',
                                        '&:hover': { backgroundColor: 'primary.50' },
                                        'td, th': { border: "none" },
                                    }}
                                        key={index}
                                        onClick={(event) => handleClick(event, row.idNieobecnosci)}
                                        role='checkbox'
                                        selected={isItemSelected}
                                        aria-checked={isItemSelected}
                                    >

                                        <TableCell padding='checkbox' >
                                            <Checkbox inputProps={{
                                                'aria-labelledby': labelId,
                                            }} color='primary' checked={isItemSelected} />
                                        </TableCell>
                                        <TableCell
                                            component='th'
                                            id={index}
                                            scope='row'>{Converter.titleCase(row.nazwaPracownika)}</TableCell>
                                        <TableCell align='left'>{row.typ}</TableCell>
                                        <TableCell align='left'>{Converter.formatDateToDDMMYYYY(row.dataOd)}</TableCell>
                                        <TableCell align='left'>{Converter.formatDateToDDMMYYYY(row.dataDo)}</TableCell>
                                    </TableRow>
                                )
                            }) : <Spinner />}
                    </TableBody>
                </Table>
                {filteredData ? <PaginationComponent
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    data={filteredData} /> : ''}
            </Paper>

            <ModalZestawienia
                open={openModal}
                page={page}
                setOpen={setOpenModal}
                selected={selected}
                filteredData={filteredData}
                data={data}
                handlePrint={handlePrint}
            />
        </>
    );
}

export default Zestawienia;