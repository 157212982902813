import React, { useState } from 'react';
import { TextField, Button, Box , Stack, Typography, Link, InputAdornment, IconButton, Card, CardContent } from '@mui/material';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../loginRedux/actionCreators';
import '../layout/styles/login.css';
import { MailIcon, PasswordEye, LeftArrow, Wrong, LifeBuoy, Phone, Letter } from '../layout/styles/icons';
import axios from 'axios';
import apiurl from '../../../apiurl';
import LifeBuoyButton from '../../../../src/submodules/template/components/LoginLifeBuoy';
import '../layout/styles/login.css';
import LoginForm from './LoginForm';
import RecoverForm from './RecoverForm';



export default function Login() {
  const [isLoginForm, setLoginForm] = useState(true);
  const [emailForm, setEmailForm] = useState(true);

  const emailSend = () => setEmailForm(false);

  const closeForm = () => {
    setLoginForm(true);
    setEmailForm(true);
  }

  const [show, setShow] = useState(true);

  return (<>
    <Box className='mainBox'>

      <LifeBuoyButton />

        <div className='centerBox'>
          <div className='herbBox'>
          <img alt='herb' src={`${process.env.PUBLIC_URL + '/herb.png'}`}></img>
         </div>

            {isLoginForm ? <LoginForm /> : (<>
            {emailForm ? <RecoverForm emailSend={emailSend} setLoginForm={setLoginForm} /> : (<>
              <div className='formBox'>
                <Stack spacing={5}>
                  <Typography fontSize={28} fontWeight={700}>E-mail został wysłany</Typography>

                  <Typography fontSize={16}>Postępuj zgodnie z instrukcją w wiadomości E-mail.</Typography>

                  <Link fontSize={14} align='center' paddingLeft={'5px'} style={{ cursor: 'pointer' }} onClick={() => (closeForm())}>Zamknij okno</Link>
                </Stack></div></>)}
            </>)}



         {isLoginForm ? (<div className='forgetBox'>
            <Typography variant='h7'>Nie pamiętasz hasła? </Typography>
            <Link className='link' variant='h7' onClick={() => (setLoginForm(false))}> Zresetuj hasło</Link>
            </div>) : null}

          <div className='logoBox'>
            <img alt='logo' src={`${process.env.PUBLIC_URL + '/logo.png'}`}></img>
        </div>

        </div>
    </Box>

  </>);

}

