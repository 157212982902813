import { Collapse, IconButton, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { ReactComponent as ExpandIcon } from "../../assets/icons/Expand_up.svg";
import { ReactComponent as RollUpIcon } from "../../assets/icons/Roll_up.svg";
import { Converter } from "../../submodules/template/services/Converter";

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 30
  }
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "10px 16px 10px 16px"
  }
}))(TableCell);


export default function TableRowNadgodziny(props) {
  const [open, setOpen] = useState(false);
  const [bgColor, setBgColor] = useState("");
  const [font, setFont] = useState();

  useEffect(() => {
    if (props.isEven === false) {
      setBgColor('neutral.50');
    }
  }, [])

  const handleExpandRow = () => {
    setOpen(!open);
    if (!open) {
      setFont('bold')
    } else { setFont('normal') }
  }

  const newData = (props) => {
    const result = Array.from(props)[0];
    return result;
  }

  return (
    <TableBody sx={{
      backgroundColor: bgColor,
      "&:hover": { backgroundColor: "primary.50" },
      cursor: 'pointer',
      "& td": { border: 0 }
    }}>
      <StyledTableRow onClick={() => handleExpandRow()}>
        <StyledTableCell sx={{ fontWeight: font }}>
          {props.row.statusNazwa}
        </StyledTableCell>
        <StyledTableCell sx={{ fontWeight: font }} align="left">{Converter.formatDateToDDMMYYYY(props.row.data)}</StyledTableCell>
        <StyledTableCell sx={{ fontWeight: font }} align="center">{newData(props.row.razem)}</StyledTableCell>
        <StyledTableCell sx={{ fontWeight: font }} align="center">{newData(props.row.odebrano)}</StyledTableCell>
        <StyledTableCell sx={{ fontWeight: font }} align="center">{newData(props.row.doOdebrania)}</StyledTableCell>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleExpandRow()}
          >
            {open ? <RollUpIcon /> : <ExpandIcon />}
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
      <TableRow onClick={() => handleExpandRow()}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 2 }}>
              <Typography variant="h4" fontWeight={'600'} gutterBottom component="div">
                Opis: <Typography variant="h4" component="span">{props.row.opis === "" ? "-" : props.row.opis}</Typography>
              </Typography>
              {/* <Typography variant="h4" fontWeight={'600'} gutterBottom component="div">
                Do odebrania: <Typography variant="h4" component="span">{props.row.doOdebrania}</Typography>
              </Typography> */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}

