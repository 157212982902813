import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../styles/kontrolki.css'
import SelectedZlozonyWniosekCard from '../modules/cards/SelectedZlozonyWniosekCard';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../redux/actionCreators';
import Spinner from '../components/Spinner';
import WnioskiPracownikowTable from '../modules/tables/WnioskiPracownikowTable';
import PopupAlert from '../components/PopupAlert';
import { getIdPrac } from '../redux/idPracFetch';
import BackArrow from '../components/BackArrow';

const Status = ['Oczekuje', 'Odrzucony', 'Zaakceptowany', 'Zaplanowany', 'Zrealizowany', 'Anulowany', 'Edytowany'];

function WnioskiPracownikow() {
    const [succesPopup, setSuccessPopup] = useState(false);
    const [errorPopup, setErrorPopup] = useState(false);
    const [selectedWniosek, setSelectedWniosek] = useState("");
    const activeUmowa = getIdPrac();
    const wnioski = useSelector((state) => state.listaZlozonychWnioskow);
    const dispatch = useDispatch();
    const { addListaZlozonychWnioskow } = bindActionCreators(actionCreators, dispatch);
    const [wnioskiFiltered, setWnioskiFiltered] = useState(null);

    useEffect(() => {
        setWnioskiFiltered(wnioski)
    }, [wnioski])

    useEffect(() => {
        getDane();

    }, [wnioski]);

    const getDane = () => {
        if (wnioski == null) {
            addListaZlozonychWnioskow({ idPrac: activeUmowa });
        }
    }



    const refreshCurrentWniosek = () => {
        let idWniosek = selectedWniosek.idWniosek;
        let wniosek = wnioski.find(x => x.idWniosek === idWniosek)
        setSelectedWniosek(wniosek);
    }

    const getKontrolkaStatusu = (status) => {
        if (status === 0) {
            return 'circle_yellow';
        }
        else if (status === 1) {
            return 'circle_red'
        }
        else if (status === 2) {
            return 'circle_green'
        }
        else if (status === 3) {
            return 'circle_green'
        }
        else if (status === 4) {
            return 'circle_black';
        }
        else if (status === 5) {
            return 'circle_black';
        }
    }

    const handleRowSelect = (wniosek) => {
        setSelectedWniosek(wniosek);
    }


    return (
        <>
            <Box
                mb={1}
                display={'flex'}
                alignItems={'center'}>
                <BackArrow />
                <Typography variant="h1">Złożone wnioski</Typography>
            </Box>
            {wnioski ? <Grid container spacing={0} columnSpacing={4} rowSpacing={2}>
                <Grid item lg={8.5} width={"100%"} order={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}>
                    <WnioskiPracownikowTable
                        setSelectedWniosek={setSelectedWniosek}
                        selectedWniosek={selectedWniosek}
                        wnioski={wnioski}
                        wnioskiFiltered={wnioskiFiltered}
                        setWnioskiFiltered={setWnioskiFiltered}
                        Status={Status}
                        getKontrolkaStatusu={getKontrolkaStatusu}
                        handleRowSelect={handleRowSelect} />
                </Grid>
                <Grid item lg={3.5} order={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                    {selectedWniosek ? <SelectedZlozonyWniosekCard
                        refreshCurrentWniosek={() => refreshCurrentWniosek()}
                        nazwaStatusu={Status} activeUmowa={activeUmowa} /*urlop={urlop}*/
                        selectedWniosek={selectedWniosek}
                        getKontrolkaStatusu={getKontrolkaStatusu}
                        errorPopup={errorPopup}
                        setErrorPopup={setErrorPopup}
                        succesPopup={succesPopup}
                        setSuccessPopup={setSuccessPopup} />
                        : ""}
                </Grid>
            </Grid> : <Spinner />}
            <PopupAlert
                severity="success"
                text="Status wniosku został zmieniony"
                open={succesPopup}
                setOpen={setSuccessPopup} />
            <PopupAlert
                severity="error"
                text="Nie udało się anulować wniosku"
                open={errorPopup}
                setOpen={setErrorPopup} />
        </>
    );
}

export default WnioskiPracownikow;