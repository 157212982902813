import React, { Component } from 'react';
import './patternPreviewer.css';

class PatternPreviewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            base64EncodedPDF: 'localStorage.getItem("pdf")',
            fullDocument: ''
        }
    }

    async componentDidMount() {
        await this.printDocument();

    }


    printDocument() {

        this.setState({
            fullDocument: "<iframe width='100%' height='100%' src='data:application/pdf;base64, "+encodeURI(this.state.base64EncodedPDF) + "'></iframe>"
        })
    }

    render() {
        return (

                <div dangerouslySetInnerHTML={{ __html: this.state.fullDocument}} style={{height: '100VH'}}>
                
                </div>


        )
    }
}

export default PatternPreviewer;