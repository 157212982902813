import { apiService } from "./api.service";

export const cacheService = { getFotoPracownika, clearCache }

async function getFotoPracownika() {
    let foto = "";
    if ('caches' in window) {    //sprawdzenie dla starszych przeglądarek
        await caches.open('foto').then(async cache => {
            let response = await cache.match(apiService.getFotoPracownikaRequest());
            if (response) {    //sprawdzenie czy ten cache ma jakiekolwiek dane
                let image = await response.text();
                foto = image;
            }
            else {   //jesli ten cache nie ma danych to trzeba go utworzyć
                await cache.add(apiService.getFotoPracownikaRequest());
                let res = await cache.match(apiService.getFotoPracownikaRequest());
                let image = await res.text();
                foto = image;
            }

        });
    }
    return foto;
};

function clearCache() {
    caches.keys().then(function (names) {
        for (let name of names)
            caches.delete(name);
    });
};
