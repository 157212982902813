import React, { useState } from 'react';
import axios from 'axios';
import apiurl from '../../../apiurl';
import { Button, InputAdornment, Link, Paper, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CustomTextField } from '../layout/styles/CustomTextField';
import { LeftArrow, MailIcon } from '../layout/styles/icons';
import '../layout/styles/login.css';

const RecoverForm = ({ emailSend, setLoginForm }) => {
    const [email, setEmail] = useState();

    function sendEmail(mail) {
        return axios.post(apiurl + "api/PwdRecovery",
            { email: mail },
            {
                headers: { 'Content-Type': 'application/json' },
                method: 'POST'
            })
            .then(res => {
                alert("Link został pomyślnie wysłany na adres: " + mail);
                emailSend()
            })
            .catch(error => {
                console.log(error.response.data)
                emailSend()
            });
    }

    return (
        <Paper sx={{ maxWidth: '400px' }} elevation={2}>
            <form >
                <Box display={'flex'} flexDirection={'column'} m={4}>
                    <Stack spacing={3}>
                        <Typography fontSize={28} fontWeight={700}>Spokojnie...</Typography>
                        <Typography fontSize={16}>Podaj adres email na który chcesz otrzymać link do zresetowania hasła.</Typography>
                        <CustomTextField id="email" label="Adres E-mail" variant="outlined" value={email || ''} onChange={(e) => setEmail(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <MailIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {email ? (<Button variant="contained" color="primary" sx={{ padding: '15px' }} onClick={() => (sendEmail(email))}>Wyślij</Button>) : (<Button variant="contained" color="primary" sx={{ padding: '15px' }} disabled>Wyślij</Button>)}
                        <Box className='backButton'>
                            <LeftArrow /><Link fontSize={12} color="#000000" paddingLeft={'5px'} sx={{ cursor: 'pointer' }} onClick={() => (setLoginForm(true))}><Typography>Wróć</Typography> </Link>
                        </Box>
                    </Stack>
                </Box>
            </form>
        </Paper>
    );
};

export default RecoverForm;