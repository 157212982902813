import { Button, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../redux/actionCreators';
import { apiService } from '../../services/api.service'
import '../../styles/changeWniosekStatusButton.css'

function ChangeWniosekStatusButton({ isLoading, setIsLoading, refreshCurrentWniosek, activeUmowa, idWniosek, status, setSuccessPopup, setErrorPopup }) {

    const wnioski = useSelector((state) => state.listaZlozonychWnioskow);
    const dispatch = useDispatch();
    const { addListaZlozonychWnioskow } = bindActionCreators(actionCreators, dispatch);

    useEffect(() => {
        refreshCurrentWniosek();
    }, [wnioski])

    const setButtons = () => {
        if (status === 0) {
            return <>
                <Grid item xs={12} >
                    <Button style={{ textTransform: 'capitalize' }} className='changeWniosekStatusButton' fullWidth variant="contained" size="large" onClick={() => changeStatus(2)}>Zaakceptuj</Button>
                </Grid>
                {/* <Grid item xs={12} >
                    <Button className='changeWniosekStatusButton' fullWidth variant="contained" size="large" onClick={() => changeStatus(3)}>Zaplanuj</Button>
                </Grid> */}
                <Grid item xs={12} >
                    <Button style={{ textTransform: 'capitalize' }} className='changeWniosekStatusButton' fullWidth variant="outlined" size="large" onClick={() => changeStatus(1)}>Odrzuć</Button>
                </Grid >
            </>
        }
        else if (status === 1) {
            return <>
                <Grid item xs={12}>
                    <Button style={{ textTransform: 'capitalize' }} className='changeWniosekStatusButton' fullWidth variant="contained" size="large" onClick={() => changeStatus(2)}>Zaakceptuj</Button>
                </Grid>
                {/* <Grid item xs={12}>
                    <Button className='changeWniosekStatusButton' fullWidth variant="contained" size="large" onClick={() => changeStatus(3)}>Zaplanuj</Button>
                </Grid> */}
                <Grid item xs={12} >
                    <Button style={{ textTransform: 'capitalize' }} className='changeWniosekStatusButton' disabled fullWidth variant="outlined" size="large">Odrzuć</Button>
                </Grid >
            </>
        }
        else if (status === 2) {
            return <>
                {/* <Grid item xs={12}>
                    <Button style={{ textTransform: 'capitalize' }} className='changeWniosekStatusButton' disabled fullWidth variant="contained" size="large" >Zaakceptuj</Button>
                </Grid> */}
                <Grid item xs={12}>
                    <Button className='changeWniosekStatusButton' style={{ textTransform: 'capitalize' }} fullWidth variant="contained" size="large" onClick={() => changeStatus(3)}>Zaplanuj</Button>
                </Grid>
                <Grid item xs={12}>
                    <Button style={{ textTransform: 'capitalize' }} className='changeWniosekStatusButton' fullWidth variant="outlined" size="large" onClick={() => changeStatus(1)}>Odrzuć</Button>
                </Grid>
            </>
        }
        else if (status === 3) {
            return <>
                {/* <Grid item xs={12}>
                    <Button style={{ textTransform: 'capitalize' }} className='changeWniosekStatusButton' fullWidth variant="contained" size="large" onClick={() => changeStatus(2)}>Zaakceptuj</Button>
                </Grid> */}
                <Grid item xs={12} >
                    <Button className='changeWniosekStatusButton' style={{ textTransform: 'capitalize' }} disabled fullWidth variant="contained" size="large">Zaplanuj</Button>
                </Grid>
                <Grid item xs={12}>
                    <Button style={{ textTransform: 'capitalize' }} className='changeWniosekStatusButton' fullWidth variant="outlined" size="large" onClick={() => changeStatus(1)}>Odrzuć</Button>
                </Grid>
            </>
        }
    }

    const changeStatus = (nowyStatus) => {
        setIsLoading(true)
        let wniosek = { idWniosek: idWniosek, status: nowyStatus, idPrac: activeUmowa }    //Przyczyna DO USTALENIA; TO DO
        apiService.changeWniosekStatus(wniosek).then(res => {
            addListaZlozonychWnioskow({ idPrac: activeUmowa });
            setSuccessPopup(true);
        }, err => {
            console.log(err);
            setErrorPopup(true);
        }).finally(() => {
            setIsLoading(false)
        })
    }

    return (
        <>
            {setButtons()}
        </>
    )
}

export default ChangeWniosekStatusButton;