import { Box, Grid, Typography } from '@mui/material';
import pulpitData from '../config/pulpitData';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CustomPulpitButton } from '../components/buttons/PulpitButton';
import { actionCreators } from '../redux/actionCreators';
import { Converter } from '../submodules/template/services/Converter';
import { useNavigate } from 'react-router-dom';
import PulpitBoxy from '../components/PulpitBoxy.js';
import BackArrow from '../components/BackArrow';

function PodsumowanieNowe() {
    const danePracownika = useSelector((state) => state.danePracownika);
    const umowyPracownika = useSelector((state) => state.danePrac);

    const dispatch = useDispatch();
    const { addDanePracownika, addDanePrac, updateActiveUmowa } = bindActionCreators(actionCreators, dispatch);
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('ekadry2_idPrac')) {
            updateActiveUmowa(localStorage.getItem('ekadry2_idPrac'));
        }
        if (umowyPracownika) {
            updateActiveUmowa(umowyPracownika[0].idPrac);
        }
        getDane();
    }, []);

    const getDane = () => {
        if (danePracownika === null) {
            addDanePracownika();
            addDanePrac();
        }
    };

    return (
        <>
            <Typography mb={2} variant="h1">Witaj z powrotem, {danePracownika && Converter.onlyFirstLetterCapital(danePracownika.imie)}!</Typography>
            <Grid
                sx={{ minHeight: '100%' }}
                container
                direction="column"
                justifyContent="space-between"
                rowSpacing={2}
            >
                <Grid item container direction="row">
                    {pulpitData[0].buttonList.map((item, index) => (
                        <CustomPulpitButton key={index} onClick={() => navigate(item.url)}>
                            <PulpitBoxy icon={item.icon} name={item.name}></PulpitBoxy>
                        </CustomPulpitButton>
                    ))
                    }
                </Grid>
                <Grid item container direction="row">
                    {pulpitData[1].buttonList.map((item, index) => (
                        <CustomPulpitButton key={index} onClick={() => navigate(item.url)}>
                            <PulpitBoxy icon={item.icon} name={item.name}></PulpitBoxy>
                        </CustomPulpitButton>
                    ))
                    }
                </Grid>
                <Grid item container direction="row">
                    {pulpitData[2].buttonList.map((item, index) => (
                        <CustomPulpitButton key={index} onClick={() => navigate(item.url)}>
                            <PulpitBoxy icon={item.icon} name={item.name}></PulpitBoxy>
                        </CustomPulpitButton>
                    ))
                    }
                </Grid>
            </Grid>
        </>
    );
}

export default PodsumowanieNowe;