import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { actionCreators } from '../../submodules/template/loginRedux/actionCreators';
import { store } from '../../redux/mainStore';
import { cacheService } from '../../services/cache.service';
import { errorData } from './errorData';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
    const [dataError, setDataError] = useState();
    let navigate = useNavigate();

    useEffect(() => {
        let segment_str = window.location.pathname;
        let segment_array = segment_str.split('/').filter(e => String(e).trim());
        if (segment_array[1] === 'forbiddenPath') {
            let error = errorData.filter(item => item.idError === 403);
            setDataError(error[0])
        } else if (segment_array[1] === 'internalServerError') {
            let error = errorData.filter(item => item.idError === 500);
            setDataError(error[0])
        } else if (segment_array[1] === 'pageNotFound') {
            let error = errorData.filter(item => item.idError === 404);
            setDataError(error[0])
        }
        else if (segment_array[1] === "noServerConnection") {
            let error = errorData.filter(item => item.idError === "Offline");
            setDataError(error[0])
        }

        //TO DO dopisac obslugeresezty bledow gdy bedzie api
    }, []);

    const handleCentrumPomocy = () => {
        let tokenLogin = JSON.parse(localStorage.getItem(localStorage.getItem('appPrefix') + 'LoginResponse')).token
        if (!tokenLogin) {
            cacheService.clearCache();
            store.dispatch(actionCreators.removeToken());
        } else {
            navigate('/profil/centrum-pomocy', { replace: true })
        }
    }


    return (
        <>
            {dataError ?
                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} >
                    <Box mb={2}>
                        {dataError.errorIcon}
                    </Box>
                    < Typography mb={1} variant='h1' > {dataError.errorHeader}</Typography >
                    <Box maxWidth={'500px'}>
                        <Typography textAlign={'center'} variant='h4'>{dataError.errorContent}</Typography>
                    </Box>
                    <Box minWidth={'450px'} mt={4} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                        <Button sx={{ textTransform: 'capitalize' }} onClick={() => {
                            cacheService.clearCache();
                            store.dispatch(actionCreators.removeToken());
                        }} variant='contained'>Powrót do strony głównej</Button>
                        <Button sx={{ textTransform: 'capitalize' }} onClick={handleCentrumPomocy} variant='text'>Odwiedź Centrum Pomocy</Button>
                    </Box>
                </Box >
                : null}
        </>
    );


}

export default ErrorPage;