import { ReactComponent as Timer } from "../assets/icons/Timer.svg";
import { ReactComponent as NowyWniosek } from "../assets/icons/NowyWniosek.svg";
import { ReactComponent as MojeWnioski } from "../assets/icons/MojeWnioski.svg";
import { ReactComponent as DostepnyUrlop } from "../assets/icons/DostepnyUrlop.svg";
import { ReactComponent as MojeDane } from "../assets/icons/MojeDane.svg";
import { ReactComponent as CallSplit } from "../assets/icons/CallSplit.svg";
import { ReactComponent as Callendar } from "../assets/icons/Callendar.svg";
import { ReactComponent as Settings } from "../assets/icons/Settings.svg";
import { ReactComponent as KreatorDokumentow } from "../assets/icons/KreatorDokumentow.svg";
import '../styles/pulpitIkony.css'


const pulpitData = [
    {
        "buttonList": [
            { "id": 1, "name": "Nowy wniosek", "icon": <NowyWniosek className='pulpitIconBig' />, "url": "/wnioski/nowy-wniosek" },
            { "id": 6, "name": "Kreator dokumentów", "icon": <KreatorDokumentow className='pulpitIkona' />, "url": "/wzorce/lista" },
            { "id": 3, "name": "Złożone wnioski", "icon": <MojeWnioski className='pulpitIkona' />, "url": "/wnioski/moje-wnioski" },
            { "id": 2, "name": "Nadgodziny", "icon": <Timer className='pulpitIkona' />, "url": "/wnioski/nadgodziny" },
            { "id": 4, "name": "Dostępny urlop", "icon": <DostepnyUrlop className='pulpitIkona' />, "url": "/wnioski/dostepny-urlop" },
        ]
    },
    {
        "buttonList": [
            { "id": 1, "name": "Nieobecności", "icon": <Callendar className='pulpitIkona' />, "url": "/kalendarz/kalendarz-nieobecnosci" },
        ]
    },
    {
        "buttonList": [
            { "id": 1, "name": "Moje dane", "icon": <MojeDane className='pulpitIkona' />, "url": "/twoje-konto/twoj-profil" },
            { "id": 3, "name": "Struktura firmy", "icon": <CallSplit className='pulpitIconSmall' />, "url": "/twoje-konto/struktura-firmy" },
            { "id": 4, "name": "Ustawienia", "icon": <Settings className='pulpitIkona' />, "url": "/twoje-konto/ustawienia" },
        ]
    },
]

export default pulpitData;
