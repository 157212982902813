import React from 'react';
import { Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function FiltrSzczegolywnioski(props) {
    return (
        <Grid container direction="row" justifyContent="space-between" style={{ backgroundColor: "#EBECEE", borderRadius: 4, cursor: "pointer" }}>
            <Grid item>
                <Typography
                    whiteSpace={"nowrap"}
                    sx={{ py: 0.5, px: 1 }}
                    variant='h6'
                >{props.text}<strong>{props.info}</strong></Typography>
            </Grid>
            <Grid item>
                <CloseIcon onClick={props.onClick} sx={{ width: "12px", height: "12px", color: "#6A6F7B" }} />
            </Grid>
        </Grid>
    );
}
export default FiltrSzczegolywnioski;