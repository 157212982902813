import { TextField } from "@mui/material";
import { Box, style } from "@mui/system";
import React, { useState } from "react";
import { ReactComponent as SearchIcon } from '../assets/Search_loupe.svg';
import ArraySearcher from "../services/arraySearcher";

function SearchTextField({ array, setFilteredArray }) { //szukajka wyrazów wewnątrz tablicy

    const [inputValue, setInputValue] = useState("");
    const onInputChange = (value) => {
        setInputValue(value)

        let filteredArray = []
        filteredArray = ArraySearcher(array, value)

        //setArray(tempArray);
        setFilteredArray(filteredArray);
    }



    return (
        <>
            <TextField sx={{ '.MuiFormLabel-root[data-shrink=false]': { top: "-5px" } }} fullWidth id="outlined-basic" label="Szukaj" variant="outlined" onChange={(e) => onInputChange(e.target.value)} value={inputValue}
                InputProps={{
                    style: { height: "44px" }, endAdornment: <SearchIcon style={{ width: "25px", height: "25px" }} />,
                }}
            />
        </>
    )
}

export default SearchTextField;