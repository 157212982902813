import React from 'react';
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { withStyles } from '@mui/styles';

const Accordion = withStyles({
    root: {
        "&$expanded": {
            margin: "auto"
        }
    },
    expanded: {}
})(MuiAccordion);

export default function PaskiPlacoweAccordion({ handleChangeHideDetails, expanded }) {
    const panel = 'panel2td';
    return (
        <>
            <Accordion expanded={expanded === panel} onChange={handleChangeHideDetails(panel)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${panel}-content`}
                    id={`${panel}-header`}
                >
                    <Typography
                        pt={1}
                        pb={1}
                        pl={2}
                        variant='h3'
                        fontWeight={'bold'}>Paski płacowe</Typography>
                </AccordionSummary>
                <AccordionDetails>
                </AccordionDetails>
            </Accordion>
        </>
    )
}