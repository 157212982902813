import { Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppLogo from '../components/AppLogo';
//import { ReactComponent as AppLogo } from '../assets/AppLogo.svg';
import { ReactComponent as Hex } from './images/hexagon.svg';
import './styles/subAside.css'

function SubAside(props) {
    const [currentAsideElement, setCurrentAsideElement] = useState('');
    // const [activeSubAsideId, setActiveSubAsideId] = useState(0);
    let navigate = useNavigate();
    const checkAuth = JSON.parse(localStorage.getItem(localStorage.getItem('appPrefix') + 'LoginResponse'));



    useEffect(() => {
        if (props.activeAsideId != 0) {
            setCurrentElements(props.activeAsideId);
        }
    });

    const setCurrentElements = (id) => {
        setCurrentAsideElement(props.asideData.find(x => x.id === id));
    };

    const handleClick = (id, url) => {
        props.setActiveSubAsideId(id)
        navigate(url);
    };
    return (
        <Box display={'flex'} flexDirection={'column'} justifyItems={'center'} className='subasidecontent'>
            <Box >
                <AppLogo />
            </Box>
            <Box width={'100%'} ml={5} mt={3} mb={'2vh'}>
                <Typography variant="h1">
                    {currentAsideElement.name}
                </Typography>
            </Box >
            <Box flexGrow={5} sx={{ minWidth: '85%' }}>
                <Typography component={'span'} >
                    <List sx={{ minWidth: '90%' }}>
                        {currentAsideElement ? currentAsideElement.subaside.map((option) => {
                            return (
                                <>
                                    {(checkAuth.isZatwierdzanieWnioskow === 1 || checkAuth.isZestawieniaNieobecnosciPrac) ?
                                        <Box mb={1}>
                                            {option.divide ? <Divider textAlign='left'><Typography variant='h6'>{option.name}</Typography></Divider> : null}
                                            {!option.divide && <ListItem className='listItemSubAside' button selected={props.activeSubAsideId === option.id} key={option.name} onClick={() => handleClick(option.id, option.url)} >
                                                <ListItemIcon style={{ minWidth: "20px", marginRight: '10px' }} >{option.icon}</ListItemIcon>
                                                <ListItemText style={{ whiteSpace: 'nowrap' }} primary={option.name} />
                                            </ListItem>}
                                        </Box>
                                        :
                                        <Box mb={1} >
                                            <ListItem className='listItemSubAside' button selected={props.activeSubAsideId === option.id} key={option.name} onClick={() => handleClick(option.id, option.url)} >
                                                <ListItemIcon style={{ minWidth: "20px", marginRight: '10px' }} >{option.icon}</ListItemIcon>
                                                <ListItemText style={{ whiteSpace: 'nowrap' }} primary={option.name} />
                                            </ListItem>
                                        </Box>
                                    }
                                </>

                            )
                        }) : ""}
                    </List>
                </Typography>
            </Box>
            <Box>
                <Hex />

            </Box>
        </Box >
    );
}

export default SubAside;