import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import NadgodzinyTable from '../modules/tables/NadgodzinyTable';
import Spinner from '../components/Spinner';
import { actionCreators } from '../redux/actionCreators';
import { getIdPrac } from '../redux/idPracFetch';
import BackArrow from '../components/BackArrow';


function Nadgodziny() {
    const nadgodziny = useSelector((state) => state.listaNadgodzin);
    const dispatch = useDispatch();
    const { addListaNadgodzin } = bindActionCreators(actionCreators, dispatch);
    const activeUmowa = getIdPrac();

    useEffect(() => {
        getDane();
    }, []);

    const getDane = () => {
        if (nadgodziny == null) {
            addListaNadgodzin({ idPrac: activeUmowa });
        }
    }


    return (
        <>
            <Box mt={2} mb={2} display={'flex'} alignItems={'center'}><BackArrow /><Typography variant="h1">Nadgodziny</Typography></Box>
            {nadgodziny ?
                <>
                    <Grid container spacing={0} columnSpacing={3} rowSpacing={2}>
                        <Grid item lg={12} width={"100%"} order={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}>
                            <NadgodzinyTable nadgodziny={nadgodziny} />
                        </Grid>
                    </Grid>
                </>
                : <Spinner />}
        </>
    );
}

export default Nadgodziny;