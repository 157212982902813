import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, Divider, ListItemButton, ListItemText, Typography } from '@mui/material';
import React, { useState } from 'react';
import { apiService } from '../services/api.service';
import { Converter } from '../submodules/template/services/Converter';


function StruktListItem({ person }) {
    const [details, setDetails] = useState('');
    const [open, setOpen] = useState(false);

    async function handleDetails(id) {
        setOpen(!open);
        await apiService.getStrukturaSzczegolowe(id).then(response => setDetails(response.data)).catch(err => console.log(err));
    }

    return (
        <>
            <ListItemButton
                disableGutters
            >
                <ListItemText sx={{ cursor: 'pointer', paddingLeft: "7px" }} primary={`${person.imie} ${person.nazwisko}`} onClick={() => handleDetails(person.idPrac)} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse
                in={open}
                unmountOnExit
                timeout="auto"
            >
                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-around'} >
                    <Box flexGrow={1} m={1}>
                        <Typography variant='h3' fontWeight={'bold'}>{person.imie} {person.nazwisko}</Typography>
                    </Box>
                    <Box flexGrow={1} m={1}>
                        <Typography variant='h4'><Box fontWeight='bold' display='inline'>Umowa: </Box >{details.umowa}</Typography>
                        <Typography variant='h4'><Box fontWeight='bold' display='inline'>Stanowisko: </Box >{details.stanowisko}</Typography>
                        <Typography variant='h4'><Box fontWeight='bold' display='inline'>Referat: </Box >{details.referat}</Typography>
                        <Typography variant='h4'><Box fontWeight='bold' display='inline'>Miejsce zatrudnienia: </Box >{details.miejsceZatrudnienia}</Typography>
                    </Box>
                    <Box flexGrow={1} m={1}>
                        <Typography variant='h4'><Box fontWeight='bold' display='inline'>Zatrudniono: </Box >{Converter.formatDateToDDMMYYYY(details.dataOdZatrudnienia)}</Typography>
                        <Typography variant='h4'><Box fontWeight='bold' display='inline'>Telefon: </Box >{details.telefon}</Typography>
                        <Typography variant='h4'><Box fontWeight='bold' display='inline'>Email: </Box >{details.email}</Typography>
                    </Box>
                </Box>
            </Collapse>
            <Divider />
        </>
    );
}

export default StruktListItem;