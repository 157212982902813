import React, { useEffect } from 'react';
import { Box, Typography, Card, CardMedia, CardContent, CardActions, Link } from '@mui/material';
import { ReactComponent as Home } from "../assets/icons/Home.svg";
import { ReactComponent as Map } from "../assets/icons/Map.svg";
import { ReactComponent as Phone } from "../assets/icons/Phone.svg";
import { ReactComponent as Fax } from "../assets/icons/Fax.svg";
import { ReactComponent as Letter } from "../assets/icons/Letter.svg";
import { ReactComponent as PC } from "../assets/icons/PC.svg";
import oProgramie from "../assets/oProgramie.png";
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../redux/actionCreators';
import packagejson from '../../package.json'
import BackArrow from '../components/BackArrow';


export default function InteractiveList() {

    const wersjaAPI = useSelector((state) => state.ApiVersion);
    const dispatch = useDispatch();
    const { addApiVersion } = bindActionCreators(actionCreators, dispatch);

    useEffect(() => {
        getDane();
    }, []);

    const getDane = () => {
        if (wersjaAPI == null) {
            addApiVersion();
        }
    }

    return (
        <>
            <Box mb={2} display={'flex'} alignItems={'center'}><BackArrow /><Typography variant="h1" >O programie</Typography></Box>
            <Card sx={{ minWidth: 287, maxWidth: 287, minHeight: 554, maxHeight: 554 }}>
                <CardMedia component="img" height="140" image={oProgramie} />
                <CardContent>
                    <Typography sx={{ ml: 1, pb: 1, fontWeight: 'bold' }} variant="h2">eKadry</Typography>
                    <Typography sx={{ ml: 1 }} variant="h4" color="neutral.600">Wersja: {packagejson.version} </Typography>
                    <Typography sx={{ ml: 1, pb: 1 }} variant="h4" color="neutral.600">API: {wersjaAPI}</Typography>

                    <Box sx={{ display: 'flex', mt: 1, ml: 1.5, mb: 1 }}>
                        <Box sx={{ display: 'inline-flex', mt: 0.5, mr: 2.5 }}>
                            <Home />
                        </Box>
                        <Box sx={{ display: 'inline-flex' }}>
                            <Typography color="neutral.600" variant="h4">INFO-SYSTEM Roman<br /> i Tadeusz Groszek sp.j</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mt: 1, ml: 1.5, mb: 1.5 }}>
                        <Box sx={{ display: 'inline-flex', mt: 0.5, mr: 2.5 }}>
                            <Map />
                        </Box>
                        <Box sx={{ display: 'inline-flex' }}>
                            <Link
                                href="https://www.google.com/maps/place/Us%C5%82ugi+Informatyczne+INFO-SYSTEM/@52.397086,20.934657,15z/data=!4m5!3m4!1s0x0:0xd3e0de7cd7c74dd!8m2!3d52.3970856!4d20.9346573?hl=pl-PL"
                                style={{ cursor: 'pointer' }}
                                target="_blank"
                                rel="noopener noreferrer"
                                underline="none"
                                variant="h4"
                                color="neutral.600">
                                05-119 Legionowo <br /> ul.Piłsudskiego 31 lok. 240
                            </Link>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mt: 1, ml: 1.5, mb: 1.5 }}>
                        <Box sx={{ display: 'inline-flex', mt: 0.5, mr: 2.5 }}>
                            <Phone />
                        </Box>
                        <Box sx={{ display: 'inline-flex', flexDirection: 'column' }}>
                            <Link
                                href="tel:22 784-49-44"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ cursor: 'pointer' }}
                                underline="none"
                                variant="h4"
                                color="neutral.600">
                                22 784-49-44
                            </Link>
                            <Link
                                href="tel:22 732-01-49"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ cursor: 'pointer' }}
                                underline="none"
                                variant="h4"
                                color="neutral.600">
                                22 732-01-49
                            </Link>
                            <Link
                                href="tel:22 732-01-50"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ cursor: 'pointer' }}
                                underline="none"
                                variant="h4"
                                color="neutral.600">
                                22 732-01-50
                            </Link>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mt: 1, ml: 1.5, mb: 1.5 }}>
                        <Box sx={{ display: 'inline-flex', mt: 0.5, mr: 2.5 }}>
                            <Fax />
                        </Box>
                        <Box sx={{ display: 'inline-flex' }}>
                            <Typography color="neutral.600" variant="h4">22 784 49 88</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mt: 1, ml: 1.5, mb: 1.5 }}>
                        <Box sx={{ display: 'inline-flex', mt: 0.5, mr: 2.5 }}>
                            <Letter />
                        </Box>
                        <Box sx={{ display: 'inline-flex' }}>
                            <Link
                                href="mailto:poczta@groszek.pl"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ cursor: 'pointer' }}
                                underline="none"
                                variant="h4"
                                color="neutral.600">
                                poczta@groszek.pl
                            </Link>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mt: 1, ml: 1.5, mb: 1.5 }}>
                        <Box sx={{ display: 'inline-flex', mt: 0.5, mr: 2.5 }}>
                            <PC />
                        </Box>
                        <Box sx={{ display: 'inline-flex' }}>
                            <Link
                                href="https://www.groszek.pl/"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ cursor: 'pointer' }}
                                underline="none"
                                variant="h4"
                                color="neutral.600">
                                www.groszek.pl
                            </Link>
                        </Box>
                    </Box>
                </CardContent>
                <CardActions sx={{ ml: 2.5 }}>
                    <Link
                        href="https://www.groszek.pl/"
                        target="_blank"
                        rel="noopener noreferrer"
                        underline="none"
                        variant="h5"
                        color="primary.500"
                        sx={{ fontWeight: '600' }}>
                        Idź do strony
                    </Link>
                </CardActions>
            </Card>
        </>
    );
}