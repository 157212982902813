import React, { useState } from 'react';
import { Box, Typography, Link, IconButton, Card, CardContent } from '@mui/material';
import '../../../submodules/template/layout/styles/login.css';
import { MailIcon, PasswordEye, LeftArrow, Wrong, LifeBuoy, Phone, Letter } from '../../../submodules/template/layout/styles/icons';



function LifeBuoyButton(){

    const [show, setShow] = useState(false);

    return (<>
          <Box className='loginHeader'>
            <Box className='loginHeaderButton'>
                <IconButton onClick={()=>setShow(!show)}><LifeBuoy /></IconButton>
            </Box>
    
            {show ? 
    
            <Box className='LifeBuoyClick'>
    
            <Card sx={{ minWidth: 260, maxWidth: 260, minHeight: 273, maxHeight: 273 }}>
        
            <CardContent>
            <Typography sx={{ ml: 1, pb: 1, fontWeight: 'bold' }} variant="h2">Centrum Pomocy</Typography>
    
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1, ml: 1.5, mb: 1 }}>
                <Box sx={{ display: 'inline-flex', mt: 0.5, mr: 2.5 }}>
                  
                </Box>
                <Box sx={{ justifyContent: 'center', mt: 1, ml: 1.5, mb: 1.5 }}>
                    <Typography color="neutral.600" variant="h4"> <Phone /> Zadzwoń do nas i zgłoś <br /> problem</Typography>
                </Box>
    
                        <Box sx={{ display: 'flex', mt: 1, ml: 1.5, mb: 1.5 }}>
                            <Box sx={{ display: 'inline-flex', mt: 0.5, mr: 2.5 }}>
                            </Box>
                            <Box sx={{ display: 'inline-flex', flexDirection: 'column' }}>
                                <Link
                                    href="tel:22 784-49-44"
                                    style={{ cursor: 'pointer' }}
                                    underline="none"
                                    variant="h4"
                                    color="#3879F6">
                                    22 784-49-44
                                </Link>
                          
                            </Box>
                        </Box>
    
                            <Box sx={{ justifyContent: 'center', mt: 1, ml: 1.5, mb: 1.5  }}>
                            <Typography color="neutral.600" variant="h4"><Letter /> Napisz email i wygodnie <br /> zgłoś usterkę</Typography>
                            </Box>
    
                            <Box sx={{ display: 'flex', mt: 1, ml: 1.5, mb: 1.5 }}>
                            <Box sx={{ display: 'inline-flex', mt: 0.5, mr: 2.5 }}>
                            </Box>
                            <Box sx={{ display: 'inline-flex' }}>
                                <Link
                                    href="mailto:poczta@groszek.pl"
                                    style={{ cursor: 'pointer' }}
                                    underline="none"
                                    variant="h4"
                                    color="#3879F6">
                                    poczta@groszek.pl
                                </Link>
                            </Box>
                        </Box>
    
                        </Box>
    
                    </CardContent>
                </Card>
    
    
            </Box>
    
              : null}
    
          </Box>
    
    
      </>
      );
    }

    export default LifeBuoyButton;