import React from 'react';
import { Button, IconButton, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import './modal.css';
import { HexWithQuestionMark } from '../../../../styles/icons';


function ActionModal({ isShowed, handleCloseModal, description, bottomButton, topButton, onClickButton }) {


    return (
        <Modal open={isShowed} onClose={handleCloseModal}>
            <Box className='modal' display={'flex'} flexDirection={'column'} >
                <Box display={'flex'} justifyContent={'flex-end'}>
                    <Box><IconButton onClick={handleCloseModal}><CloseIcon /></IconButton></Box>
                </Box>
                <Box display={'flex'} justifyContent={'center'}>
                    <HexWithQuestionMark />
                </Box>
                <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} alignItems={'center'}>
                    <Box m={3}>
                        <Typography variant='h3' >{description}</Typography>
                    </Box>
                    <Box width={'50%'} display={'flex'} justifyContent={'space-around'} flexDirection={'column'}>
                        <Button sx={{ textTransform: 'none', marginBottom: '10px' }} size='large' onClick={onClickButton} variant='contained'>{topButton}</Button>
                        <Button sx={{ textTransform: 'none' }} size='large' onClick={handleCloseModal} variant='outlined'>{bottomButton}</Button>

                    </Box>
                </Box>
            </Box>
        </Modal>
        // <Modal className='includeBootstrap removeModalBackground' show={props.isShowed} onHide={props.handleCloseModal}>
        //     <Modal.Header closeButton>
        //         <Modal.Title>{props.title} </Modal.Title>
        //     </Modal.Header>
        //     <Modal.Body style={{ color: "red" }}>{props.description}</Modal.Body>
        //     <Modal.Footer style={{ display: "inline" }}>
        //         <div >
        //             <div style={{ display: "flex", justifyContent: "space-between" }}>
        //                 <Button variant="secondary" onClick={props.handleCloseModal}>
        //                     {props.leftButton}
        //                 </Button>

        //                 <Button variant="danger" onClick={props.onClickButton}>
        //                     {props.rightButton}
        //                 </Button>


        //             </div>

        //         </div>



        //     </Modal.Footer>
        // </Modal>
    )
}

export default ActionModal;