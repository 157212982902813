import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import PaginationComponent from "../../components/PaginationComponent";
import FiltersWnioskiAccordion from "../accordions/FiltersWnioskiAccordion";
import NaglowekTablecell from "./NaglowekTablecell";
import { Converter } from "../../submodules/template/services/Converter";
import moment from "moment";
import { StatusyWnioskow } from "../../services/statusyWnioskow";

function WnioskiPracownikowTable({ wnioski, wnioskiFiltered, setWnioskiFiltered, setSelectedWniosek, selectedWniosek, Status, getKontrolkaStatusu, handleRowSelect }) {
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [heightRow, setHeightRow] = useState(`52px`);

    useEffect(() => {
        setPage(0);
    }, [wnioskiFiltered])
    useEffect(() => {
        setSelectedWniosek(wnioski[0]);
    }, [])

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <FiltersWnioskiAccordion
                setOpen={setOpen}
                open={open}
                setWnioskiFiltered={setWnioskiFiltered}
                wnioski={wnioski}
                wnioskiFiltered={wnioskiFiltered}
                setHeightRow={setHeightRow} />
            <TableContainer>
                <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                        <TableRow sx={{ height: "52px" }}>
                            <NaglowekTablecell text="Osoba" />
                            <NaglowekTablecell text="Zgłoszone" />
                            <NaglowekTablecell text="Rodzaj urlopu" />
                            <NaglowekTablecell text="Przebieg akceptacji" />
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        {wnioskiFiltered ? wnioskiFiltered
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                let bgColor = "";
                                let font;
                                if (selectedWniosek.idWniosek === row.idWniosek) {  //gdy wniosek jest wybrany to jest niebieski
                                    bgColor = 'primary.50';
                                    font = 'bold';
                                }
                                else if (index % 2) {   //gdy wniosek jest parzysty to jest szary
                                    bgColor = 'neutral.50';
                                }
                                return (
                                    <TableRow

                                        sx={{
                                            height: `${heightRow}`,
                                            cursor: 'pointer',
                                            backgroundColor: bgColor,
                                            "&:hover": { backgroundColor: "primary.50" },
                                        }}  /*selected={selectedWniosek.idWniosek === row.idWniosek}  hover */
                                        onClick={() => handleRowSelect(row)}
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.idWniosek}>
                                        <TableCell sx={{ fontWeight: font }}>{Converter.titleCase(row.imie)} {Converter.titleCase(row.nazwisko)}</TableCell>
                                        <TableCell sx={{ fontWeight: font }}>{moment(row.dataWn).format('DD.MM.YYYY')}</TableCell>
                                        <TableCell sx={{ fontWeight: font }}>{row.nazwaRodzajuNieobecnosci}</TableCell>
                                        <TableCell sx={{ fontWeight: font }}>
                                            <Grid container alignItems={'center'} sx={{ ml: -2.5 }}>
                                                <Grid item xs={3}>
                                                    <div id={getKontrolkaStatusu(row.status)}></div>
                                                </Grid>
                                                <Grid sx={{ fontWeight: font }} item xs={9}>
                                                    {StatusyWnioskow.getTextFromInt(row.status)}
                                                </Grid>
                                            </Grid></TableCell>

                                    </TableRow>
                                );
                            }) : ''}
                    </TableBody>
                </Table>
            </TableContainer>
            {wnioskiFiltered ? <PaginationComponent
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                data={wnioskiFiltered} /> : ""}
        </Paper>

    )

}

export default WnioskiPracownikowTable;