import React from 'react';
import { Card } from '@mui/material';
import TwojeDokumentyAccordion from '../accordions/TwojeDokumentyAccordion';
import PaskiPlacoweAccordion from '../accordions/PaskiPlacoweAccordion';

function TwojeDokumentyCard({ pity }) {

    const [expanded, setExpanded] = React.useState(false);

    const handleChangeHideDetails = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);

    };

    return (
        <>
            <Card sx={{ minWidth: '100%' }}>
                <TwojeDokumentyAccordion
                    pity={pity}
                    handleChangeHideDetails={handleChangeHideDetails}
                    expanded={expanded} />
                <PaskiPlacoweAccordion
                    handleChangeHideDetails={handleChangeHideDetails}
                    expanded={expanded} />
            </Card>
        </>

    );
}

export default TwojeDokumentyCard;


