import React, { useState } from 'react';
import { AccordionDetails, AccordionSummary, Card, Stack, Typography, Table, TableContainer, TableBody, TableRow, TableCell } from '@mui/material';
import '../../styles/kontrolki.css';
import { Accordion as MuiAccordion } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/system';
import { Converter } from '../../submodules/template/services/Converter';
import { withStyles } from '@mui/styles';
import moment from 'moment';

const Light = (props) => {
    return <div id={props.light}></div>
}

const Accordion = withStyles({
    root: {
        '&$expanded': {
            margin: 'auto'
        }
    },
    expanded: {}
})(MuiAccordion);

function TwojeBadaniaCard({ badania }) {
    const rowStyle = { borderBottomColor: 'neutral.100' }

    const chceckValidDate = (dateExamination, validExamination) => {
        const date1 = new Date(dateExamination);
        const date2 = new Date(validExamination);
        const subtractDates = date2.getTime() - date1.getTime();
        if (Math.floor(subtractDates / (1000 * 60 * 60 * 24)) > 7) {
            return 'circle_green'
        } if (Math.floor(subtractDates / (1000 * 60 * 60 * 24)) <= 0) {
            return 'circle_red'
        } if (Math.floor(subtractDates / (1000 * 60 * 60 * 24)) < 7) {
            return 'circle_yellow'
        }
    }
    const [expanded, setExpanded] = React.useState(false);

    const handleChangeHideDetails = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);

    };
    return (
        <Card elevation={2}>
            <Stack mt={3} ml={4} mb={2}>
                <Typography variant='h3' fontWeight={'bold'}>Twoje badania</Typography>
            </Stack>
            {badania && badania.map((item, index) => (
                <Accordion key={index} elevation={0} expanded={expanded === index} onChange={handleChangeHideDetails(index)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}a-content`}
                        id={`panel${index}a-header`}>
                        <Box pt={1} pb={1} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <Box>
                                <Light light={chceckValidDate(item.data, item.dataNastepnego)} />
                            </Box>
                            <Typography ml={2} variant='h4'>{Converter.onlyFirstLetterCapital(item.rodzaj)}</Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box mx={2}>
                            <TableContainer>
                                <Table size='small' style={{ width: '100%' }}>
                                    <TableBody>
                                        <TableRow sx={{ 'td, th': rowStyle }}>
                                            <TableCell><Box ml={-2}>Opis badania</ Box></TableCell>
                                            <TableCell>{(item.opis) ? (item.opis) : '-'}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ 'td, th': rowStyle }}>
                                            <TableCell><Box ml={-2}>Data badania</ Box></TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap' }}>{`${moment(item.data).format('DD/MM/YYYY')}`}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                            <TableCell><Box ml={-2}>Następne badanie</ Box></TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap' }}>{(item.dataNastepnego) ? (`${moment(item.dataNastepnego).format('DD/MM/YYYY')}`) : '-'}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        {/* <Box ml={2} display={'flex'} flexDirection={'column'} alignItems={'left'}>
                            <Typography variant='caption3'>Rodzaj badania: {item.rodzaj}</Typography>
                            <Typography variant='caption3'>Data wykonania badania: {Converter.formatDateToDDMMYYYY(item.data)}</Typography>
                            <Typography variant='caption3'>Data następnego badania: {Converter.formatDateToDDMMYYYY(item.dataNastepnego)}</Typography>
                        </Box> */}
                    </AccordionDetails>
                </Accordion>

            ))}

        </Card>
    );
}

export default TwojeBadaniaCard;