import { Button, AccordionDetails, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Badge } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StyledNoBorderAccordion, StyledAccordionSummary } from "../../components/CustomAccordion";
import { DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { actionCreators } from '../../redux/actionCreators';
import { apiService } from '../../services/api.service';
import FilterListIcon from '@mui/icons-material/FilterList';
import { pl } from 'date-fns/locale';
import ArraySearcher from '../../submodules/template/services/arraySearcher';
import { ReactComponent as SearchIcon } from '../../submodules/template/assets/Search_loupe.svg';
import { useTheme } from '@mui/styles';
import InfoWnioskiFilters from '../../components/InfoWnioskiFilters';
import TableStyleButtonsWnioski from './TableStyleButtonsWnioski';

const FiltersWnioskiAccordion = ({ open, setOpen, wnioski, setWnioskiFiltered, wnioskiFiltered, setHeightRow }) => {
    const theme = useTheme();

    const [statusWnios, setStatusWnios] = useState('');
    const [kodRodzajuNieobecnosci, setKodRodzajuNieobecnosci] = useState('');
    const [rodzajeStatusow, setRodzajeStatusow] = useState();
    const [dataOd, setDataOd] = useState(null);
    const [dataDo, setDataDo] = useState(null);
    const rodzajeNieobecnosci = useSelector((state) => state.rodzajeNieobecnosci);
    const dispatch = useDispatch();
    const { addRodzajeNieobecnosci } = bindActionCreators(actionCreators, dispatch);
    const [inputValue, setInputValue] = useState("");
    const [invisible, setInvisible] = React.useState(false);
    const [count, setCount] = useState(0);
    const [nazwaUrlopu, setNazwaUrlopu] = useState('');

    useEffect(() => {
        if (rodzajeNieobecnosci === null) {
            addRodzajeNieobecnosci();
        }
        apiService.getStatusy().then(res => setRodzajeStatusow(res.data))
    }, [])

    const handleChangeStatus = (event) => {
        setCount(count + 1);
        let status = '';
        let filteredArray = []
        if (event === '') {
            setStatusWnios('')
            status = ''
            filteredArray = wnioski;
        }
        else {
            setStatusWnios(event.target.value)
            status = event.target.value
            filteredArray = filterByStatus(wnioski, status);
        }
        filteredArray = filterByRodzaj(filteredArray, kodRodzajuNieobecnosci)
        filteredArray = filterByDataOd(filteredArray, dataOd)
        filteredArray = filterByDataDo(filteredArray, dataDo)
        filteredArray = filterByText(filteredArray, inputValue)
        setWnioskiFiltered(filteredArray)
    }

    const handleChangeRodzaj = (event) => {
        setCount(count + 1);
        let rodzaj = '';
        let filteredArray = []
        if (event === '') {
            setKodRodzajuNieobecnosci('')
            rodzaj = ''
            filteredArray = wnioski;
        }
        else {
            setKodRodzajuNieobecnosci(event.target.value)
            rodzaj = event.target.value
            filteredArray = filterByRodzaj(wnioski, rodzaj);
        }
        filteredArray = filterByStatus(filteredArray, statusWnios)
        filteredArray = filterByDataOd(filteredArray, dataOd)
        filteredArray = filterByDataDo(filteredArray, dataDo)
        filteredArray = filterByText(filteredArray, inputValue)
        setWnioskiFiltered(filteredArray)
    }

    const handleDataOd = (date) => {
        setCount(count + 1);
        let filteredArray = [];
        let dateValue = '';
        if (date) {
            filteredArray = filterByDataOd(wnioski, date);
            filteredArray = filterByStatus(filteredArray, statusWnios)
            filteredArray = filterByRodzaj(filteredArray, kodRodzajuNieobecnosci)
            filteredArray = filterByDataDo(filteredArray, dataDo)
            filteredArray = filterByText(filteredArray, inputValue)
            setDataOd(date);
            setWnioskiFiltered(filteredArray)
        }
    }

    const handleDataDo = (date) => {
        setCount(count + 1);
        let filteredArray = [];
        let dateValue = '';
        if (date) {
            filteredArray = filterByDataDo(wnioski, date);
            filteredArray = filterByStatus(filteredArray, statusWnios)
            filteredArray = filterByRodzaj(filteredArray, kodRodzajuNieobecnosci)
            filteredArray = filterByDataOd(filteredArray, dataOd)
            filteredArray = filterByText(filteredArray, inputValue)
            setDataDo(date);
            setWnioskiFiltered(filteredArray)
        }

    }
    const handleInputChange = (value) => {
        setInputValue(value)
        let filteredArray = []
        filteredArray = filterByText(wnioski, value)
        filteredArray = filterByDataDo(filteredArray, dataDo);
        filteredArray = filterByStatus(filteredArray, statusWnios)
        filteredArray = filterByRodzaj(filteredArray, kodRodzajuNieobecnosci)
        filteredArray = filterByDataOd(filteredArray, dataOd)
        setWnioskiFiltered(filteredArray);
    }

    const filterByStatus = (wnioski, status) => {
        let newArray = [];
        if (status || status === 0) {
            newArray = wnioski.filter((item, index) => {
                return item.status == status
            })
            return newArray;
        }
        else {
            return wnioski;
        }
    }
    const filterByRodzaj = (wnioski, rodzaj) => {
        let newArray = [];
        if (rodzaj) {
            newArray = wnioski.filter((item, index) => {
                return item.kodRodzajuNieobecnosci == rodzaj
            })
            return newArray;
        }
        else {
            return wnioski;
        }
    }

    const filterByDataOd = (wnioski, date) => {
        let newArray = []
        if (date) {
            let dateValue = new Date(new Date(date).setHours(0, 0, 0, 0)).getTime()
            newArray = wnioski.filter((item, index) => {
                let dateWniosku = new Date(new Date(item.dataOd).setHours(0, 0, 0, 0)).getTime();
                return dateWniosku >= dateValue
            })
            return newArray;
        }
        else {
            return wnioski;
        }
    }

    const filterByDataDo = (wnioski, date) => {
        let newArray = []

        if (date) {
            let dateValue = new Date(new Date(date).setHours(0, 0, 0, 0)).getTime()
            newArray = wnioski.filter((item, index) => {
                let dateWniosku = new Date(new Date(item.dataDo).setHours(0, 0, 0, 0)).getTime();
                return dateWniosku <= dateValue
            })
            return newArray;
        }
        else {
            return wnioski
        }
    }

    const filterByText = (array, input) => {
        let filteredArray = []
        filteredArray = ArraySearcher(array, input)
        return filteredArray;
    }

    const cancelFilters = () => {
        setCount(0)
        setStatusWnios('');
        setKodRodzajuNieobecnosci('');
        setDataOd(null);
        setDataDo(null);
        setWnioskiFiltered(wnioski);
    }

    const CloseFilterDataOd = () => {
        setCount(count - 1)
        setDataOd(null);
        let filteredArray = [];
        filteredArray = filterByDataDo(wnioski, dataDo);
        filteredArray = filterByStatus(filteredArray, statusWnios);
        filteredArray = filterByRodzaj(filteredArray, kodRodzajuNieobecnosci);
        setWnioskiFiltered(filteredArray);
    }
    const CloseFilterDataDo = () => {
        setCount(count - 1)
        setDataDo(null);
        let filteredArray = [];
        filteredArray = filterByDataOd(wnioski, dataOd);
        filteredArray = filterByStatus(filteredArray, statusWnios);
        filteredArray = filterByRodzaj(filteredArray, kodRodzajuNieobecnosci);
        setWnioskiFiltered(filteredArray);

    }
    const CloseFilterStatus = () => {
        setCount(count - 1);
        setStatusWnios('');
        let filteredArray = [];
        filteredArray = filterByDataOd(wnioski, dataOd);
        filteredArray = filterByDataDo(filteredArray, dataDo);
        filteredArray = filterByRodzaj(filteredArray, kodRodzajuNieobecnosci);
        setWnioskiFiltered(filteredArray);
    }
    const CloseFilterRodzaj = () => {
        setCount(count - 1)
        setKodRodzajuNieobecnosci('');
        let filteredArray = [];
        filteredArray = filterByDataOd(wnioski, dataOd);
        filteredArray = filterByDataDo(filteredArray, dataDo);
        filteredArray = filterByStatus(filteredArray, statusWnios);
        setWnioskiFiltered(filteredArray);
    }

    const handleBadgeVisibility = () => {
        setOpen(!open)
        setInvisible(!invisible);
    };

    const handlersDetailsFilters = {
        dataOd, dataDo, kodRodzajuNieobecnosci, statusWnios, CloseFilterDataDo,
        CloseFilterDataOd, CloseFilterRodzaj, CloseFilterStatus, cancelFilters, open, nazwaUrlopu
    };

    return (
        <>
            <StyledNoBorderAccordion expanded={open}>
                <Grid container direction={'row'} pt={4} pb={1} alignItems="center" columnSpacing={2} pl={4} pr={2}>
                    <Grid item xs={10}>
                        <TextField sx={{ '.MuiFormLabel-root[data-shrink=false]': { top: "-5px" } }} fullWidth id="outlined-basic"
                            label="Szukaj" variant="outlined" onChange={(e) => handleInputChange(e.target.value)} value={inputValue}
                            InputProps={{
                                style: { height: "44px" }, endAdornment: <SearchIcon style={{ width: "25px", height: "25px" }} />,
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Badge badgeContent={count} invisible={invisible} color="primary" sx={{ width: '95%' }}>
                            <Button fullWidth startIcon={<FilterListIcon />}
                                style={{ textTransform: 'capitalize', height: "44px" }}
                                sx={open ? { backgroundColor: theme.palette.primary["50"] } : {}} variant="text" onClick={handleBadgeVisibility}>Filtruj</Button>
                        </Badge>
                    </Grid>
                </Grid>
                <StyledAccordionSummary>
                </StyledAccordionSummary>
                <AccordionDetails>
                    <Grid container direction={'row'} alignItems="center" spacing={2} pl={2}>
                        <LocalizationProvider locale={pl} dateAdapter={AdapterDateFns}>
                            <Grid item xs={3}>
                                <DatePicker
                                    label="Data od"
                                    value={dataOd}
                                    inputFormat="dd/MM/yyyy"
                                    mask={"__/__/____"}
                                    onChange={(newValue) => {
                                        handleDataOd(newValue)
                                    }}
                                    renderInput={(params) => <TextField size='small' sx={{
                                        width: '100%',
                                        '.MuiSvgIcon-root': {
                                            filter:
                                                'invert(77%) sepia(4%) saturate(3357%) hue-rotate(190deg) brightness(85%) contrast(67%)',
                                        }
                                    }} {...params} />}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <DatePicker
                                    label="Data do"
                                    value={dataDo}
                                    inputFormat="dd/MM/yyyy"
                                    mask={"__/__/____"}
                                    onChange={(newValue) => {
                                        handleDataDo(newValue)
                                    }}
                                    renderInput={(params) => <TextField size='small' sx={{
                                        width: '100%',
                                        '.MuiSvgIcon-root': {
                                            filter:
                                                'invert(77%) sepia(4%) saturate(3357%) hue-rotate(190deg) brightness(85%) contrast(67%)',
                                        }
                                    }} {...params} />}
                                /></Grid>
                        </LocalizationProvider>
                        <Grid item xs={3} >
                            <FormControl size="small" sx={{ width: '100%' }}>
                                <InputLabel id="rodzaj-select-label">Rodzaj</InputLabel>
                                <Select
                                    label={"Rodzaj urlopu"}
                                    labelId="kodRodzajuNieobecnosci"
                                    id="kodRodzajuNieobecnosciSelect"
                                    name="kodRodzajuNieobecnosci"
                                    value={kodRodzajuNieobecnosci || ''}
                                    onChange={(e) => handleChangeRodzaj(e)}
                                >
                                    {rodzajeNieobecnosci && rodzajeNieobecnosci.map((item) => {
                                        return (
                                            <MenuItem onClick={() => setNazwaUrlopu(item.nazwa)}
                                                sx={{ margin: '5px', borderRadius: '5px' }}
                                                key={item.kod} value={item.kod}>{item.nazwa}</MenuItem>
                                        )
                                    })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl size="small" sx={{ width: '100%' }}>
                                <InputLabel id="status-select-label">Status</InputLabel>
                                <Select
                                    labelId="status-select-label"
                                    id="status-select"
                                    value={statusWnios}
                                    label="Status"
                                    onChange={(e) => handleChangeStatus(e)}
                                >
                                    {rodzajeStatusow && rodzajeStatusow.map((item) => {
                                        return (
                                            <MenuItem sx={{ margin: '5px', borderRadius: '5px' }}
                                                key={item.statusId}
                                                value={item.statusId}>{item.nazwaStatusu}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </StyledNoBorderAccordion>
            <InfoWnioskiFilters handlersDetailsFilters={handlersDetailsFilters} />
            <TableStyleButtonsWnioski setHeightRow={setHeightRow} />

        </>
    );
}

export default FiltersWnioskiAccordion;