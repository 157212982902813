export class Pattern {

    constructor(name, typ_Wzorca, description, id_Systemu, patternDocument, isportrait, marginTop, marginBottom, marginLeft, marginRight, isEndOfDocumentVisible){
        this.Name = name;
        this.Typ_Wzorca = typ_Wzorca;
        this.Description = description;
        this.Id_Systemu = id_Systemu;
        this.PatternDocument = patternDocument;
        this.isPortrait = isportrait;
        this.MarginTop = marginTop;
        this.MarginBottom = marginBottom;
        this.MarginLeft = marginLeft;
        this.MarginRight = marginRight;
        this.isEndOfDocumentVisible = isEndOfDocumentVisible;
    }
}