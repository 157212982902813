import React, { Component } from 'react';
import PatternService from './../services/pattern-service/patternService'
import PrinterButton from './../common/printerButton';
import ExportPatternButton from '../common/exportPatternButton';
import ImportPatternButton from '../common/importPatternButton';
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import SpinnerWithoutText from '../../../components/SpinnerWithoutText';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeIcon from '@mui/icons-material/Mode';
import SearchTextField from '../../template/components/SearchTextField';
import DeleteModal from '../common/modals/DeleteModal';
import { withStyles } from '@mui/styles';

const CustomIconButton = withStyles({
    root: {
        borderRadius: 4,

        "&:hover, &.Mui-focusVisible": { color: '#3879F6' }
    }
})(props => <IconButton {...props} />);

const columns = [
    {
        id: 1,
        name: 'ID',
    },
    {
        id: 2,
        name: 'Nazwa',
    },
    {
        id: 3,
        name: 'System',
    },
    {
        id: 4,
        name: 'Typ',
    },
    {
        id: 5,
        name: 'Opis',
    },
    {
        id: 6,
        name: 'Akcja',
    },
]


class PatternListPrinter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showButtons: -1,
            patterns: [],
            isLoading: true,
            permission: '',
            showModal: false,
            currentid_Pattern: 0,
            currentPatternName: '',
            currentPatternid_Systemu: 0,
            isImportShown: false,
        }
        this.handleDeleteModal = this.handleDeleteModal.bind(this);
        this.onAddButton = this.onAddButton.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.getAllPatternsByIdSystemu = this.getAllPatternsByIdSystemu.bind(this);
        this.showImport = this.showImport.bind(this);
        this.handleShowButtons = this.handleShowButtons.bind(this);
    }
    async componentDidMount() {

        await PatternService.getInstance(this.props.apiurl, this.props.apiAuthHeader).getRola().then(res => {
            if (res.data.dostepneRole.includes(1)) {
                this.setState({ permission: true })
            }
            else {
                this.setState({ permission: false })
            }

        })

        this.getAllPatternsByIdSystemu();
    }

    getAllPatternsByIdSystemu() {
        PatternService.getInstance(this.props.apiurl, this.props.apiAuthHeader).getAllPatternsByIdSystemu().then(response => {
            this.setState({ patterns: response.data })
            this.setState({ isLoading: false })
        });
    }

    onAddButton() {

        //this.props.history.push(url);
        this.props.navigation("/wzorce/lista/patternCreator")
        //window.location.replace('/wzorce');
    }

    handleShowModal(id, id_Systemu, name) {
        this.setState({
            showModal: true,
            currentid_Pattern: id,
            currentPatternName: name,
            currentPatternid_Systemu: id_Systemu
        });
    }
    handleCloseModal() {
        this.setState({ showModal: false })
    }
    async handleDeleteModal() {
        await PatternService.getInstance(this.props.apiurl, this.props.apiAuthHeader).deletePattern(this.state.currentid_Pattern);
        this.setState({ showModal: false });
        this.getAllPatternsByIdSystemu();
        /*var patternsCopy = this.state.patterns;
        for (var i = 0; i < this.state.patterns.length; i++)
            if (patternsCopy[i].id_Pattern === this.state.currentid_Pattern) {
                patternsCopy.splice(i, 1);
                this.setState({ patterns: patternsCopy })
                break;
            }*/
    }

    handleShowButtons(id) {
        this.setState({ showButtons: id })
    }

    showImport() {
        this.setState({ isImportShown: true })
    }
    render() {
        const { patterns } = this.state;

        return (
            <>

                <Paper>
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                        <Box m={3} minWidth={'600px'}>

                            <SearchTextField array={patterns} />
                        </Box>

                        <Box m={3}>
                            {(this.state.isImportShown === false)
                                ?
                                <Button startIcon={<DownloadIcon />} variant={'text'} sx={{ textTransform: 'none' }} onClick={this.showImport}>Importuj</Button>
                                :
                                <ImportPatternButton apiurl={this.props.apiurl} apiAuthHeader={this.props.apiAuthHeader} getAllPatternsByIdSystemu={this.getAllPatternsByIdSystemu}></ImportPatternButton>
                            }
                            <Button onClick={this.onAddButton} variant={'contained'} sx={{ textTransform: 'none', marginLeft: '10px' }}>Dodaj Wzorzec</Button>
                        </Box>



                    </Box>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns?.map((column) => (
                                        <TableCell
                                            size='small'
                                            key={column.id}
                                            align={column.align}
                                        >
                                            <Typography variant="h4" fontWeight={'bold'}>{column.name}</Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                {!this.state.isLoading ? patterns.map((pattern, index) => {
                                    let bgColor = "";
                                    // let font;
                                    // if (selectedPatten.idWniosek === pattern.id_Pattern) {  //gdy wniosek jest wybrany to jest niebieski
                                    //     bgColor = 'primary.50';
                                    //     font = 'bold';
                                    // }
                                    if (index % 2) {   //gdy wniosek jest parzysty to jest szary
                                        bgColor = 'neutral.50';
                                    }
                                    return (
                                        <TableRow key={index}
                                            onMouseEnter={() => this.handleShowButtons(pattern.id_Pattern)}
                                            onMouseLeave={() => this.handleShowButtons(-1)}
                                            sx={{
                                                cursor: 'pointer',
                                                backgroundColor: bgColor,
                                                "&:hover": { backgroundColor: "primary.50" },
                                            }}>
                                            <TableCell sx={{ marginLeft: '5px' }}>{pattern.id_Pattern}</TableCell>
                                            <TableCell sx={{ wordWrap: "break-word", maxWidth: "100px" }}>{pattern.name}</TableCell>
                                            <TableCell sx={{ marginLeft: '5px' }}>{pattern.id_Systemu}</TableCell>
                                            <TableCell sx={{ marginLeft: '5px' }}>{pattern.typ_Wzorca}</TableCell>
                                            <TableCell sx={{ wordWrap: "break-word", maxWidth: "150px" }}>{pattern.description}</TableCell>
                                            <TableCell sx={{ minWidth: '120px' }}>{pattern.id_Pattern === this.state.showButtons ?
                                                <Box sx={{ float: 'left' }}>
                                                    <CustomIconButton sx={{ color: 'secondaryColors.500' }} size='small'><ExportPatternButton apiurl={this.props.apiurl} apiAuthHeader={this.props.apiAuthHeader} patternId={pattern.id_Pattern}></ExportPatternButton></CustomIconButton>
                                                    <CustomIconButton sx={{ color: 'secondaryColors.500' }} size='small'><PrinterButton apiurl={this.props.apiurl} printParams={this.props.printParams} apiAuthHeader={this.props.apiAuthHeader} patternId={pattern.id_Pattern}></PrinterButton></CustomIconButton>
                                                    {this.state.permission === true ? <CustomIconButton onClick={() => this.props.navigation("/wzorce/lista/patternEditor/" + pattern.id_Pattern)} sx={{ color: 'secondaryColors.500' }} size='small'><ModeIcon titleAccess='Edytuj' fontSize="inherit" /></CustomIconButton> : null}
                                                    {this.state.permission === true ? <CustomIconButton onClick={() => this.handleShowModal(pattern.id_Pattern, pattern.id_Systemu, pattern.name)} sx={{ color: 'secondaryColors.500' }} size='small'><DeleteIcon titleAccess='Usuń' fontSize="inherit" /></CustomIconButton> : null}
                                                </Box>
                                                : null}
                                            </TableCell>

                                        </TableRow>
                                    )
                                }
                                ) :
                                    <Box justifyContent={'center'} >
                                        <SpinnerWithoutText />
                                    </Box>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                {/* <div className="d-flex-column">

                    <div>
                        {(this.state.permission == true) ?

                            <div className="d-flex justify-content-between" style={{ marginBottom: '10px', minHeight: "50px" }}>
                                <Button onClick={this.onAddButton}>Dodaj Wzorzec</Button>
                                {(this.state.isImportShown == false)
                                    ?
                                    <Button onClick={this.showImport}>Importuj</Button>
                                    :
                                    <ImportPatternButton apiurl={this.props.apiurl} apiAuthHeader={this.props.apiAuthHeader} getAllPatternsByIdSystemu={this.getAllPatternsByIdSystemu}></ImportPatternButton>
                                }

                            </div>


                            : <div></div>}

                    </div>
                    <div className="table-responsive-xl card">
                        <table className="table table-hover" style={{ marginBottom: "0px" }}>
                            <colgroup>
                                <col span="1" style={{ width: "7%" }} />
                                <col span="1" style={{ width: "17%" }} />
                                <col span="1" style={{ width: "7%" }} />
                                <col span="1" style={{ width: "7%" }} />
                                <col span="1" style={{ width: "30%" }} />
                                <col span="1" />
                                <col span="1" />
                                {(this.state.permission == true) ? <col span="1" /> : ""}
                                {(this.state.permission == true) ? <col span="1" /> : ""}
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Nazwa</th>
                                    <th>System</th>
                                    <th>Typ</th>
                                    <th>Opis</th>
                                    <th></th>
                                    <th></th>
                                    {(this.state.permission == true) ? <th /> : ""}
                                    {(this.state.permission == true) ? <th /> : ""}
                                </tr>
                            </thead>
                            <tbody>
                                {(!this.state.isLoading) ? patterns.map((pattern, index) => {
                                    return (
                                        <tr className="patternListPrinterRow" key={pattern.id_Pattern} style={{ backgroundColor: index % 2 ? '#f5f5f6' : '' }}>
                                            <td>{pattern.id_Pattern}</td>
                                            <td style={{ wordWrap: "break-word", maxWidth: "100px" }}>{pattern.name}</td>
                                            <td>{pattern.id_Systemu}</td>
                                            <td>{pattern.typ_Wzorca}</td>
                                            <td className="col-md-1 ml-auto" style={{ wordWrap: "break-word", maxWidth: "150px" }}>{pattern.description}</td>
                                            <td><ExportPatternButton apiurl={this.props.apiurl} apiAuthHeader={this.props.apiAuthHeader} patternId={pattern.id_Pattern}></ExportPatternButton></td>
                                            <td><PrinterButton apiurl={this.props.apiurl} printParams={this.props.printParams} apiAuthHeader={this.props.apiAuthHeader} patternId={pattern.id_Pattern}></PrinterButton></td>
                                            {(this.state.permission == true) ? <td><Link to={"/wzorce/lista/patternEditor/" + pattern.id_Pattern}><button type="button" className="btn btn-primary">Edytuj</button></Link></td> : <div></div>}
                                            {(this.state.permission == true) ? <td><Button variant="danger" onClick={() => this.handleShowModal(pattern.id_Pattern, pattern.id_Systemu, pattern.name)}>
                                                Usuń
                                            </Button></td> : <div></div>}
                                        </tr>
                                    )
                                }) :
                                    <tr><td colSpan="8">
                                        {(this.state.isLoading) ? <div style={{ display: "flex", justifyContent: "center" }} >
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div> : ""}

                                    </td></tr>
                                }

                            </tbody>
                        </table>
                    </div>
                        </div> */}
                {/* <Modal className='includeBootstrap removeModalBackground' show={this.state.showModal} onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Czy na pewno chcesz usunąć wzorzec nr {this.state.currentid_Pattern} z ID Systemu {this.state.currentPatternid_Systemu} o nazwie "{this.state.currentPatternName}"? </Modal.Title>
                    </Modal.Header>
                    <Modal.Body><h5><p style={{ color: "red" }}><b>Tej akcji nie można cofnąć!</b></p></h5></Modal.Body>
                    <Modal.Footer style={{ display: "inline" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Button variant="secondary" onClick={this.handleCloseModal}>
                                Zamknij
                            </Button>

                            <Button variant="danger" onClick={this.handleDeleteModal}>
                                Usuń!
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal> */}
                <DeleteModal open={this.state.showModal} handleClose={this.handleCloseModal} currentid_Pattern={this.state.currentid_Pattern} currentPatternName={this.state.currentPatternName} currentPatternid_Systemu={this.state.currentPatternid_Systemu} handleDeleteModal={this.handleDeleteModal} />

            </>

        );
    }
}

export default PatternListPrinter;