import React, { useContext, useEffect, useState } from 'react';
import { TreeView } from '@mui/lab';
import { Autocomplete, Card, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box } from '@mui/system';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../redux/actionCreators';
import { useDispatch, useSelector } from 'react-redux';
import ExpandContext, { ExpandProvider } from '../services/expandContext';
import TreeRender from '../modules/TreeRenderStruktura';
import '../styles/struktura.css';
import Spinner from '../components/Spinner';
import BackArrow from '../components/BackArrow';

function StrukturaOrganizacyjna() {
  const dispatch = useDispatch();
  const state = useContext(ExpandContext)

  const listaPracownikow = useSelector((state) => state.listaPracownikow);    //listaPracownikow zawsze posiada całą strukture i jej nie ruszamy
  const { addListaPracownikow } = bindActionCreators(actionCreators, dispatch);
  const [data, setData] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [expandAccordion, setExpandAccordion] = useState(false);
  const [copyPerson, setCopyPerson] = useState({
    idKadr: 0,
    imie: '',
    nazwisko: '',
    idPrac: 0,
  });
  const [filteredListaPracownikow, setFilteredListaPracownikow] = useState([]);   //ta lista posiada początkowo całą strukturę, ale jest zmienna
  const [isFiltered, setIsFiltered] = useState(false);
  const [listaOsob, setListaOsob] = useState([]);

  const allData = []


  useEffect(() => {
    if (listaPracownikow === null) {
      addListaPracownikow();
    }
    if (listaPracownikow) {
      setData(listaPracownikow[0])
      getOsobyStruktura(listaPracownikow[0])
      setListaOsob(allData);
      setFilteredListaPracownikow(listaPracownikow)
    }
  }, [listaPracownikow])


  // rekurencyjne odczytanie i zapisanie osob ze struktury organizacyjnej do tablicy
  function getOsobyStruktura(data) {
    if (data.osoby) {
      allData.push(...data.osoby)
    }
    if (data.children) {
      data.children.forEach(getOsobyStruktura)
    }
  }

  //obsluga autocomplete, zapisanie wybranej osoby, ustawienie expandAccordeon ktore warunkuje otwarcie accodrion w strukturze organizacyjnej
  const handleOsobaChange = (event, value) => {
    const personData = Object.assign({}, copyPerson);
    if (value) {
      personData.idKadr = value.idKadr
      personData.imie = value.imie
      personData.nazwisko = value.nazwisko
      personData.idPrac = value.idPrac
      setExpandAccordion(true)
      setCopyPerson(personData)
      let tempArr = JSON.parse(JSON.stringify(listaPracownikow));   //deep clone of an array listaPracownikow
      filterListaPracownikow(tempArr[0], personData.idPrac)
      setFilteredListaPracownikow(tempArr);
      setIsFiltered(true);
      let pathsToOsoba = [];
      let tempArr2 = [];
      deepFilter(data, personData.idPrac, pathsToOsoba, tempArr2);  //zapisuje sciezki do osoby rozdzielone srednikami (do zmiennej pathsToOsoba)
      autoExpandCard(pathsToOsoba);
    }
    if (!value) {
      setFilteredListaPracownikow(listaPracownikow);
      state.setExpanded([]);
      setExpandAccordion(false)
      setCopyPerson('')
      setIsFiltered(false);
    }


  }

  //rozsuwa Cardy na podstawie tablicy ze ścieżkami do osoby np. [1, 2, 3, ;, 6, 7, 8, ;] - ścieżki są rozdzielone średnikami
  function autoExpandCard(pathsToOsoba) {
    let pathsArr = splitPaths(pathsToOsoba);
    pathsArr.map((path) => {  //lecimy po każdej ścieżce
      //tu mam path który jest tablicą np. [1,2,3]
      setExpanded(path)
    })
  }

  //rozdziela ścieżki, które są w jednej tablicy porozdzielane średnikami, na tablice tablic
  function splitPaths(pathsToOsoba) {
    let paths = [];
    let tempArr = [];
    pathsToOsoba.map((element) => {
      if (element !== ";") {
        tempArr.push(element);
      }
      else {
        paths.push(tempArr);
        tempArr = [];
      }
    })
    return paths;
  }

  // wyszukanie osoby z handleOsobaChange, rekurencyjnie w strukturze organizacyjnej, zapisanie tablicy stingow do tej osoby
  const deepFilter = (nodes, person, pathToOsoba, tempArr) => {
    if (nodes.osoby) {
      nodes.osoby.map(pers => {
        if (pers.idPrac === person) {
          pathToOsoba.concat(tempArr);
          concatTabs(pathToOsoba, tempArr)
          pathToOsoba.push(nodes.idStrukt.toString())
          pathToOsoba.push(';')
          tempArr = [];
        }
      })
    }
    if (nodes.children !== null) {
      var i;
      var result = null;
      for (i = 0; result == null && i < nodes.children.length; i++) {
        tempArr.push(nodes.idStrukt.toString())
        deepFilter(nodes.children[i], person, pathToOsoba, tempArr)
      }
    }
  };

  //przelatuje po całej strukturze rekurencyjnie i usuwa z niej osoby, które są inne niż osoba wyszukiwana
  const filterListaPracownikow = (nodes, person) => {
    if (nodes.osoby) {
      let tempOsoby = nodes.osoby;
      nodes.osoby = tempOsoby.filter(item => item.idPrac === person)
    }
    if (nodes.children !== null) {
      var i;
      var result = null;
      for (i = 0; result == null && i < nodes.children.length; i++) {
        filterListaPracownikow(nodes.children[i], person)
      }
    }
  };

  const concatTabs = (tab1, tab2) => {
    tab2.map((elem) => {
      tab1.push(elem);
    })
  }

  return (
    <>
      {filteredListaPracownikow.length > 0 ? <>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', alignItems: 'center' }}>
          <Box mt={2} mb={2} mr={3} display={'flex'} alignItems={'center'}><BackArrow /><Typography variant="h1" >Struktura Organizacyjna</Typography></Box>
          <Box>
            <Autocomplete
              size='small'
              id="osobyStruktura"
              options={listaOsob}
              onChange={(event, value) => handleOsobaChange(event, value)}
              getOptionLabel={(option) => (`${option.imie} ${option.nazwisko} - ${option.idPrac}`)}
              sx={{ width: 300, backgroundColor: '#fff' }}
              renderInput={(params) => <TextField {...params} label="Imię i nazwisko pracownika" />}
            />
          </Box>
        </Box>
        <Box minWidth={'100%'} >
          <Card>
            <TreeView
              expanded={expanded}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              sx={{ flexGrow: 1, overflowY: 'auto' }}
            >
              <ExpandProvider value={{ expanded, setExpanded }}>
                <TreeRender index={0} isFiltered={isFiltered} currentPath={[]} expanded={expanded} data={filteredListaPracownikow[0]} expandAccordion={expandAccordion} setExpandAccordion={setExpandAccordion} copyPerson={copyPerson} />
              </ExpandProvider>
            </TreeView>
          </Card>

        </Box>
      </> : <Spinner />}

    </>
  );
}

export default StrukturaOrganizacyjna;


