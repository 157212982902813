import { ReactComponent as Error403 } from "../../assets/icons/error403.svg";
import { ReactComponent as Error500 } from "../../assets/icons/error500.svg";
import { ReactComponent as Error404 } from "../../assets/icons/error404.svg";
import { ReactComponent as ErrorOffline } from "../../assets/icons/OfflineError.svg";


export const errorData = [
    {
        'idError': 403,
        'errorHeader': 'Błąd 403... Brak dostępu',
        'errorContent': 'Strona, której szukasz, ma ograniczony dostęp. Przejdź do strony głównej lub odwiedź nasze Centrum Pomocy.',
        'errorIcon': <Error403 />
    },
    {
        'idError': 404,
        'errorHeader': 'Błąd 404... Strona nie istnieje',
        'errorContent': 'Nie możemy znaleźć strony, której szukasz. Spróbuj jeszcze raz lub odwiedź nasze Centrum Pomocy.',
        'errorIcon': <Error404 />
    },
    {
        'idError': 500,
        'errorHeader': 'Błąd 500... Błąd serwera',
        'errorContent': 'Wystąpił błąd serwera. Nasz dział techniczny już nad tym pracuje. Przejdź do strony głównej lub odwiedź nasze Centrum Pomocy.',
        'errorIcon': <Error500 />
    },
    {
        'idError': 'Offline',
        'errorHeader': 'Brak połączenia z serwerem',
        'errorContent': 'Wystąpił błąd połączenia z serwerem. Sprawdź połączenie, przejdź do strony głównej lub odwiedź nasze Centrum Pomocy.',
        'errorIcon': <ErrorOffline />
    },

];