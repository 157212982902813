import React, { Component } from 'react';
import PatternService from '../services/pattern-service/patternService';
import IosShareIcon from '@mui/icons-material/IosShare';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const patternPrinterURL = "/wzorce/patternPrinter/";

class ExportPatternButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            base64EncodedPDF: '',
            name: '',
            printURL: patternPrinterURL + this.props.patternId,
            json: ''
        }

        this.downloadFile = this.downloadFile.bind(this);
    }


    downloadFile(e) {
        e.stopPropagation();
        PatternService.getInstance(this.props.apiurl, this.props.apiAuthHeader).getExportedPatternById(this.props.patternId).then(response => {

            this.setState({
                json: response.data,
            })
            var filename = JSON.parse(this.state.json).Name + ".json";
            var blob = new Blob([this.state.json], { type: 'text/plain' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                var e = document.createEvent('MouseEvents'),
                    a = document.createElement('a');
                a.download = filename;
                a.href = window.URL.createObjectURL(blob);
                a.dataset.downloadurl = ['text/plain', a.download, a.href].join(':');
                e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                a.dispatchEvent(e);
            }

        })
            .catch(error => {
                console.log(error);
            });

    }


    render() {
        return (
            <>
                <IosShareIcon titleAccess='Eksportuj' fontSize="inherit" onClick={(e) => this.downloadFile(e)} />
            </>


        )
    }
}

export default ExportPatternButton;