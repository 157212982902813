import React, { Component } from 'react';
import PatternService from '../services/pattern-service/patternService';
import { Button, ToastBody } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

toast.configure();

class ImportPatternButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            base64EncodedPDF: '',
            name: '',
            json: '',
            isNoFileChosen: true,
            error: '',
            patterns: this.props.patterns
        }

        this.importFile = this.importFile.bind(this);
        this.submitPattern = this.submitPattern.bind(this);
        this.resetInput = this.resetInput.bind(this);
    }
    componentWillReceiveProps

    importFile(){
        const selectedFile = document.getElementById('json').files[0];

        if(selectedFile)
        {
            if(selectedFile.size > 20971520){  //20MB w bajtach
                this.setState({ error: "Plik przekracza 20MB."})
                document.getElementById('json').value = "";
                return;
            }
            if(selectedFile.type != "application/json"){
                this.setState({ error: "Niepoprawne rozszerzenie pliku."})
                document.getElementById('json').value = "";
                return;
            }
            this.setState({error: ''})
            var fr=new FileReader();
            fr.readAsText(selectedFile);
    
            let that = this;
            fr.onload = function() {
                that.setState({
                    json: this.result
                })
            }
            this.setState({ isNoFileChosen: false })
            return;
        }
        document.getElementById('json').value = "";
        this.setState({ isNoFileChosen: true })

    }
    
    submitPattern(){
        PatternService.getInstance(this.props.apiurl, this.props.apiAuthHeader).addImportedPattern(this.state.json).then(response => {

            this.props.getAllPatternsByIdSystemu();
            this.resetInput();
            toast.success("Wzorzec zaimportowano pomyślnie.")
        }, 
        error => {

            toast.error(error);
            this.resetInput();
            
        })
    }

    resetInput(){
        document.getElementById('json').value = "";
        this.setState({ isNoFileChosen: true });
    }
    render() {
        return (

            <div>
                <div className="d-inline-flex" style={{minHeight: "50px"}}>
                        <div className="d-flex flex-column" style={{paddingRight: "5px", minWidth:"305px"}}>
                            <input type="file" id="json" name="json" accept="application/json" onChange={this.importFile}/>
                            <div className="d-flex flex-row">
                    {this.state.error != '' ?
                    <div style={{color: "red", fontSize: "13px"}}>
                        {this.state.error}
                    </div> :
                    <div style={{fontSize: "13px"}}>Plik o rozszerzeniu .json nie może przekraczać 20MB</div>}
                </div>
                        </div>
                        
                        <Button variant="primary" onClick={this.submitPattern} disabled={this.state.isNoFileChosen}>Wyślij</Button>
                </div>

            </div>



        )
    }
}

export default ImportPatternButton;