import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, FormControl, FormControlLabel, FormLabel, IconButton, Modal, Radio, RadioGroup, Typography } from '@mui/material';
import { apiService } from '../../services/api.service';


const ModalZestawienia = ({ open, setOpen, selected, filteredData, data, page, handlePrint }) => {
    const [groupValue, setGroupValue] = useState('strona');
    const [exportType, setExportType] = useState('pdf')
    const [dataNieob, setDataNieob] = useState([]);

    const handleClose = () => setOpen(false);

    const handleRadioGroup = (event) => {
        let grupa = event.target.value
        setGroupValue(event.target.value);
        if (grupa === 'strona') {
            if (page === 0) {
                let result = [];
                filteredData.slice(0, 10).map((item, index) => { return result.push(item.idNieobecnosci) });
                setDataNieob(result)
            } else if (page !== 0) {
                let result = [];
                filteredData.slice(`${page}0`, `${page + 1}0`).map((item, index) => { return result.push(item.idNieobecnosci) })
            }
        } else if (grupa === 'wszystkie') {
            let allIdDatas = data.map((n) => n.idNieobecnosci)
            setDataNieob(allIdDatas)
        } else if (grupa === 'wybrane') {
            setDataNieob(selected)
        }
    }

    const handleSendNieob = (event) => {
        event.preventDefault();
        if (exportType === 'csv') {
            apiService.setNieobecnosciCSV(dataNieob).then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Nieobecności.csv');
                document.body.appendChild(link);
                link.click();
            }
            )
        } else {
            handlePrint();
        }
    };


    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-zestawienia'
                aria-describedby='modal-zestawienia'
            >
                <Box className='modal' display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} justifyItems={'center'}>
                        <Typography variant='h1' mb={2} fontWeight={'bold'}>Eksportuj</Typography>
                        <Box
                            display='flex'
                            justifyContent='flex-end'
                            alignItems='flex-end'
                        >
                            <IconButton onClick={handleClose}><CloseIcon /></IconButton>
                        </Box>
                    </Box>
                    <Box>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby='eksport-group-label'
                                defaultValue='strona'
                                name='eksport-group'
                                value={groupValue}
                                onChange={handleRadioGroup}
                            >
                                <FormControlLabel value={'strona'} control={<Radio />} label='Obecną stronę' />
                                <FormControlLabel value={'wszystkie'} disabled={exportType === 'pdf' && true} control={<Radio />} label='Wszystkie nieobecności' />
                                <FormControlLabel value={'wybrane'} disabled={(selected.length > 0 && exportType !== 'pdf') ? false : true} control={<Radio />} label={`Wybrane rekordy: ${selected.length} wybranych`} />
                            </RadioGroup>
                            <FormLabel ><Typography mt={2} mb={2} variant='h4' color={'darkMode.700'}>Eksportuj jako:</Typography></FormLabel>
                            <RadioGroup
                                aria-labelledby='rodzaj-group-label'
                                defaultValue='pdf'
                                value={exportType}
                                name='rodzaj-group'
                                onChange={(event) => setExportType(event.target.value)}
                            >
                                <FormControlLabel value='pdf' control={<Radio />} label='Do druku' />
                                <FormControlLabel value='csv' control={<Radio />} label='Do pliku CSV' />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Box display={'flex'} justifyContent={'right'} gap={1}>
                        <Button variant='outlined' onClick={handleClose}>Wyjdź</Button>
                        <Button variant='contained' onClick={(event) => handleSendNieob(event)}>Eksportuj</Button>
                    </Box>
                </Box>
            </Modal>
        </>);
}

export default ModalZestawienia;