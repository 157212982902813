import { TablePagination } from '@mui/material';
import React from 'react';


function PaginationComponent({ setPage, page, setRowsPerPage, rowsPerPage, data }) {

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            <TablePagination
                sx={{ borderTop: 1, borderTopColor: "#D6DDEF" }}
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
}

export default PaginationComponent;