import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ReactComponent as ExpandIcon } from "../../assets/icons/Expand_up.svg";
import '../../styles/buttonWithoutTextTransform.css';

function TwojeUmowyAccordion(props) {

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography variant="h3" m={2} mb={1} fontWeight={'700'}>Twoje umowy</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <TableContainer>
                    <Table size="small" aria-label="historiaZatrudnienia-table">
                        <TableHead />
                        <TableBody>
                            {props.danePrac.map((item, index) => (
                                <TableRow key={index} style={{ height: "45px" }} sx={{ '&:last-child td, &:last-child th': { border: 0 }, }} >
                                    <TableCell>
                                        <Typography variant="h3" component='span' fontWeight={'700'}>ID umowy: </Typography>
                                        <Typography component='span' variant='h3'>{item.idPrac}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h3" component='span' fontWeight={'700'}>Nazwa: </Typography>
                                        <Typography component='span' variant='h3'>{item.umowa}</Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionDetails>
        </Accordion>
    )
}

export default TwojeUmowyAccordion;