import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../loginRedux/actionCreators';
import { config } from '../../../config/configApp';
import HeaderContent from '../components/headerContent';
import './styles/header.css';


const Header = forwardRef((props, ref) => {

  const dispatch = useDispatch();
  const { removeToken } = bindActionCreators(actionCreators, dispatch);
  const childRef = useRef();

  useImperativeHandle(ref, () => ({ resetTimer() { childRef.current.resetTimer() } }));

  return (
    <>
      <div className='insideHeader'>
        <HeaderContent ref={childRef} config={config} menuModule={props.menuModule} removeToken={() => (removeToken())} path={'../assets/'} />
      </div>
    </>

  )
});

export default Header;