export const Converter = { titleCase, formatDateToDDMMYYYY, formatDateToDDMMYYYYHHMMSS, onlyFirstLetterCapital, formatDateToYYYYMMDD }

function formatDateToDDMMYYYY(date) {
    let d = new Date(date);
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return `${day}/${month}/${year}`;
}

function formatDateToYYYYMMDD(date) {
    let d = new Date(date);
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return `${year}-${month}-${day}`;
}
function formatDateToDDMMYYYYHHMMSS(date) {
    let d = new Date(date);
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());
    let hour = String(d.getHours());
    let minutes = String(d.getMinutes());
    let seconds = String(d.getSeconds());
    if (seconds.length < 2) seconds = '0' + seconds;
    if (minutes.length < 2) minutes = '0' + minutes;
    if (hour.length < 2) hour = '0' + hour;
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return `${day}/${month}/${year} ${hour}:${minutes}:${seconds}`;
}

function onlyFirstLetterCapital(text) {
    let formattedText = text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    return formattedText;
}

function titleCase(string) {
    let text = string.toLowerCase().split('');
    for (let i = 0; i < text.length; i++) {
        if (text[i] == text[0]) {
            text[0] = text[0].toUpperCase();
        } else if (text[i] == " " || text[i] == "-") {
            text[i + 1] = text[i + 1].toUpperCase();
        }
    }
    return text;
}

// Funkcja służy do konwersji stringa, w taki sposób, aby imię i nazwisko rozpoczynało się z dużej litery.
// Funkcja uwzględnia nazwiska dwuczłonowe.