import { IconButton, Modal, Button } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import '../../styles/modal.css';
import ModalZatrudnienieZawartosc from './ModalZatrudnienieZawartosc';


function ModalZatrudnienie({ open, setOpen, itemHistZatrud }) {
    const handleClose = () => setOpen(false);
    return (
        <>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-zatrudnienie'
                aria-describedby='modal-zatrudnienie'
            >
                <Box className='modalZatrudnienie' display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        sx={{ mt: 2, mr: 2 }}
                    >
                        <IconButton onClick={handleClose}><CloseIcon /></IconButton>
                    </Box>
                    <ModalZatrudnienieZawartosc itemHistZatrud={itemHistZatrud}></ModalZatrudnienieZawartosc>

                    <Box sx={{ mt: -2, mb: 2 }} textAlign='center'>
                        <Button
                            onClick={handleClose}
                            style={{ textTransform: 'capitalize' }}
                            variant="text">Zamknij</Button>
                    </Box>
                </Box>
            </Modal >


        </>
    );
}
export default ModalZatrudnienie;