import { Box } from '@mui/material';
import React, { useState } from 'react';
import PozyczkiAccordion from '../accordions/PozyczkiAccordion';
import WynagrodzenieAccordion from '../accordions/WynagrodzenieAccordion';

function DaneFinansoweUzytkownika({ finanse }) {
    const [showPozyczki, setShowPozyczki] = useState(true);
    const [showWynagrodzenie, setShowWynagrodzenie] = useState(true);
    const [expanded, setExpanded] = React.useState(false);

    const handleShowValue = (value) => {
        if (value === 'pozyczki') {
            setShowPozyczki(!showPozyczki)
        } else {
            setShowWynagrodzenie(!showWynagrodzenie)
        }
    };
    const handleChangeHideDetails = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);

    };

    const handlers = { finanse, handleShowValue, expanded, handleChangeHideDetails };

    return (
        <Box mb={4}>
            <WynagrodzenieAccordion handlers={handlers} showWynagrodzenie={showWynagrodzenie} />
            <PozyczkiAccordion handlers={handlers} showPozyczki={showPozyczki} />
        </Box>
    )
}

export default DaneFinansoweUzytkownika;