import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import TwojeUmowyAccordion from '../modules/accordions/TwojeUmowyAccordion';
import TwojAdresEmailAccordion from '../modules/accordions/TwojAdresEmailAccordion';
import HasloAccordion from '../modules/accordions/HasloAccordion';
import { actionCreators } from '../redux/actionCreators';
import Spinner from '../components/Spinner';
import BackArrow from '../components/BackArrow';

function Ustawienia() {
    const danePrac = useSelector((state) => state.danePrac);
    const danePracownika = useSelector((state) => state.danePracownika);
    const dispatch = useDispatch();
    const { addDanePrac, addDanePracownika } = bindActionCreators(actionCreators, dispatch);

    useEffect(() => {
        getDane();
    }, []);

    const getDane = () => {
        if (danePrac === null) {
            addDanePrac();
        }
        if (danePracownika === null) {
            addDanePracownika();
        }
    }

    return (
        <div>
            {(danePrac && danePracownika) ?
                <>
                    <Box mt={2} mb={4} display={'flex'} alignItems={'center'}><BackArrow /><Typography variant="h1" >Dopasuj do siebie</Typography></Box>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column' } }} mr={{ lg: 18 }}>
                        {danePrac && <TwojeUmowyAccordion danePrac={danePrac} />}
                        {danePracownika && <TwojAdresEmailAccordion email={danePracownika.email} />}
                        <HasloAccordion />
                    </Box>
                </>
                : <Spinner />}
        </div>
    );
}

export default Ustawienia;