import React, { useEffect, useRef } from 'react';

function useDidMountEffect(func, deps) {     //ten hook wywołuje się tak jak useEffect oprócz initial render
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
    }, deps);
}

export const CustomHooksService = { useDidMountEffect };