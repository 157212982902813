import { ReactComponent as AutoAwesomeMosaic } from "../assets/icons/AutoAwesomeMosaic.svg";
import { ReactComponent as Description } from "../assets/icons/Description.svg";
import { ReactComponent as Timer } from "../assets/icons/Timer.svg";
import { ReactComponent as School } from "../assets/icons/School.svg";
import { ReactComponent as DriveFileRenameOutline } from "../assets/icons/DriveFileRenameOutline.svg";
import { ReactComponent as EditFill } from "../assets/icons/Edit_fill.svg";
import { ReactComponent as KreatorDokumentow } from "../assets/icons/KreatorDokumentow.svg";
import { ReactComponent as DostepnyUrlop } from "../assets/icons/DostepnyUrlop.svg";
import { ReactComponent as CalendarIcon } from "../assets/icons/CalendarIcon.svg";
import { ReactComponent as UserIcon } from "../assets/icons/UserIcon.svg";
import { ReactComponent as WheelchairPickup } from "../assets/icons/WheelchairPickup.svg";
import { ReactComponent as Help } from "../assets/icons/Help.svg";
import { ReactComponent as Computer } from "../assets/icons/Computer.svg";
import { ReactComponent as SettingsPhone } from "../assets/icons/SettingsPhone.svg";
import { ReactComponent as MojeDane } from "../assets/icons/MojeDane.svg";
import { ReactComponent as MojeWnioski } from "../assets/icons/MojeWnioski.svg";
import { ReactComponent as WnioskiPracownikow } from "../assets/icons/WnioskiPracownikow.svg";
import { ReactComponent as Settings } from "../assets/icons/Settings.svg";
import { ReactComponent as CallSplit } from "../assets/icons/CallSplit.svg";
import { ReactComponent as ZestawieniaPracownikow } from "../assets/icons/ZestawieniaPracownikow.svg";
import '../styles/iconMenu.css'


const asideData = [
    {
        "id": 1, "name": "Pulpit", "icon": <AutoAwesomeMosaic />, "url": "/pulpit/podsumowanie", "position": "top",
        "subaside": [
            { "id": 1, "name": "Pulpit", "icon": <Description className="iconMenu" />, "url": "/pulpit/podsumowanie" },
            // { "id": 2, "name": "Czas pracy", "icon": <Timer />, "url": "/pulpit/czas-pracy" },
            { "id": 3, "name": "Szkolenia", "icon": <School className="iconMenu" />, "url": "/pulpit/szkolenia" }
        ]
    },
    {
        "id": 2, "name": "Wnioski", "icon": <DriveFileRenameOutline></DriveFileRenameOutline>, "url": "/wnioski/nowy-wniosek", "position": "top",
        "subaside": [
            { "id": 1, "name": "Nowy wniosek", "icon": <EditFill className="iconMenu" />, "url": "/wnioski/nowy-wniosek" },
            { "id": 6, "name": "Kreator dokumentów", "icon": <KreatorDokumentow className="iconMenu" />, "url": "/wzorce/lista" },
            { "id": 3, "name": "Moje wnioski", "icon": <MojeWnioski className="iconMenu" />, "url": "/wnioski/moje-wnioski" },
            { "id": 2, "name": "Nadgodziny", "icon": <Timer className="iconMenu" />, "url": "/wnioski/nadgodziny" },
            { "id": 4, "name": "Dostępny urlop", "icon": <DostepnyUrlop className="iconMenu" />, "url": "/wnioski/dostepny-urlop" },
            { "id": 8, "name": "Pracownicy", "divide": true, "url": "" },
            { "id": 5, "name": "Wnioski pracowników", "icon": <WnioskiPracownikow className="iconMenu" />, "url": "/wnioski/wnioski-pracownikow" },
            { "id": 7, "name": "Zestawienia", "icon": <ZestawieniaPracownikow className="iconMenu" />, "url": "/wnioski/zestawienia" }
        ]
    },
    {
        "id": 3, "name": "Nieobecności", "icon": <CalendarIcon />, "url": "/kalendarz/kalendarz-nieobecnosci", "position": "top",
        "subaside": [
            { "id": 1, "name": "Nieobecności", "icon": <CalendarIcon className="iconMenu" />, "url": "/kalendarz/kalendarz-nieobecnosci" },
        ]
    },
    {
        "id": 4, "name": "Twoje konto", "icon": <UserIcon />, "url": "/twoje-konto/twoj-profil", "position": "bottom",
        "subaside": [
            { "id": 1, "name": "Moje dane", "icon": <MojeDane className="iconMenu" />, "url": "/twoje-konto/twoj-profil" },
            // { "id": 2, "name": "Twoje dokumenty", "icon": <AllInbox />, "url": "/twoje-konto/twoje-dokumenty" },
            { "id": 3, "name": "Struktura firmy", "icon": <CallSplit className="iconMenu" />, "url": "/twoje-konto/struktura-firmy" },
            { "id": 4, "name": "Ustawienia", "icon": <Settings className="iconMenu" />, "url": "/twoje-konto/ustawienia" },
        ]
    },
    {
        "id": 5, "name": "Dostosuj", "icon": <WheelchairPickup />, "url": "/dostosuj/moje-dane", "position": "bottom",
        "subaside": [
            { "id": 1, "name": "test", "icon": <DriveFileRenameOutline className="iconMenu" />, "url": "/dostosuj/moje-dane" },
        ]
    },
    {
        "id": 6, "name": "Pomoc", "icon": <Help />, "url": "/pomoc/o-programie", "position": "bottom",
        "subaside": [
            { "id": 1, "name": "O Programie", "icon": <Computer className="iconMenu" />, "url": "/pomoc/o-programie" },
            { "id": 2, "name": "Centrum pomocy", "icon": <SettingsPhone className="iconMenu" />, "url": "/profil/centrum-pomocy" },
        ]
    },
]

export default asideData;
