import React from "react";
import { Grid, Button, Box, Paper, ToggleButtonGroup } from "@mui/material";
import IosShareIcon from '@mui/icons-material/IosShare';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useState } from "react";

export default function TableStyleButtonsWnioski({ setHeightRow }) {

    const [openDropdown, setOpenDropdown] = useState(false);
    const [styleButton1, setStyleButton1] = useState(false);
    const [styleButton2, setStyleButton2] = useState(true);
    const [styleButton3, setStyleButton3] = useState(false);

    const handleClick = () => {
        setOpenDropdown((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpenDropdown(false);
    };

    const handlChangeHeightRowPomniejszona = () => {
        setHeightRow(`36px`);
        setOpenDropdown(false);
        setStyleButton1(true);
        setStyleButton2(false);
        setStyleButton3(false);

    }
    const handlChangeHeightRowNormal = () => {
        setHeightRow(`52px`);
        setOpenDropdown(false);
        setStyleButton1(false);
        setStyleButton2(true);
        setStyleButton3(false);

    }
    const handlChangeHeightRowPowiekszona = () => {
        setHeightRow(`65px`);
        setOpenDropdown(false);
        setStyleButton1(false);
        setStyleButton2(false);
        setStyleButton3(true);

    }

    return (
        <>
            <Grid container
                pl={4} pt={1}
                columnSpacing={2}
                direction="row"
                alignItems="center">
                <Grid item>
                    <Box sx={{ position: 'relative' }}>
                        <Button
                            onClick={handleClick}
                            sx={{ "&:hover": { backgroundColor: "primary.50" } }}
                            style={{ textTransform: 'capitalize', fontSize: "12px" }}
                            variant="text"
                            startIcon={<VerticalSplitIcon />}>
                            Gęstość
                        </Button>
                        {openDropdown ? (
                            <ClickAwayListener onClickAway={handleClickAway}>
                                <Grid>
                                    <Paper sx={{
                                        position: 'absolute',
                                        top: 38,
                                        right: -16,
                                        left: 0,
                                        zIndex: 3,
                                        p: 0.5,
                                        bgcolor: '#FFFFFF',
                                        border: "none",
                                        borderRadius: 2,
                                    }}>

                                        <Button
                                            fullWidth
                                            style={{
                                                fontSize: "12px",
                                                textTransform: "capitalize",
                                                "&:hover": { backgroundColor: "primary.50" },
                                            }}
                                            sx={styleButton1 ? { backgroundColor: "#E1EBFE" } : { color: '#141515' }}
                                            onClick={handlChangeHeightRowPomniejszona}
                                            variant="text">Pomniejszona</Button>
                                        <Button
                                            fullWidth
                                            style={{
                                                fontSize: "12px",
                                                textTransform: "capitalize",
                                                "&:hover": { backgroundColor: "primary.50" }
                                            }}
                                            sx={styleButton2 ? { backgroundColor: "#E1EBFE" } : { color: '#141515' }}
                                            onClick={handlChangeHeightRowNormal}
                                            variant="text">Standardowa</Button>
                                        <Button
                                            fullWidth
                                            style={{
                                                fontSize: "12px",
                                                textTransform: "capitalize",
                                                "&:hover": { backgroundColor: "primary.50" }
                                            }}
                                            sx={styleButton3 ? { backgroundColor: "#E1EBFE" } : { color: '#141515' }}
                                            onClick={handlChangeHeightRowPowiekszona}
                                            variant="text">Powiększona</Button>

                                    </Paper>
                                </Grid>
                            </ClickAwayListener >
                        ) : null}
                    </Box>
                </Grid>
                <Grid item>
                    <Button
                        sx={{ "&:hover": { backgroundColor: "primary.50" } }}
                        style={{ textTransform: 'capitalize', fontSize: "12px" }}
                        variant="text"
                        startIcon={<IosShareIcon />}>
                        Eksportuj
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}