import React from 'react';
import { CircularProgress } from '@mui/material';
import '../styles/spinner.css'

function SpinnerWithoutText() {
    return (
        <>
            <CircularProgress sx={{ m: 5 }} />
        </>
    );
}

export default SpinnerWithoutText;