// import { authenticationService } from '../services';

export function authHeader() {
    let token = null;
    if (JSON.parse(localStorage.getItem(localStorage.getItem('appPrefix') + 'LoginResponse'))) {
        token = JSON.parse(localStorage.getItem(localStorage.getItem('appPrefix') + 'LoginResponse')).token;
        if (/*currentUser && currentUser.*/token) {

            return {
                Authorization: `Bearer ${token}`, 'Accept': 'application/json',
                'Content-Type': 'application/json',
            };
        } else {
            return {};
        }
    }
    else {
        return {};
    }
    //const token = JSON.parse(localStorage.getItem(localStorage.getItem('appPrefix') + 'LoginResponse')).token;

    // return authorization header with jwt token
    //const currentUser = authenticationService.currentUserValue;

}