import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box } from '@mui/system';

function PozyczkiAccordion({ handlers: { finanse, handleShowValue, expanded, handleChangeHideDetails }, showPozyczki }) {
    const panel = "panel2df";
    return (
        <>
            {finanse.pozyczki &&
                finanse.pozyczki.map((row, index) => (
                    <Accordion key={index} expanded={expanded === panel} onChange={handleChangeHideDetails(panel)}>
                        <AccordionSummary
                            sx={{ paddingTop: '10px', paddingBottom: '10px' }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${panel}-content`}
                            id={`${panel}-header`}
                        >
                            <Typography ml={2} variant='h3' fontWeight={'bold'}>Twoje pożyczki</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} mt={-2}>
                                <Typography ml={2} variant='h4'>{!showPozyczki ? `Do spłaty: ${row.kwota} zł brutto` : '********************'}</Typography>
                                <Box mr={-0.5} ><IconButton
                                    aria-label="toggle visibility"
                                    onClick={() => handleShowValue('pozyczki')}
                                >
                                    {!showPozyczki ? < VisibilityIcon style={{ height: '18px', width: '18px' }} /> : <VisibilityOffIcon style={{ height: '18px', width: '18px' }} />}
                                </IconButton>
                                </Box>
                            </Box>
                            {/* <Box display={'flex'} flexDirection={'column'} alignItems={'left'}>
                                <Typography variant='caption2'>Opis: {row.opis}</Typography>
                                <Typography variant='caption2'>Oprocentowanie: {row.oprocentowanie} %</Typography>
                                <Typography variant='caption2'>{!showPozyczki ? `Odsetki: ${row.odsetki} zł` : '*********'}</Typography>
                                <Typography variant='caption2'>Wierzyciel: {row.wierzyciel}</Typography>
                            </Box> */}
                        </AccordionDetails>
                    </Accordion>
                ))
            }
        </>
    );
}
export default PozyczkiAccordion;