import React, { useState } from 'react';
import { Button, IconButton, Modal, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import '../../styles/modal.css';
import { ModalHex } from '../../styles/icons.js';



function ModalZgloszenie({ openModal, setOpenModal }) {
    const [opis, setOpis] = useState('');

    const handleOpisChange = (e) => {
        setOpis(e.target.value)
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby='modal-zgloszenie'
            aria-describedby='modal-zgloszenie'
        >
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} className='modal'>
                <Box display={'flex'} width={'100%'} alignItems={'center'} justifyContent={'space-between'} >
                    <Box flexGrow={1} />
                    <Box><IconButton onClick={handleClose}><CloseIcon /></IconButton></Box>
                </Box>
                <Box mb={1}>
                    <ModalHex />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography variant='h1' mb={2} fontWeight={'bold'}>Co chciałbyś zmienić?</Typography>
                </Box>
                <TextField multiline minRows={5} fullWidth id='outlined-basic' label='Twoje zmiany w profilu...' variant='outlined' name='opis' value={opis} onChange={(e) => handleOpisChange(e)} />
                <Box mt={2} display={'inline-flex'}>
                    <Button sx={{ minWidth: '200px' }} variant='contained'>Wyślij</Button>

                </Box>
            </Box>

        </Modal>
    );
}

export default ModalZgloszenie;