import { ReactComponent as CreateIcon } from '../assets/icons/CreateIcon.svg';
import { ReactComponent as RefuseIcon } from '../assets/icons/RefuseIcon.svg';
import { ReactComponent as AcceptIcon } from '../assets/icons/AcceptIcon.svg';
import { ReactComponent as ModifyIcon } from '../assets/icons/ModifyIcon.svg';
import { ReactComponent as DeleteIcon } from '../assets/icons/DeleteIcon.svg';
import { ReactComponent as EditIcon } from '../assets/icons/EditIcon.svg';
import { ReactComponent as CompletedIcon } from '../assets/icons/CompletedIcon.svg';

export const StatusyWnioskow = { getTextFromInt, getIconFromInt };

function getTextFromInt(int) {
    const Status = ['Oczekuje', 'Odrzucony', 'Zaakceptowany', 'Zaplanowany', 'Zrealizowany', 'Anulowany', 'Edytowany'];
    return (
        Status[int]
    )
};

function getIconFromInt(int) {
    switch (int) {
        case 0:
            return CreateIcon
        case 1:
            return RefuseIcon
        case 2:
            return AcceptIcon
        case 3:
            return ModifyIcon
        case 4:
            return CompletedIcon
        case 5:
            return DeleteIcon
        case 6:
            return EditIcon
        default:
            return null
    }
}