
import React, { useState } from 'react';
import { Routes, Route, Navigate, useNavigate, useParams } from "react-router-dom";
import './submodules/template/layout/styles/layout.css'
import TwojeKonto from './pages/TwojeKonto';
import Dostosuj from './pages/Dostosuj';
import Kalendarz from './pages/Kalendarz';
import NowyWniosek from './pages/NowyWniosek';
import DostepnyUrlop from './pages/DostepnyUrlop';
import Szkolenia from './pages/Szkolenia';
import { Box, Typography } from '@mui/material';
import OProgramie from './pages/OProgramie';
import CentrumPomocy from './pages/CentrumPomocy';
import WnioskiPracownikow from './pages/WnioskiPracownikow';
import MojeWnioski from './pages/MojeWnioski';
import Nadgodziny from './pages/Nadgodziny';
import Ustawienia from './pages/Ustawienia';
import PodsumowanieNowe from './pages/PodsumowanieNowe';
import StrukturaOrganizacyjna from './pages/StrukturaOrganizacyjna';
import { RouterLinks } from './submodules/patternprinter/patternExport'
import { authHeader } from './config/authHeader';
import apiurl from './apiurl';
import './styles/removeModalBackground.css';
import { ExpandProvider } from './services/expandContext';
import BackArrow from './components/BackArrow';
import Zestawienia from './pages/Zestawienia';
import ErrorPage from './interceptors/errors/errorPage';
import SearchTextField from './submodules/template/components/SearchTextField';

function MainRouter(props) {
  const [expanded, setExpanded] = useState([]);
  let navigation = useNavigate();
  let params = useParams();

  return (

    <Box sx={{ ml: "2vw", mr: "2vw", mb: "1vh" }}>
      <React.Fragment>
        <ExpandProvider value={{ expanded, setExpanded }}>
          <Routes>
            <Route path="/" element={<Navigate to="/pulpit/podsumowanie" />} />
            <Route path="/pulpit/podsumowanie" element={<PodsumowanieNowe />} />
            <Route path="/pulpit/szkolenia" element={<Szkolenia />} />
            <Route path="/wnioski/nowy-wniosek" element={<NowyWniosek />} />
            <Route path="/wnioski/nadgodziny" element={<Nadgodziny />} />
            <Route path="/wnioski/moje-wnioski" element={<MojeWnioski />} />
            <Route path="/wnioski/wnioski-pracownikow" element={<WnioskiPracownikow />} />
            <Route path="/wnioski/zestawienia" element={<Zestawienia />} />
            <Route path="/wnioski/dostepny-urlop" element={<DostepnyUrlop />} />
            <Route path="/twoje-konto/twoj-profil" element={<TwojeKonto />} />
            <Route path="/twoje-konto/ustawienia" element={<Ustawienia />} />
            <Route path="/twoje-konto/struktura-firmy" element={<StrukturaOrganizacyjna />} />
            <Route path="/dostosuj" element={<Dostosuj />} />
            <Route path="/kalendarz/kalendarz-nieobecnosci" element={<Kalendarz />} />
            <Route path="/pomoc/o-programie" element={<OProgramie />} />
            <Route path="/profil/centrum-pomocy" element={<CentrumPomocy />} />
            <Route path="/forbiddenPath" element={<ErrorPage />} />
            <Route path="/internalServerError" element={<ErrorPage />} />
            <Route path="/pageNotFound" element={<ErrorPage />} />
            <Route path="/noServerConnection" element={<ErrorPage />} />
            <Route path="/wzorce/*" element={
              <>
                <Box ml={-1} mb={2} display={'flex'} alignItems={'center'}><BackArrow /><Typography variant="h1" >Kreator dokumentów</Typography></Box>
                <RouterLinks
                  {...props}
                  searchTextField={SearchTextField}
                  apiAuthHeader={authHeader()}
                  apiurl={`${apiurl}`}
                  navigation={navigation}
                  params={params}
                >
                </RouterLinks>
              </>

            } />

          </Routes>
        </ExpandProvider>

      </React.Fragment>
    </Box >

  );
}

export default MainRouter;
