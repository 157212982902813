import React from 'react';
import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';

export const CustomPulpitButton = withStyles({
    root: {
        margin: 10,
        background: 'white',
        borderRadius: 4,
        border: 0,
        color: "#273B4A",
        height: '142px',
        width: '171px',
        padding: "0 30px",
        boxShadow: "0px 3px 1px -2px #D6DDEF,0px 2px 2px 0px #D6DDEF,0px 1px 5px 0px #D6DDEF",
        '&:hover': {
            backgroundColor: '#273B4A',
            color: '#FFFFFF',
        },
        '&:hover path': {
            fill: '#FFFFFF',
            color: '#FFFFFF',
            // stroke: '#FFFFFF'
        },
        '&:hover circle': {
            stroke: '#FFFFFF'
        },
    }
})(props => <Button {...props} />);
