import PatternService from '../pattern-service/patternService';

export const printService = { openPrintDocument }

async function openPrintDocument({
    apiurl,
    apiAuthHeader,
    idPrac,
    idWniosek,
    TypWniosku,
    KodRodzajuNieobecnosci,
    NazwaRodzajuNieobecnosci,
    DataOd,
    DataDo,
    DataWn,
    hhmmOd,
    hhmmDo
}) {

    let dane = { apiurl, apiAuthHeader, idPrac, idWniosek, TypWniosku, KodRodzajuNieobecnosci, NazwaRodzajuNieobecnosci, DataOd, DataDo, DataWn, hhmmOd, hhmmDo };

    getDocument(dane).then(async wydruk => {
        const firstPath = "/" + window.location.pathname.split('/')[1];
        let pdfWindow = await window.open(firstPath + "/wzorce/druk/wczytywanie")

        setTimeout(() => {
            pdfWindow.document.write("<html><head><title>" + wydruk.name + "</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
            pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(wydruk.base64EncodedPDF) + "#toolbar=0&navpanes=0&scrollbar=0'></embed></body></html>");

            pdfWindow.history.pushState({}, "", firstPath + "/wzorce/druk");

        }, 500);
    });
    // localStorage.setItem("pdf", this.state.base64EncodedPDF);
    //localStorage.setItem("pdfValues", this.state.base64EncodedPDF)
    //let pathname = window.location.pathname;
    //let pdfWindow = await window.open(pathname + "/wczytywanie")


    //window.open(patternPrinterURL + this.props.patternId, "_blank");

}



function getDocument(dane) {


    let result = PatternService.getInstance(dane.apiurl, dane.apiAuthHeader).getConvertedPatternWithDataDlaWniosku(
        dane.idPrac,
        dane.idWniosek,
        dane.TypWniosku,
        dane.KodRodzajuNieobecnosci,
        dane.NazwaRodzajuNieobecnosci,
        dane.DataOd,
        dane.DataDo,
        dane.DataWn,
        dane.hhmmOd,
        dane.hhmmDo).then(response => {
            return {
                base64EncodedPDF: response.data.base64EncodedPdf,
                name: response.data.name
            }

        })
        .catch(error => {
            console.log(error);
        });
    return result;
}
