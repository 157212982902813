import React, { useState } from 'react';
import '../../styles/printModalZatrudnienie.css'
import SciezkaKariery from '../accordions/SciezkaKariery';
import AdresZamieszkania from '../accordions/AdresZamieszkania';
import DaneOsobowe from '../accordions/DaneOsobowe';
import Adres from '../accordions/Adres';
import AdresKorespondencyjny from '../accordions/AdresKorespondencyjny';
import AdresDoCelowPodatkowych from '../accordions/AdresDoCelowPodatkowych';

function DaneOsoboweZatrudnienia({ histZatrudnienia, dane }) {

    const [expanded, setExpanded] = React.useState(false);

    const handleChangeHideDetails = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);

    };

    const handlers = { dane, handleChangeHideDetails, expanded };

    return (
        <>
            <SciezkaKariery handlers={handlers} histZatrudnienia={histZatrudnienia} />
            <DaneOsobowe handlers={handlers} />
            <Adres handlers={handlers} />
            <AdresZamieszkania handlers={handlers} />
            <AdresKorespondencyjny handlers={handlers} />
            <AdresDoCelowPodatkowych handlers={handlers} />
        </>
    )
}
export default DaneOsoboweZatrudnienia;