import React, { Component } from 'react';
import PatternService from '../../services/pattern-service/patternService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Pattern } from '../../models/pattern';
import TextEditor from '../../common/textEditor';
import PatternForm from '../../common/patternForm';
import ActionModal from '../../common/modals/actionModal';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, } from '@mui/material';
import Spinner from '../../../../components/Spinner';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

toast.configure();

const initialState = {
    Typ_Wzorca: 0,
    name: '',
    description: '',
    Id_Systemu: 0,
    editorValue: '&nbsp;',
    showSaveModal: false,
    showReturnModal: false,
    showPreviewModal: false,
    nameError: '',
    descriptionError: '',
    Id_SystemuError: '',
    isPortrait: true, //true to portrait, false to landscape
    marginTop: 30,
    marginBottom: 30,
    marginRight: 30,
    marginLeft: 30,
    patternMacros: [],
    isMacrosLoaded: false,
    isEndOfDocumentVisible: true,
    permission: ''
}


const patternPreviewerURL = "/wzorce/patternPreviewer";
let patternService;
class PatternCreator extends Component {


    constructor(props) {
        super(props);
        this.state = initialState;
        //patternService = new PatternService(this.props.apiAuthHeader);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleId_SystemuChange = this.handleId_SystemuChange.bind(this);
        this.handleCloseSaveModal = this.handleCloseSaveModal.bind(this);
        this.handleShowSaveModal = this.handleShowSaveModal.bind(this);
        this.handleShowReturnModal = this.handleShowReturnModal.bind(this);
        this.handleCloseReturnModal = this.handleCloseReturnModal.bind(this);
        this.handleShowPreviewModal = this.handleShowPreviewModal.bind(this);
        this.handleClosePreviewModal = this.handleClosePreviewModal.bind(this);
        this.changeOrientationToLandscape = this.changeOrientationToLandscape.bind(this);
        this.changeOrientationToPortrait = this.changeOrientationToPortrait.bind(this);
        this.handleMarginBottomChange = this.handleMarginBottomChange.bind(this);
        this.handleMarginLeftChange = this.handleMarginLeftChange.bind(this);
        this.handleMarginRightChange = this.handleMarginRightChange.bind(this);
        this.handleMarginTopChange = this.handleMarginTopChange.bind(this);
        this.restoreMargins = this.restoreMargins.bind(this);
        this.getPreviewPattern = this.getPreviewPattern.bind(this);
        this.handleSaveModalAction = this.handleSaveModalAction.bind(this);
        this.handleReturnModalAction = this.handleReturnModalAction.bind(this);
        this.onChangeIsEndOfDocumentVisible = this.onChangeIsEndOfDocumentVisible.bind(this);
        this.handleTypWzorcaChange = this.handleTypWzorcaChange.bind(this);
    }



    async componentDidMount() {
        await PatternService.getInstance(this.props.apiurl, this.props.apiAuthHeader).getRola().then(res => {
            if (res.data.dostepneRole.includes(1)) {
                this.setState({ permission: true })
            }
            else {
                this.setState({ permission: false })
            }
        })
        await this.getIdSystemu();
        this.getPatternMacros();
        window.addEventListener("beforeunload", this.onUnload);
        let that = this;
        //this.props.promptActivate();
    }


    async componentWillUnmount() {
        let that = this;
        //this.props.promptDeactivate();
        window.removeEventListener("beforeunload", this.onUnload);
    }

    getIdSystemu() {
        PatternService.getInstance(this.props.apiurl, this.props.apiAuthHeader).getIdSystemu().then(response => {
            this.setState({
                Id_Systemu: response.data,
            })
        });
    }

    getPatternMacros() {
        PatternService.getInstance(this.props.apiurl, this.props.apiAuthHeader).getPatternMacros().then(response => {
            this.setState({
                patternMacros: response.data,
                isMacrosLoaded: true
            })
        });
    }
    onUnload = e => {
        e.preventDefault();
        e.returnValue = '';
    }

    handleShowSaveModal() {
        const isValid = this.validate();
        if (isValid) {
            //this.props.promptDeactivate();
            this.setState({
                showSaveModal: true,
            });
        }
    }
    handleShowReturnModal() {
        this.setState({
            showReturnModal: true,
        });
    }
    handleShowPreviewModal() {
        this.setState({ showPreviewModal: true })
    }
    handleCloseSaveModal() {
        //this.props.promptActivate();
        this.setState({
            showSaveModal: false,
        })
    }
    handleCloseReturnModal() {

        this.setState({
            showReturnModal: false,
        })
    }
    handleClosePreviewModal() {
        this.setState({ showPreviewModal: false })
    }


    handleNameChange(event) {
        this.setState({ name: event.target.value })

    }
    handleDescriptionChange(event) {
        this.setState({ description: event.target.value })

    }
    handleId_SystemuChange(event) {
        this.setState({ Id_Systemu: event.target.value })

    }
    handleTypWzorcaChange(event) {
        this.setState({ Typ_Wzorca: event.target.value })

    }
    handleMarginTopChange(event) {
        this.setState({ marginTop: event.target.value })
        if (event.target.value > 200) {
            this.setState({ marginTop: 200 })
        }
        if (event.target.value < 0) {
            this.setState({ marginTop: 0 })
        }
        if (event.target.value === '')
            this.setState({ marginTop: 0 })

    }
    handleMarginBottomChange(event) {
        this.setState({ marginBottom: event.target.value })
        if (event.target.value > 200) {
            this.setState({ marginBottom: 200 })
        }
        if (event.target.value < 0) {
            this.setState({ marginBottom: 0 })
        }
        if (event.target.value === '')
            this.setState({ marginBottom: 0 })
    }
    handleMarginRightChange(event) {
        this.setState({ marginRight: event.target.value })
        if (event.target.value > 200) {
            this.setState({ marginRight: 200 })
        }
        if (event.target.value < 0) {
            this.setState({ marginRight: 0 })
        }
        if (event.target.value === '')
            this.setState({ marginRight: 0 })
    }
    handleMarginLeftChange(event) {
        this.setState({ marginLeft: event.target.value })
        if (event.target.value > 200) {
            this.setState({ marginLeft: 200 })
        }
        if (event.target.value < 0) {
            this.setState({ marginLeft: 0 })
        }
        if (event.target.value === '')
            this.setState({ marginLeft: 0 })
    }
    validate() {
        let nameError = "";
        let descriptionError = "";

        if (this.state.name.length === 0) {
            nameError = "Pole nazwa nie może być puste";
        }
        if (this.state.name.length > 100) {
            nameError = "Nazwa przekracza 100 znaków";
        }
        if (this.state.description.length === 0) {
            descriptionError = "Pole opis nie może być puste";
        }
        if (this.state.description.length > 500) {
            descriptionError = "Opis przekracza 500 znaków";
        }

        if (nameError || descriptionError) {
            this.setState({ nameError, descriptionError });
            return false;
        }


        return true;
    }


    handleEditorChange(content, editor) {
        if (content) {
            this.setState({ editorValue: content.level.content });
        }

    }

    changeOrientationToPortrait(e) {
        this.setState({
            isPortrait: true
        })

    }
    changeOrientationToLandscape(e) {
        this.setState({
            isPortrait: false
        })
    }

    getPreviewPattern() {
        var isportrait = '1'
        if (this.state.isPortrait === true)
            isportrait = '1';
        else
            isportrait = '0';

        let data = {
            Name: this.state.name,
            PatternDocument: this.state.editorValue,
            IsPortrait: isportrait,
            MarginTop: this.state.marginTop,
            MarginBottom: this.state.marginBottom,
            MarginLeft: this.state.marginLeft,
            MarginRight: this.state.marginRight,
            IsEndOfDocumentVisible: this.state.isEndOfDocumentVisible
        }
        PatternService.getInstance(this.props.apiurl, this.props.apiAuthHeader).getPreviewPattern(data).then(response => {
            this.setState({
                base64EncodedPDF: response.data,
            })
            this.printDocument();

        })
            .catch(error => {
                console.log(error);
            });
    }

    restoreMargins() {
        this.setState({
            marginBottom: 30,
            marginTop: 30,
            marginLeft: 30,
            marginRight: 30
        })
    }
    onChangeIsEndOfDocumentVisible() {
        this.setState({
            isEndOfDocumentVisible: !this.state.isEndOfDocumentVisible
        })
    }
    handleReturnModalAction() {

        //window.location.href = "/wzorce";
        this.props.navigation("/wzorce/lista");
    }

    async handleSaveModalAction() {

        const isValid = this.validate();
        if (isValid) {
            //sessionStorage.setItem('isPatternCreated', true);

            var isportrait = '1'
            if (this.state.isPortrait === true)
                isportrait = '1'
            else
                isportrait = '0'

            let data = new Pattern(
                this.state.name,
                this.state.Typ_Wzorca,
                this.state.description,
                this.state.Id_Systemu,
                this.state.editorValue,
                isportrait,
                this.state.marginTop,
                this.state.marginBottom,
                this.state.marginLeft,
                this.state.marginRight,
                this.state.isEndOfDocumentVisible
            )
            await PatternService.getInstance(this.props.apiurl, this.props.apiAuthHeader).addPattern(data.Name, data.Typ_Wzorca, data.Description, data.Id_Systemu, data.PatternDocument, data.isPortrait, data.MarginTop, data.MarginBottom, data.MarginLeft, data.MarginRight, data.isEndOfDocumentVisible);
            this.props.navigation("/wzorce/lista");
        }
    }

    async printDocument() {
        const firstPath = "/" + window.location.pathname.split('/')[1];
        let pdfWindow = await window.open(firstPath + "/wzorce/lista/wczytywanie")
        setTimeout(() => {
            pdfWindow.document.write("<html<head><title>" + this.state.name + "</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
            pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(this.state.base64EncodedPDF) + "#toolbar=0&navpanes=0&scrollbar=0'></embed></body></html>");
            pdfWindow.history.pushState({}, "", firstPath + "/wzorce/lista");
        }, 1);

    }

    render() {
        if (!this.state.permission) {
            return (
                <div>
                    Brak dostępu
                </div>
            )
        }
        else {
            return (
                <Box display={'flex'} flexDirection={'column'} >
                    <PatternForm
                        handleSubmit={this.handleSubmit}
                        typWzorca={this.state.Typ_Wzorca} handleTypWzorcaChange={this.handleTypWzorcaChange}
                        marginTop={this.state.marginTop} handleMarginTopChange={this.handleMarginTopChange}
                        marginBottom={this.state.marginBottom} handleMarginBottomChange={this.handleMarginBottomChange}
                        marginLeft={this.state.marginLeft} handleMarginLeftChange={this.handleMarginLeftChange}
                        marginRight={this.state.marginRight} handleMarginRightChange={this.handleMarginRightChange}
                        isPortrait={this.state.isPortrait} changeOrientationToPortrait={this.changeOrientationToPortrait} changeOrientationToLandscape={this.changeOrientationToLandscape}
                        restoreMargins={this.restoreMargins} getPreviewPattern={this.getPreviewPattern}
                        name={this.state.name} nameError={this.state.nameError} handleNameChange={this.handleNameChange}
                        description={this.state.description} descriptionError={this.state.descriptionError} handleDescriptionChange={this.handleDescriptionChange}
                        Id_Systemu={this.state.Id_Systemu} handleId_SystemuChange={this.handleId_SystemuChange}
                        onSaveClick={this.handleShowSaveModal}
                        isEndOfDocumentVisible={this.state.isEndOfDocumentVisible}
                        onChangeIsEndOfDocumentVisible={this.onChangeIsEndOfDocumentVisible}
                    ></PatternForm>
                    <Box>
                        {this.state.isMacrosLoaded ? <TextEditor
                            patternMacros={this.state.patternMacros}
                            editorValue={this.state.editorValue}
                            updateFunction={this.handleEditorChange} />
                            : <Box style={{ display: "flex", justifyContent: "center" }} >
                                <Box className="spinner-border" role="status">
                                    <Spinner />
                                </Box>
                            </Box>}
                    </Box>
                    <Box mt={2} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                        <Box>
                            <Button sx={{ textTransform: 'none' }} onClick={() => this.handleReturnModalAction()} variant='text' startIcon={<ArrowBackIosIcon />}>Powrót</Button>
                        </Box>
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-around'}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={this.state.isEndOfDocumentVisible} onChange={() => this.onChangeIsEndOfDocumentVisible()} id="isEndOfDocumentVisible" />} label={'Dodaj notkę "Koniec wydruku" na końcu dokumentu'} />
                            </FormGroup>
                            <Button sx={{ textTransform: 'none', ml: 2, mr: 2 }} variant='outlined' onClick={this.getPreviewPattern}>Podgląd wydruku</Button>
                            <Button sx={{ textTransform: 'none' }} variant='contained' onClick={this.handleShowSaveModal}>Zapisz</Button>
                        </Box>

                    </Box>
                    <ActionModal handleCloseModal={this.handleCloseSaveModal} isShowed={this.state.showSaveModal} title="Czy na pewno chcesz zapisać" description="Po zapisaniu zostaniesz przekierowany do listy wzorców" bottomButton="Anuluj" topButton="Zapisz" onClickButton={this.handleSaveModalAction}></ActionModal>

                </Box>
            )
        }
    }

}

export default PatternCreator;
