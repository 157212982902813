import { Autocomplete, Button, ButtonGroup, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import DatePicker from '@mui/lab/DatePicker';
import { DataFormatter } from '../services/dataFormatter';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { apiService } from '../services/api.service';
import { getIdPrac } from '../redux/idPracFetch';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { LeftArrow, RightArrow } from '../styles/icons';


const miesiace = DataFormatter.getMonth();

function ToolbarKalendarz(props) {
    const activeUmowa = getIdPrac();

    const dates = new Date(props.selectedDate);
    const monthNumber = dates.getMonth() + 1;
    const [miesiac, setMiesiac] = useState(monthNumber.toString());
    const [options, setOptions] = useState();

    const checkActiveLogin = JSON.parse(localStorage.getItem(localStorage.getItem('appPrefix') + 'LoginResponse'));

    useEffect(() => {
        getData();
        setOptions([...props.listaPracownikow]);
    }, [props.listaPracownikow]);

    const getData = () => {
        if (props.events.length === 0) {
            apiService.getMojeNieobecnosci(activeUmowa).then(res => props.fillEvents(res.data));
        }
    }

    // useEffect(() => {
    //     if (props.events.length === 0 && nieobecnosci !== null) {
    //         initialFillEvents(props);
    //     }
    // }, [nieobecnosci]);

    // const initialFillEvents = () => {
    // const initialEvents = await apiService.getNieobecnosci({ idPrac: idPrac.idPrac, rok: new Date().toISOString().substring(0, 4) || props.rok, kodRodzajuNieobecnosci: '' || props.kodRodzajuNieobecnosci });
    //fillEvents(initialEvents.data)
    //     fillEvents(nieobecnosci)
    // };

    const handleNieobecnosci = (idPrac) => {
        let data = {
            idPrac: activeUmowa,
            idPracPracownik: idPrac
        }
        if (idPrac === null) {
            data.idPracPracownik = activeUmowa  //gdy resetujemy input to chcemy zaczytać własne nieobecnosci
        }
        apiService.getNieobecnosciPracownikow(data).then(res => (props.fillEvents(res.data)));

    };

    // const handleRodzajNieobecnosciChange = (event, props) => {
    //     props.setKodRodzajuNieobecnosci(event.target.value);
    //     handleNieobecnosci(props);
    // };

    const handleMonthChange = (event, props) => {
        setMiesiac(event.target.value);
        props.setSelectedDate(new Date(props.selectedDate).setMonth(event.target.value - 1));

    };

    const handlePersonChange = (event, newValue) => {
        let data = Object.assign({})
        if (newValue === null) {
            data = null;
            props.setSelectedPerson(data);
            handleNieobecnosci(data);

        } else {
            data.idKadr = newValue.idKadr;
            data.imie = newValue.imie;
            data.nazwisko = newValue.nazwisko;
            data.idPrac = newValue.idPrac;

            props.setSelectedPerson(data);
            handleNieobecnosci(newValue.idPrac);
        }
    };

    const handleYearChange = (newValue) => {
        props.setRok(new Date(newValue).getFullYear().toString());
        props.setSelectedDate(new Date(newValue).getFullYear().toString());
    };

    return (
        <>
            <Box mb={1} mr={3} width={'100%'}>
                <Grid container spacing={1} justifyContent={'space-between'} mt={1}>
                    {checkActiveLogin.isZatwierdzanieWnioskow === 1 &&
                        <Grid item xs={5} >
                            {options &&
                                <Autocomplete
                                    id='listaPracownikow'
                                    size='small'
                                    name='lista'
                                    options={options}
                                    value={props.selectedPerson || null}
                                    getOptionLabel={(option) => {
                                        return (`${option.imie} ${option.nazwisko}  ${option.idPrac}`)
                                    }}
                                    onChange={(event, newValue) => {
                                        handlePersonChange(event, newValue)
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label='Pracownik' />
                                    )}
                                />
                            }
                        </Grid>
                    }

                    {/* {props.rodzajeNieobecnosci &&
                        <Grid item xs={3}>
                            <FormControl fullWidth size='small'>
                                <InputLabel id='rodzajNieob'>Rodzaj Nieobecności</InputLabel>
                                <Select
                                    defaultValue=''
                                    labelId='rodzajNieob'
                                    id='rodzajSelect'
                                    multiple
                                    value={props.kodRodzajuNieobecnosci || []}
                                    label='Rodzaj Nieobecności'
                                    onChange={(event) => handleRodzajNieobecnosciChange(event, props)}
                                >
                                    {props.rodzajeNieobecnosci.map((item) => {
                                        return (
                                            <MenuItem key={item.kod} value={item.kod}>{item.nazwa}</MenuItem>
                                        )
                                    })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>} */}
                    <Grid item xs={3}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id='miesiac'>Miesiąc</InputLabel>
                            <Select
                                error={props.miesiac === null}
                                required
                                defaultValue=''
                                labelId='miesiac'
                                id='miesiacSelect'
                                value={miesiac || props.date}
                                label='Miesiąc'
                                onChange={(event) => handleMonthChange(event, props)}
                            >
                                {miesiace.map((item, index) => (
                                    <MenuItem key={index} value={item.id}>{item.name}</MenuItem>

                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack spacing={3}>
                                <DatePicker
                                    views={['year']}
                                    size='small'
                                    label='Rok'
                                    value={dates.getFullYear().toString() || props.rok}
                                    onChange={(newValue) => handleYearChange(newValue)}
                                    renderInput={(params) => <TextField sx={{ svg: { color: '#c2c9db' } }} size='small' {...params} />}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item alignContent={'center'} container xs={1}>
                        <Grid item display={'flex'} justifyContent={'end'} md={6}>
                            <IconButton onClick={() => {
                                props.onNavigate('PREV');
                                props.setRok(new Date(dates).getFullYear().toString());
                            }} ><LeftArrow /></IconButton>
                        </Grid>
                        <Grid item display={'flex'} justifyContent={'end'} md={6}>
                            <IconButton onClick={() => {
                                props.onNavigate('NEXT');
                                props.setRok(new Date(dates).getFullYear().toString());
                            }} ><RightArrow /></IconButton>
                        </Grid>


                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default ToolbarKalendarz;