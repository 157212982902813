import React, { Component } from 'react';

class LoadingSpinner extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }

    }




    render() {
        return (

            <div>
            <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

            </div>


        )
    }
}

export default LoadingSpinner;