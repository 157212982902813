import { Box, Card, CardContent, Grid, ThemeProvider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Calendar, Application, RainbowThemeContainer } from 'react-rainbow-components';
import { FormControl } from '@mui/material';
import '../styles/nowyWniosek.css'
import { pl } from 'date-fns/locale';
import { CalendarPicker } from '@mui/lab';
import ZlozWniosekCard from '../modules/cards/ZlozWniosekCard';
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../redux/actionCreators";
import DostepneUrlopyProgressBars from '../modules/DostepneUrlopyProgressBars';
import DostepneNadgodzinyProgressBar from '../modules/DostepneNadgodzinyProgressBar';
import Spinner from '../components/Spinner';
import { getIdPrac } from '../redux/idPracFetch';
import PopupAlert from '../components/PopupAlert';
import { style } from '@mui/system';
import { LeftArrow, RightArrow } from '../styles/icons';
import BackArrow from '../components/BackArrow';
import { useTheme } from '@mui/styles';

function NowyWniosek() {
    const theme = useTheme();

    const [dataRozpoczeciaKalendarza, setDataRozpoczeciaKalendarza] = useState(null);
    const [dataZakonczeniaKalendarza, setDataZakonczeniaKalendarza] = useState(null);
    const [dateErrors, setDateErrors] = useState({
        dataOd: false,
        dataDo: false
    })
    const [dateRange, setDateRange] = useState();
    const [openPopup, setOpenPopup] = useState(false);
    const activeUmowa = getIdPrac();
    const dostepneUrlopy = useSelector((state) => state.listaDostepnychUrlopow);
    const dostepneNadgodziny = useSelector((state) => state.dostepneNadgodziny);
    const rodzajeNieobecnosci = useSelector((state) => state.rodzajeNieobecnosci);
    const listaZastepstw = useSelector((state) => state.listaZastepstw);
    const przelozeni = useSelector((state) => state.listaPrzelozeni);
    const dispatch = useDispatch();
    const { addRodzajeNieobecnosci, addListaZastepstw, addListaMoichWnioskow, addPrzelozeni } = bindActionCreators(actionCreators, dispatch);


    useEffect(() => {
        getDane();
        setDates();
    }, []);

    const setDates = () => {
        let date = new Date();
        date.setHours(0, 0, 0, 0);
        setDataRozpoczeciaKalendarza(date);
        setDataZakonczeniaKalendarza(date);
    }
    const getDane = () => {
        // DO USTALENIA; TO DO
        // if (dostepneUrlopy === null) {
        //     addListaDostepnychUrlopow({ idPrac: activeUmowa });
        // }
        // if (dostepneNadgodziny === null) {
        //     addDostepneNadgodziny({ idPrac: activeUmowa });
        // }
        if (rodzajeNieobecnosci == null) {
            addRodzajeNieobecnosci();
        }
        if (listaZastepstw == null) {
            addListaZastepstw();
        }
        if (!przelozeni) {
            addPrzelozeni(activeUmowa)
        }
    }

    const convertDateToString = (date) => {
        let dateString = (new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()).slice(0, -8); //slice wywala timezone, sekundy i milisekundy
        return dateString;
    }

    const handleDataRozpoczeciaKalendarza = (date) => {

        //date.setHours(0, 0, 0, 0);
        document.getElementById("datetime-rozpoczecie").value = convertDateToString(date);
        setDataRozpoczeciaKalendarza(date);
        setDateErrors({ ...dateErrors, dataOd: false })
    }

    const handleDataZakonczeniaKalendarza = (date) => {

        //date.setHours(0, 0, 0, 0);
        document.getElementById("datetime-zakonczenie").value = convertDateToString(date);
        setDataZakonczeniaKalendarza(date);
        setDateErrors({ ...dateErrors, dataDo: false })
    }

    const handleDateRange = (range) => {
        setDateRange(range);
        document.getElementById("datetime-rozpoczecie").value = convertDateToString(range[0]);
        if (range[1]) {
            range[1].setHours(0, 0, 0, 0);
            document.getElementById("datetime-zakonczenie").value = convertDateToString(range[1]);
        }
        else {
            document.getElementById("datetime-zakonczenie").value = null

        }

    }
    const calendarTheme = {
        rainbow: {
            palette: {
                brand: theme.palette.primary.main,
            },
        },
    };
    return (
        <>
            <Box mb={2} display={'flex'} alignItems={'center'}><BackArrow /><Typography variant="h1" >Nowy wniosek</Typography></Box>
            {/*dostepneUrlopy && dostepneNadgodziny &&*/ rodzajeNieobecnosci && listaZastepstw ?
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={pl}>
                    <FormControl sx={{ width: "100%" }} >
                        <Grid container spacing={0} columnSpacing={3}>
                            <Grid container item columnSpacing={0} lg={8} md={12} justifyContent="center" mb={2}>
                                <Grid item lg={12}>
                                    <Card sx={{ position: 'static', padding: "35px", pr: "25px", pl: '25px' }}>
                                        {/* <Application theme={calendarTheme} className=" rainbow-align-content_center"> */}
                                        <RainbowThemeContainer theme={calendarTheme} >
                                            <Calendar
                                                locale='pl-PL'
                                                minDate={new Date()}
                                                id="calendar-11"
                                                variant="double"
                                                selectionType="range"
                                                value={dateRange}
                                                onChange={value => handleDateRange(value)}
                                            />
                                        </RainbowThemeContainer>

                                        {/* </Application> */}

                                    </Card>
                                </Grid>
                                {/* <Grid item lg={8} xl={6}>
                                    <Card sx={{ minWidth: "340px", position: 'static' }}>
                                        <CardContent>

                                            <CalendarPicker
                                                components={{
                                                    LeftArrowIcon: LeftArrow,
                                                    RightArrowIcon: RightArrow,

                                                }}
                                                leftArrowButtonText='Poprzedni miesiąc' rightArrowButtonText='Następny miesiąc' date={dataRozpoczeciaKalendarza} onChange={(newDate) => handleDataRozpoczeciaKalendarza(newDate)} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item lg={8} xl={6}>
                                    <Card style={{ minWidth: "340px", position: 'static' }}>
                                        <CardContent>
                                            <CalendarPicker
                                                components={{
                                                    LeftArrowIcon: LeftArrow,
                                                    RightArrowIcon: RightArrow,

                                                }}
                                                leftArrowButtonText='Poprzedni miesiąc' rightArrowButtonText='Następny miesiąc' date={dataZakonczeniaKalendarza} onChange={(newDate) => handleDataZakonczeniaKalendarza(newDate)} />
                                        </CardContent>
                                    </Card>
                                </Grid> */}
                                {/* DO USTALENIA; TO DO */}
                                {/* <Grid container rowSpacing={1} justifyContent="space-between" style={{ marginTop: "30px", marginBottom: "20px" }}>
                                    <DostepneUrlopyProgressBars limit={3} dostepneUrlopy={dostepneUrlopy} />
                                </Grid>
                                <Grid container rowSpacing={1} justifyContent="space-between" style={{ marginTop: "30px", marginBottom: "20px" }}>
                                    <DostepneNadgodzinyProgressBar dostepneNadgodziny={dostepneNadgodziny}></DostepneNadgodzinyProgressBar>
                                </Grid> */}
                            </Grid>
                            <ZlozWniosekCard
                                dateErrors={dateErrors} setDateErrors={setDateErrors}
                                dateRange={dateRange} setDateRange={setDateRange}
                                convertDateToString={convertDateToString} addListaMoichWnioskow={addListaMoichWnioskow}
                                rodzajeNieobecnosci={rodzajeNieobecnosci} listaZastepstw={listaZastepstw}
                                openPopup={openPopup} setOpenPopup={setOpenPopup} przelozeni={przelozeni}>
                            </ZlozWniosekCard>
                        </Grid>
                    </FormControl>
                </LocalizationProvider>
                : <Spinner />}
            <PopupAlert severity="success" text="Wniosek został wysłany!" open={openPopup} setOpen={setOpenPopup} />

        </ >
    );
}

export default NowyWniosek;