import { Card, CardContent, Grid, Typography, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import '../../styles/kontrolki.css'
import ChangeWniosekStatusButton from "../../components/buttons/ChangeWniosekStatusButton.js";
import SpinnerWithoutText from "../../components/SpinnerWithoutText.js";
import AccordionHistWniosku from "../accordions/AccordionHistWniosku.js";
import moment from "moment";
import { ReactComponent as Import } from "../../assets/icons/Import.svg";
import { Converter } from "../../submodules/template/services/Converter";

function SelectedZlozonyWniosekCard(props) {

    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {

    }, [props.selectedWniosek])


    return (
        <>
            <Card>
                <CardContent>
                    <Box m={2}>
                        <Box mt={2}>
                            <Grid container rowSpacing={2} justifyContent="space-between" alignItems="center" mb={3}>
                                <Grid item xs={10}>
                                    <Typography variant='h1'>Szczegóły</Typography>
                                </Grid>
                                {props.selectedWniosek.typWniosku === 0 &&
                                    <IconButton sx={{ mr: -2 }} style={{ padding: "2px" }}>
                                        <Import style={{ height: "24px", width: "24px" }} />
                                    </IconButton>
                                }
                            </Grid>
                            <Grid container rowSpacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant='h4' fontWeight="bold">Początek urlopu:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='h4'>{moment(props.selectedWniosek.dataOd).format('DD/MM/YYYY')}
                                        {props.selectedWniosek.hhmmOd !== '00:00' ? props.selectedWniosek.hhmmOd : null}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='h4' fontWeight="bold">Koniec urlopu:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='h4'>{moment(props.selectedWniosek.dataDo).format('DD/MM/YYYY')}
                                        {props.selectedWniosek.hhmmDo !== '00:00' ? props.selectedWniosek.hhmmDo : null}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='h4' fontWeight="bold">Typ urlopu:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='h4'>{props.selectedWniosek.nazwaRodzajuNieobecnosci}</Typography>
                                </Grid>
                                {/* <Grid item xs={6}>
                            <Typography fontWeight="bold">Pozostały urlop:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography >{props.urlop.dostepnyUrlop} dni | {props.urlop.maxLiczbaDniUrlopu} dni</Typography>
                        </Grid> */}
                                <Grid item xs={6}>
                                    <Typography variant='h4' fontWeight="bold">Zastępca:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='h4'>{props.selectedWniosek.zastepstwo ? Converter.titleCase(props.selectedWniosek.zastepstwo) : "-"}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='h4' fontWeight="bold">Komentarz:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='h4'>{props.selectedWniosek.opis ? props.selectedWniosek.opis : "-"}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='h4' fontWeight="bold">Status:</Typography>
                                </Grid>
                                <Grid container item xs={6}>
                                    <Box display="flex" alignItems={'center'} sx={{ ml: -2.5 }}>
                                        <Box id={props.getKontrolkaStatusu(props.selectedWniosek.status)}></Box>
                                        <Typography variant='h4'>{props.nazwaStatusu[props.selectedWniosek.status]}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
            <AccordionHistWniosku idWniosek={props.selectedWniosek.idWniosek} idPrac={props.selectedWniosek.idPrac} />
            <Card>
                <Grid container item xs={12} rowSpacing={2} p={4} >
                    {!isLoading ?
                        <ChangeWniosekStatusButton
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            refreshCurrentWniosek={() => props.refreshCurrentWniosek()}
                            activeUmowa={props.activeUmowa}
                            idWniosek={props.selectedWniosek.idWniosek}
                            status={props.selectedWniosek.status}
                            succesPopup={props.succesPopup}
                            setSuccessPopup={props.setSuccessPopup}
                            errorPopup={props.errorPopup}
                            setErrorPopup={props.setErrorPopup}>
                        </ChangeWniosekStatusButton>
                        : <Box style={{ margin: 'auto' }}>
                            <SpinnerWithoutText />
                        </Box>}
                </Grid>
            </Card>

        </>
    )
}

export default SelectedZlozonyWniosekCard;