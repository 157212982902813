import React, { useState, useEffect } from 'react';
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary, IconButton, Table, TableRow, TableBody, TableContainer, TableCell } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/system';
import { withStyles } from '@mui/styles';
import MojProfilTitleAccordion from '../MojProfilTitlelAccordion';
import ModalZgloszenie from '../modals/ModalZgloszenie';
import Spinner from '../../components/Spinner';
import moment from 'moment';
import { AccountDetails, ImportIcon } from '../../styles/icons';
import ModalZatrudnienieZawartosc from '../modals/ModalZatrudnienieZawartosc';
import ModalZatrudnienie from '../modals/ModalZatrudnienie';
import '../../styles/printModalZatrudnienie.css'
import { useReactToPrint } from 'react-to-print';
import { CustomHooksService } from '../../services/customHooks.serivce';

const Accordion = withStyles({
    root: {
        "&$expanded": {
            margin: "auto"
        }
    },
    expanded: {}
})(MuiAccordion);

export default function SciezkaKariery({ handlers: { handleChangeHideDetails, expanded }, histZatrudnienia }) {

    const [showZgloszenie, setShowZgloszenie] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const rowStyle = { borderBottomColor: 'neutral.100' }
    const sciezkaKarieryRef = React.useRef();
    const panel = 'panel1';
    const [open, setOpen] = useState(false);
    const [itemHistZatrud, setItemHistZatrud] = useState([]);
    const [itemHistZatrudDruk, setItemHistZatrudDruk] = useState([]);

    const handlePrint = useReactToPrint({
        content: () => sciezkaKarieryRef.current,
    });

    CustomHooksService.useDidMountEffect(() => {    //ten hook omija wykonanie pod initial render, ponieważ nie chcemy, żeby na starcie się odpalało drukowanie
        handlePrint()   //drukowanie tutaj, ponieważ itemHistZatrud zmienia sie asynchronicznie, a potrzebujemy mieć wartość poprawną
    }, [itemHistZatrudDruk])

    const handleZatrudnienieRow = (item) => {
        setOpen(true);
        setItemHistZatrud(item);
    }

    const handleExpandAccordion = () => {
        setShowZgloszenie(!showZgloszenie);
    }

    useEffect(() => {
        if (expanded !== panel) {
            setShowZgloszenie(false);
        }
    }, [expanded])

    const handleModalZgloszenie = (e) => {
        e.stopPropagation();
        setOpenModal(true);
    }

    return (
        <>
            <Accordion sx={{ borderBottom: 1, borderBottomColor: 'neutral.200' }} expanded={expanded === panel} onChange={handleChangeHideDetails(panel)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${panel}a-content`}
                    id={`${panel}a-header`}
                    onClick={handleExpandAccordion}
                >
                    <MojProfilTitleAccordion
                        title="Ścieżka kariery"
                        showZgloszenie={showZgloszenie}
                        handleModalZgloszenie={handleModalZgloszenie} />
                </AccordionSummary>
                {histZatrudnienia ?
                    <Box px={2}>
                        <AccordionDetails>
                            <TableContainer sx={{ width: "100%" }} >
                                <Table aria-label="simple table">
                                    <TableBody>
                                        {histZatrudnienia.map((item, index) => (
                                            <TableRow key={index} sx={{ 'td, th': rowStyle, '&:last-child td, &:last-child th': { border: 0 } }} >
                                                <TableCell><Box ml={-2} style={{ whiteSpace: 'nowrap' }}>{`${moment(item.dataOd).format('DD.MM.YYYY')}`} - {`${moment(item.dataDo).format('DD.MM.YYYY')}`}</Box></TableCell>
                                                <TableCell>{item.stanowisko}</TableCell>
                                                <TableCell align='right' style={{ whiteSpace: 'nowrap' }}><Box mr={-2}>
                                                    <IconButton onClick={() => handleZatrudnienieRow(item)}><AccountDetails /></IconButton>
                                                    <IconButton onClick={() => {
                                                        setItemHistZatrudDruk(Object.assign({}, item));
                                                    }}><ImportIcon /></IconButton>
                                                    {/* <ReactToPrint
                                                        trigger={() => <IconButton><ImportIcon /></IconButton>}
                                                        content={() => {
                                                            setItemHistZatrud(item)
                                                            sleep(500);
                                                            return sciezkaKarieryRef.current
                                                        }}
                                                    /> */}
                                                </Box>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Box>
                    : <Spinner />}
            </Accordion>
            <div ref={sciezkaKarieryRef} className='print-source' >
                <ModalZatrudnienieZawartosc itemHistZatrud={itemHistZatrudDruk} />
            </div>
            {open && <ModalZatrudnienie open={open} setOpen={setOpen} itemHistZatrud={itemHistZatrud} />}
            {openModal && <ModalZgloszenie openModal={openModal} setOpenModal={setOpenModal} />}
        </>
    )
};