import React, { useContext, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, List, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StruktListItem from '../../components/StrukturaListItem';
import '../../styles/struktura.css';
import ExpandContext from '../../services/expandContext';


const AccordeonCardStruktura = ({ persons, expandAccordion, setExpandAccordion, id, currentPath }) => {
    const [refresh, setRefresh] = useState(false);
    const state = useContext(ExpandContext);


    //funkcja uniemozliwoa otwarcie kilku akordeonow na raz
    const handleExpand = (id) => (event, isExpanded) => {
        setExpandAccordion(isExpanded ? id : false);
        setRefresh(!refresh);
    };


    function arrayEquals(a, b) {    //porownuje tablice A do tablicy B składającej się z X ostatnich elementów (gdzie X to dlugosc tablicy A)
        /*Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&*/
        let temp = a.slice(-b.length)
        let isEqual = b.every((val, index) => val === temp[index]);
        return isEqual;
    }

    return (
        <>
            <Accordion sx={{ marginLeft: "10px !important" }} className="strukturaAccordion" expanded={expandAccordion ? expandAccordion === id || arrayEquals(state.expanded, currentPath) : false} onChange={handleExpand(id)}>
                <AccordionSummary sx={{ '&:hover': { backgroundColor: "rgba(0, 0, 0, 0.04)" } }}
                    className="strukturaAccordionSummary"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${id}-content`}
                >
                    <Typography variant='h3' /*sx={{ width: '100%', paddingBottom: "10px", paddingTop: "10px", paddingLeft: "5px", }}*/ fontWeight={'bold'}>Pracownicy</Typography>
                </AccordionSummary>
                <AccordionDetails className="strukturaAccordionSummary">
                    <List>
                        {persons.map((person, index) => {
                            return (
                                <StruktListItem key={index} person={person} />
                            )

                        })}
                    </List>
                </AccordionDetails>
            </Accordion>
        </>
    )
};

export default AccordeonCardStruktura;