import React from 'react';
import { Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ReactComponent as MarginTop } from '../icons/MarginTop.svg';
import { ReactComponent as MarginBottom } from '../icons/MarginBottom.svg';
import { ReactComponent as MarginRight } from '../icons/MarginRight.svg';
import { ReactComponent as MarginLeft } from '../icons/MarginLeft.svg';
import { ReactComponent as OrientationY } from '../icons/OrientationY.svg';
import { ReactComponent as OrientationX } from '../icons/OrientationX.svg';

import { withStyles } from '@mui/styles';
import './patternForm.css'

const CustomIconButton = withStyles({
    root: {
        borderRadius: 4,
        "&:hover, &.Mui-focusVisible": { color: '#3879F6' },
        "&.Mui-disabled": { border: 'solid 2px #4380f6', backgroundColor: '#4380f6' }
    }
})(props => <IconButton {...props} />);

function PatternForm(props) {


    return (
        <>
            {/* <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} >
                <Box flexGrow={1} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
                    &nbsp;
                </Box>
                <Box ml={4} mr={4} flexGrow={2}>
                    &nbsp;
                </Box>
                <Box flexGrow={1} display={'flex'} flexDirection={'column'} rowGap={2} justifyContent={'start'} sx={{ maxWidth: "400px" }}>
                    <Box display={'flex'} justifyContent={'space-evenly'}>
                        <Typography sx={{ maxWidth: "140px" }} ml={2} variant='h5' color={'darkMode.500'}>Marginesy:</Typography>
                        <Button variant='text' onClick={props.restoreMargins} sx={{ maxWidth: "140px", textTransform: 'none' }}><Typography variant='h5' color={'secondaryColors.500'}>Wyczyść marginesy</Typography></Button>

                    </Box>
                    <Box display={'flex'} justifyContent={'space-evenly'}>
                    </Box>
                </Box>
            </Box> */}


            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} >
                <Box flexGrow={1} display={'flex'} flexDirection={'column'} sx={{ marginTop: '10px' }}>
                    &nbsp;
                    <TextField error={props.nameError && true} helperText={props.nameError && `${props.nameError}`} value={props.name} onChange={props.handleNameChange} sx={{ marginBottom: "25px" }}
                        inputProps={{
                            style: { backgroundColor: 'white' }
                        }}
                        size='small' variant='outlined' label='Nazwa wzorca' />
                    <TextField disabled value={props.Id_Systemu} onChange={props.handleId_SystemuChange} type={'number'} sx={{ marginBottom: "25px", backgroundColor: 'white' }} size='small' variant='outlined' label='ID Systemu' />
                    <TextField onChange={props.handleTypWzorcaChange} value={props.typWzorca} type={'number'} sx={{ backgroundColor: 'white' }} size='small' variant='outlined' label='Typ wzorca' />

                </Box>
                <Box ml={4} mr={4} display={'flex'} flexGrow={2} flexDirection={'column'} sx={{ marginTop: '10px' }}>
                    &nbsp;
                    <TextField rows={6} fullWidth value={props.description} onChange={props.handleDescriptionChange}
                        sx={{
                            backgroundColor: 'white', "&  .MuiFormHelperText-root.Mui-error": {
                                backgroundColor: "#F0F3F7",
                                margin: 0,
                                paddingLeft: 2,
                                paddingTop: 1
                            }
                        }}

                        error={props.descriptionError && true} helperText={props.descriptionError && `${props.descriptionError}`} variant='outlined' label='Opis wzorca' multiline />
                </Box>
                <Box flexGrow={1} display={'flex'} flexDirection={'column'} justifyContent={'start'} sx={{ maxWidth: "400px" }}>
                    <Box display={'flex'} justifyContent={'space-evenly'} height={'30px'}>
                        <Box sx={{ width: '100%', maxWidth: "140px" }}>
                            <Typography variant='h5' color={'darkMode.500'}>Marginesy:</Typography>
                        </Box>
                        <Box sx={{ width: '100%', maxWidth: "140px" }}>
                            <Button variant='text' onClick={props.restoreMargins} sx={{ float: 'right', padding: '0px', textTransform: 'none' }}><Typography variant='h5' color={'secondaryColors.500'}>Wyczyść marginesy</Typography></Button>

                        </Box>
                    </Box>
                    {/* <Box display={'flex'} flexDirection={'row'} justifyContent={'space-around'}>
                        <Typography ml={2} variant='h5' color={'darkMode.500'}>Marginesy:</Typography>
                        <Button variant='text' onClick={props.restoreMargins} sx={{ textTransform: 'none' }}><Typography variant='h5' color={'secondaryColors.500'}>Wyczyść marginesy</Typography></Button>
                    </Box> */}
                    <Box display={'flex'} justifyContent={'space-evenly'} marginBottom={'20px'}>
                        <TextField value={props.marginTop} onChange={props.handleMarginTopChange} size='small' variant='outlined' sx={{ maxWidth: "140px", backgroundColor: 'white', color: 'secondaryColors.500', borderColor: 'secondaryColors.500', textTransform: 'none' }} label={'Górny'} InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MarginTop />
                                </InputAdornment>
                            )
                        }} />
                        <TextField value={props.marginBottom} onChange={props.handleMarginBottomChange} size='small' variant='outlined' sx={{ maxWidth: "140px", backgroundColor: 'white', color: 'secondaryColors.500', borderColor: 'secondaryColors.500', textTransform: 'none' }} label={'Dolny'} InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MarginBottom />
                                </InputAdornment>
                            )
                        }} />
                    </Box>
                    <Box display={'flex'} justifyContent={'space-evenly'}>
                        <TextField value={props.marginLeft} onChange={props.handleMarginLeftChange} size='small' variant='outlined' sx={{ maxWidth: "140px", backgroundColor: 'white', color: 'secondaryColors.500', borderColor: 'secondaryColors.500', textTransform: 'none' }} label={'Lewy'} InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MarginLeft />
                                </InputAdornment>
                            )
                        }} />
                        <TextField value={props.marginRight} onChange={props.handleMarginRightChange} size='small' variant='outlined' sx={{ maxWidth: "140px", backgroundColor: 'white', color: 'secondaryColors.500', borderColor: 'secondaryColors.500', textTransform: 'none' }} label={'Prawy'} InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MarginRight />
                                </InputAdornment>
                            )
                        }} />
                    </Box>
                </Box>
            </Box>
            <Box paddingBottom={2} display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'} height={'40px'}>
                <Typography variant='h5' mr={1}>Orientacja dokumentu:</Typography>
                <CustomIconButton disabled={!props.isPortrait && true} aria-label="orientationX" onClick={(e) => props.changeOrientationToLandscape(e.currentTarget.checked)}><OrientationX className={!props.isPortrait && 'rectanglesvg'} aria-checked={props.isPortrait} /></CustomIconButton>
                <CustomIconButton disabled={props.isPortrait && true} aria-label="orientationY" onClick={(e) => props.changeOrientationToPortrait(e.currentTarget.checked)} ><OrientationY className={props.isPortrait && 'rectanglesvg'} aria-checked={!props.isPortrait} /></CustomIconButton>
            </Box>
        </>
    )
}

export default PatternForm;