const loginReducer = (state = { token: null }, action) => {
    switch (action.type) {
        case "add":
            return action.payload 
        case "remove":
            return state 
        default:
            return state
    }
}

export default loginReducer 