import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { actionCreators } from "../../redux/actionCreators.js";
import { bindActionCreators } from "redux";
import { apiService } from '../../services/api.service'
import { Button } from '@mui/material'
import { getIdPrac } from '../../redux/idPracFetch.js';

function MojWniosekButtons(props) {
    const wnioski = useSelector((state) => state.listaMoichWnioskow);
    const activeUmowa = getIdPrac();
    const dispatch = useDispatch();
    const { addListaMoichWnioskow } = bindActionCreators(actionCreators, dispatch);

    useEffect(() => {
        props.refreshCurrentWniosek();
    }, [wnioski])

    const chooseSelectedWniosek = (id) => {
        let selectedWniosek = wnioski.map(item => item.idWniosek).indexOf(id)
        props.setSelectedWniosek(wnioski[selectedWniosek]);
    }

    const anulujWniosek = () => {
        let wniosek = { IdWniosek: props.idWniosek, Status: 5, IdPrac: activeUmowa, Przyczyna: "" } //Przyczyna DO USTALENIA; TO DO
        apiService.changeWniosekStatus(wniosek).then(() => {
            props.setStatus(true);
            props.setIsRemoved(true);
            addListaMoichWnioskow();
            props.setSuccessPopup(true);
            chooseSelectedWniosek(props.idWniosek);
        }, err => {
            console.log(err);
            props.setErrorPopup(true);
        })

    }

    const setButtons = (status) => {
        if (status === 5 || props.isRemoved) {
            return (
                <>
                    <Button
                        className='changeWniosekStatusButton'
                        style={{ textTransform: 'none' }}
                        disabled={true}
                        fullWidth
                        variant="outlined"
                        size="large" onClick={() => anulujWniosek()}>Anuluj wniosek</Button>
                </>
            )
        } else {
            return (
                <>
                    <Button
                        className='changeWniosekStatusButton'
                        style={{ textTransform: 'none' }}
                        disabled={false}
                        fullWidth
                        variant="outlined"
                        size="large" onClick={() => anulujWniosek()}>Anuluj wniosek</Button>
                </>
            )
        }
    }

    return (
        <>
            {setButtons(props.status)}
        </>

    )
}

export default MojWniosekButtons