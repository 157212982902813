import React, { useState, useEffect } from 'react';
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary, Table, TableBody, TableContainer } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/system';
import { withStyles } from '@mui/styles';
import '../../styles/printModalZatrudnienie.css'
import DaneOsoboweTableRow from '../DaneOsoboweTableRow';
import MojProfilTitleAccordion from '../MojProfilTitlelAccordion';
import ModalZgloszenie from '../modals/ModalZgloszenie';

const Accordion = withStyles({
    root: {
        "&$expanded": {
            margin: "auto"
        }
    },
    expanded: {}
})(MuiAccordion);

export default function AdresKorespondencyjny({ handlers: { dane, handleChangeHideDetails, expanded } }) {

    const [openModal, setOpenModal] = useState(false);
    const [showZgloszenie, setShowZgloszenie] = useState(false);
    const panel = 'panel5';

    const handleExpandAccordion = () => {
        setShowZgloszenie(!showZgloszenie)
    }

    useEffect(() => {
        if (expanded !== panel) {
            setShowZgloszenie(false);
        }
    }, [expanded])

    const handleModalZgloszenie = (e) => {
        e.stopPropagation();
        setOpenModal(true);
    }

    return (
        <>
            {(dane.ulicaKorespondencyjny || dane.domKorespondencyjny || dane.lokalKorespondencyjny || dane.kodKorespondencyjny || dane.miastoKorespondencyjny) !== "" ?
                <Accordion sx={{ borderBottom: 1, borderBottomColor: 'neutral.200' }} expanded={expanded === panel} onChange={handleChangeHideDetails(panel)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${panel}a-content`}
                        id={`${panel}a-header`}
                        onClick={handleExpandAccordion}
                    >
                        <MojProfilTitleAccordion
                            title="Adres korespondencyjny"
                            showZgloszenie={showZgloszenie}
                            handleModalZgloszenie={handleModalZgloszenie} />
                    </AccordionSummary>

                    <Box px={2}>
                        <AccordionDetails>
                            <TableContainer sx={{ width: "100%" }} >
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <DaneOsoboweTableRow rodzajDane="Ulica i numer domu"
                                            uzytkownikDane={`${dane.ulicaKorespondencyjny} ${dane.domKorespondencyjny}${(dane.lokalKorespondencyjny) ? ('/' + (dane.lokalKorespondencyjny)) : ""}`} />
                                        <DaneOsoboweTableRow rodzajDane="Kod pocztowy i miasto"
                                            uzytkownikDane={`${dane.kodKorespondencyjny} ${dane.miastoKorespondencyjny}`} />
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Box>
                </Accordion> : null}
            {openModal && <ModalZgloszenie openModal={openModal} setOpenModal={setOpenModal} />}
        </>
    )
};