import { Table, TableBody, Typography, TableContainer } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import '../../styles/modal.css';
import TableRowZatrudnienie from '../../components/TableRowZatrudnienie';
import moment from 'moment';

function ModalZatrudnienieZawartosc({ itemHistZatrud }) {
    const [reload, setReload] = useState(false);
    useEffect(() => {
        setReload(!reload);
    }, [itemHistZatrud])
    return (
        <>
            {itemHistZatrud && <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
                <Box>
                    <Typography sx={{ ml: 6 }} variant='h1'>Szczegóły</Typography>
                </Box>
                <TableContainer >
                    <Box sx={{ px: 6, py: 3 }}>
                        <Table sx={{ width: '100%' }} size='small' aria-label='szczegolyZatrudnienia-table'>

                            <TableBody>
                                <TableRowZatrudnienie
                                    title="Umowa"
                                    value={itemHistZatrud.umowa && itemHistZatrud.umowa}>
                                </TableRowZatrudnienie>
                                <TableRowZatrudnienie
                                    title="Od"
                                    value={`${moment(itemHistZatrud.dataOd).format('YYYY-MM-DD')}`}>
                                </TableRowZatrudnienie>
                                <TableRowZatrudnienie
                                    title="Do"
                                    value={`${moment(itemHistZatrud.dataDo).format('YYYY-MM-DD')}`}>
                                </TableRowZatrudnienie>
                                <TableRowZatrudnienie
                                    title="Miejsce"
                                    value={itemHistZatrud.miejsce}>
                                </TableRowZatrudnienie>
                                <TableRowZatrudnienie
                                    title="Wydział"
                                    value={itemHistZatrud.wydzial}>
                                </TableRowZatrudnienie>
                                <TableRowZatrudnienie
                                    title="Kategoria"
                                    value={itemHistZatrud.kategoria}>
                                </TableRowZatrudnienie>
                                <TableRowZatrudnienie
                                    title="Grupa"
                                    value={itemHistZatrud.grupa}>
                                </TableRowZatrudnienie>
                                <TableRowZatrudnienie
                                    title="Stanowisko"
                                    value={itemHistZatrud.stanowisko}>
                                </TableRowZatrudnienie>
                                <TableRowZatrudnienie
                                    border="0"
                                    title="Zwolniony z dniem"
                                    value={`${moment(itemHistZatrud.dataZwolnienia).format('YYYY-MM-DD')}`}>
                                </TableRowZatrudnienie>
                            </TableBody>
                        </Table>
                    </Box>
                </TableContainer>

            </Box>
            }
        </>



    );
}
export default ModalZatrudnienieZawartosc;