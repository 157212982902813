import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import InfoUzytkownikaCard from '../modules/cards/InfoUzytkownikaCard';
import DaneFinansoweUzytkownika from '../modules/cards/DaneFinansoweUzytkownika';
import { cacheService } from '../services/cache.service';
import TwojeBadaniaCard from '../modules/cards/TwojeBadaniaCard';
import TwojeDokumentyCard from '../modules/cards/TwojeDokumentyCard';
import '../styles/twojeKonto.css';
import '../styles/kontrolki.css';
import Spinner from '../components/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from '../redux/actionCreators';
import { bindActionCreators } from 'redux';
import { getIdPrac } from '../redux/idPracFetch';
import BackArrow from '../components/BackArrow';

function TwojeKonto() {
    const [foto, setFoto] = useState();
    const activeUmowa = getIdPrac();

    const danePracownika = useSelector((state) => state.danePracownika);
    const info = useSelector((state) => state.info);
    const badania = useSelector((state) => state.badania);
    const pozyczki = useSelector((state) => state.pozyczki);
    const wynagrodzenia = useSelector((state) => state.wynagrodzenia);
    const pity = useSelector((state) => state.pity);
    const zatrudnienie = useSelector((state) => state.zatrudnienie);
    const dispatch = useDispatch();
    const { addDanePracownika, addInfo, addBadania, addPozyczki, addWynagrodzenia, addPity, addZatrudnienie } = bindActionCreators(actionCreators, dispatch);

    useEffect(() => {
        getFoto();
    }, []);


    useEffect(() => {
        getData();
    }, [activeUmowa]);



    const getData = () => {
        if (danePracownika == null) {
            addDanePracownika();
        }
        if (info == null) {
            addInfo({ idPrac: activeUmowa });
        }
        if (badania == null) {
            addBadania({ idPrac: activeUmowa });
        }
        if (pozyczki == null) {
            addPozyczki({ idPrac: activeUmowa });
        }
        if (wynagrodzenia == null) {
            addWynagrodzenia({ idPrac: activeUmowa });
        }
        if (pity == null) {
            addPity({ idPrac: activeUmowa });
        }
        if (zatrudnienie == null) {
            addZatrudnienie({ idPrac: activeUmowa });
        }
    }
    const getFoto = () => {

        cacheService.getFotoPracownika().then(res => {
            setFoto(res);
        });

    };

    return (
        <>
            <Box mb={2} display={'flex'} alignItems={'center'}><BackArrow /><Typography variant="h1" >Mój profil</Typography></Box>
            {danePracownika !== null && foto !== undefined && zatrudnienie !== null && info !== null && badania !== null && pozyczki !== null && wynagrodzenia !== null && pity !== null && zatrudnienie !== null ?
                <Grid container justifyContent='center' spacing={5} p={2}>
                    <Grid item xs={8}>
                        <InfoUzytkownikaCard daneUzytkownika={danePracownika} foto={foto} zatrudnienie={info} histZatrudnienia={zatrudnienie} />
                        <TwojeDokumentyCard pity={pity} />
                    </Grid>
                    <Grid item xs={4}>
                        {pozyczki.length > 0 && wynagrodzenia.length > 0 ? <DaneFinansoweUzytkownika finanse={{ pozyczki, wynagrodzenia }} /> : null}
                        <TwojeBadaniaCard badania={badania} />
                    </Grid>
                </Grid>
                : <Spinner />}
        </>
    );
};

export default TwojeKonto;