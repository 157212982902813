import React, { useState } from 'react';
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PopupAlert from '../../components/PopupAlert';
import { withStyles } from '@mui/styles';

const Accordion = withStyles({
    root: {
        "&$expanded": {
            margin: "auto"
        }
    },
    expanded: {}
})(MuiAccordion);

export default function TwojeDokumentyAccordion({ pity, handleChangeHideDetails, expanded }) {

    const panel = 'panel1td';
    const rowStyle = { borderBottomColor: 'neutral.100' }
    const [light, setLight] = useState(true);

    const [popupOpen, setPopupOpen] = useState(false);
    const popupHandle = (xml) => {
        if (xml !== '') {
            setPopupOpen(true)
        }
    };

    return (
        <>

            <Accordion sx={{ borderBottom: 1, borderBottomColor: 'neutral.200' }}
                expanded={expanded === panel}
                onChange={handleChangeHideDetails(panel)}>
                <AccordionSummary
                    onClick={() => setLight(!light)}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${panel}-content`}
                    id={`${panel}-header`}
                >
                    <Grid container flexDirection={'column'}>
                        <Grid item >
                            <Typography
                                mt={2}
                                ml={2}
                                mb={1}
                                style={{ whiteSpace: 'nowrap' }}
                                variant='h3' fontWeight={'bold'}>Twoje dokumenty</Typography>
                        </Grid>
                        <Grid item >
                            {light ?
                                <>
                                    <Box
                                        pt={1}
                                        pb={1}
                                        display={'flex'}
                                        flexDirection={'row'}
                                        alignItems={'center'}>
                                        <div id='circle_green'></div>
                                        <Typography ml={-1} variant='h4'>Pity</Typography>
                                    </Box>
                                </>
                                : null}
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <Box px={2}>
                    <AccordionDetails>
                        <TableContainer>
                            <Table style={{ width: '100%' }} aria-label='pity-table'>
                                <TableHead>
                                    <Typography fontWeight={'bold'} mb={2} variant='h4'>Pity</Typography>
                                </TableHead>
                                <TableBody>
                                    {pity.sort(((a, b) => b.rok - a.rok)).map((row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ 'td, th': rowStyle, '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell>
                                                <Box ml={-2} mb={-2} mt={-1.5}>{row.nazwa}</ Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box mb={-2} mt={-1.5}>{row.rok}</Box>
                                            </TableCell>
                                            <TableCell align='right'>
                                                <Box mb={-2} mt={-1.5}>
                                                    {row.pitXml !== '' ?
                                                        <IconButton
                                                            onClick={() => popupHandle(row.pitXml)}
                                                            color='error'
                                                            title='Pobierz PIT'
                                                            download={row.rok + "_" + row.nazwa}
                                                            href={'data:application/pdf;base64,' + row.pitXml}
                                                        >
                                                            <PictureAsPdfRoundedIcon />
                                                        </IconButton> :
                                                        <IconButton style={{ color: 'gray', cursor: 'default' }}>
                                                            <PictureAsPdfRoundedIcon />
                                                        </IconButton>
                                                    }
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Box>
            </Accordion>
            <PopupAlert severity="success" text="PIT został pobrany!" open={popupOpen} setOpen={setPopupOpen} />
        </>
    )
}