export const DataFormatter = {
    getNazwaNieobecnosci, getDaysBetweenDates, getMonth,
};

function getNazwaNieobecnosci(typWniosku, nazwaRodzajuNieobecnosci) {
    if (typWniosku === 0) {
        return nazwaRodzajuNieobecnosci
    }
    else if (typWniosku === 1) {
        return 'Nadgodziny';
    }
    else if (typWniosku === 2) {
        return 'Wyjście prywatne';
    }
}

function getDaysBetweenDates(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
}

function getMonth() {
    return [{ id: 1, name: "Styczeń" },
    { id: 2, name: "Luty" },
    { id: 3, name: "Marzec" },
    { id: 4, name: "Kwiecień" },
    { id: 5, name: "Maj" },
    { id: 6, name: "Czerwiec" },
    { id: 7, name: "Lipiec" },
    { id: 8, name: "Sierpień" },
    { id: 9, name: "Wrzesień" },
    { id: 10, name: "Październik" },
    { id: 11, name: "Listopad" },
    { id: 12, name: "Grudzień" }];
}