import { Card, CardContent, Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Converter } from '../../submodules/template/services/Converter.js'
import MojWniosekButtons from "../../components/buttons/MojWniosekButtons.js";
import { ReactComponent as Import } from "../../assets/icons/Import.svg";
import { printService } from '../../submodules/patternprinter/services/pattern-service/printService'
import { authHeader } from "../../config/authHeader.js";
import apiurl from "../../apiurl.js";
import AccordionHistWniosku from "../accordions/AccordionHistWniosku.js";
import { ReactComponent as CheckCircleOutline } from "../../assets/icons/CheckCircleOutline.svg";
import '../../styles/kontrolki.css';
import moment from "moment";
import { StatusyWnioskow } from "../../services/statusyWnioskow.js";

function SelectedMojWniosekCard(props) {
    const [status, setStatus] = useState(false);
    const [isRemoved, setIsRemoved] = useState(false);


    useEffect(() => {
        setStatus(false);
        setIsRemoved(false);
    }, [props.selectedWniosek]);




    const onPrintButton = () => {       //TO DO godziny wniosków do obgadania
        let selectedWniosek = props.selectedWniosek;
        let daneDoDruku = {
            apiurl: apiurl,
            apiAuthHeader: authHeader(),
            idPrac: selectedWniosek.idPrac,
            idWniosek: selectedWniosek.idWniosek,
            TypWniosku: selectedWniosek.typWniosku,
            KodRodzajuNieobecnosci: selectedWniosek.kodRodzajuNieobecnosci,
            NazwaRodzajuNieobecnosci: selectedWniosek.nazwaRodzajuNieobecnosci,
            DataOd: selectedWniosek.dataOd,
            DataDo: selectedWniosek.dataDo,
            DataWn: selectedWniosek.dataWn,
            hhmmOd: selectedWniosek.hhmmOd,
            hhmmDo: selectedWniosek.hhmmDo
        }

        printService.openPrintDocument(daneDoDruku);
    }
    return (
        <>
            <Card sx={{ borderRadius: ' 4px 4px 0px 0px' }}>
                <CardContent>
                    <Box m={2}>
                        <Box mt={2}>
                            <Grid container justifyContent="space-between" alignItems="center" mb={3}>
                                <Grid item xs={10}>
                                    <Typography variant='h1'>Szczegóły</Typography>
                                </Grid>
                                {props.selectedWniosek.typWniosku === 0 &&
                                    <IconButton sx={{ mr: -2 }} style={{ padding: "2px" }} onClick={() => {
                                        onPrintButton();
                                    }}>
                                        <Import style={{ height: "24px", width: "24px" }} />
                                    </IconButton>
                                }
                            </Grid>
                            <Grid container rowSpacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant='h4' fontWeight="bold">ID Prac:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='h4'>{props.selectedWniosek.idPrac}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='h4' fontWeight="bold">Początek urlopu:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='h4'>{moment(props.selectedWniosek.dataOd).format('DD/MM/YYYY')} {props.selectedWniosek.hhmmOd !== '00:00' ? props.selectedWniosek.hhmmOd : null}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='h4' fontWeight="bold">Koniec urlopu:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='h4'>{moment(props.selectedWniosek.dataDo).format('DD/MM/YYYY')} {props.selectedWniosek.hhmmDo !== '00:00' ? props.selectedWniosek.hhmmDo : null}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='h4' fontWeight="bold">Typ urlopu:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='h4'>{props.selectedWniosek.nazwaRodzajuNieobecnosci}</Typography>
                                </Grid>
                                {/* <Grid item xs={6}>      TO DO 
                            <Typography fontWeight="bold">Pozostały urlop:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {props.urlop.isUrlopNielimitowany === true ?
                                <Typography>Nielimitowany</Typography>
                                :
                                <Typography >{props.urlop.dostepneDni} dni | {props.urlop.maxDniUrlopu} dni</Typography>
                            }
                        </Grid> */}
                                <Grid item xs={6}>
                                    <Typography variant='h4' fontWeight="bold">Zastępca:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='h4'>{Converter.titleCase(props.selectedWniosek.zastepstwo) ? Converter.titleCase(props.selectedWniosek.zastepstwo) : "-"}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='h4' fontWeight="bold">Komentarz:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='h4'>{props.selectedWniosek.opis ? props.selectedWniosek.opis : "-"}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='h4' fontWeight="bold">Status:</Typography>
                                </Grid>
                                <Grid container item xs={6}>
                                    <Box sx={{ display: "flex", alignItems: 'center', textAlign: 'left', ml: -2.5 }} >
                                        <Box id={props.getKontrolkaStatusu(props.selectedWniosek.status)}></Box>
                                        <Typography variant='h4'>{StatusyWnioskow.getTextFromInt(props.selectedWniosek.status)}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
            <AccordionHistWniosku idWniosek={props.selectedWniosek.idWniosek} />
            <Card sx={{ borderRadius: '0px 0px 4px 4px' }}>
                <Grid container item xs={12} rowSpacing={2}>
                    {/* <ChangeWniosekStatusButton refreshCurrentWniosek={() => props.refreshCurrentWniosek()} idPrac={props.idPrac} idWniosek={props.selectedWniosek.idWniosek} status={props.selectedWniosek.status}></ChangeWniosekStatusButton> */}
                    <Grid item xs={12} p={4} mt={5}>
                        <MojWniosekButtons
                            setStatus={setStatus}
                            succesPopup={props.succesPopup}
                            setSuccessPopup={props.setSuccessPopup}
                            errorPopup={props.errorPopup}
                            setErrorPopup={props.setErrorPopup}
                            setSelectedWniosek={props.setSelectedWniosek}
                            idWniosek={props.selectedWniosek.idWniosek}
                            status={props.selectedWniosek.status}
                            isRemoved={isRemoved}
                            setIsRemoved={setIsRemoved}
                            refreshCurrentWniosek={props.refreshCurrentWniosek}
                        >
                        </MojWniosekButtons>
                    </Grid>
                </Grid>
                {status ?
                    <Box ml={4} mb={2} display={'flex'} flexDirection={'row'}>
                        <Box item >
                            <CheckCircleOutline />
                        </Box>
                        <Box item >
                            <Typography variant="h4" color="success.main">
                                Wniosek został pomyślnie odwołany.
                            </Typography>
                        </Box>
                    </Box>
                    : null}
            </Card>
        </>
    )
}

export default SelectedMojWniosekCard;