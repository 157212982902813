import React from 'react';
import { Avatar, Box, Popover, Stack, Typography } from '@mui/material';
import moment from 'moment';


export default function CalendarPopover({ id, open, anchorEl, setAnchorEl, popUpEvent }) {

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Box p={2} display={'flex'} flexDirection={'column'}>
                    <Stack mb={3} direction='row' mt={1} ml={1}>
                        <Avatar alt='pracownik' />
                    </Stack>
                    {popUpEvent && <Typography>{popUpEvent.title}</Typography>}
                    <Typography>
                        {popUpEvent && `${moment(popUpEvent.start).format("MM.DoYYYY, hh:mm")} - ${moment(popUpEvent.end).format("MM.DoYYYY, hh:mm")}`}
                    </Typography>
                </Box>
            </Popover>
        </Box>
    );
}

