import { Toolbar, Typography } from '@mui/material';
import React from 'react';
import './styles/footer.css'

function Footer() {
    return (
        <div>
            <Toolbar className="footerContent">
                <Typography variant="caption3" color="secondaryColors.500"
                    fontFamily="Mulish">
                    Copyright © 2022 U.I. INFO-SYSTEM sp.j. All rights reserved.
                </Typography>
            </Toolbar>
        </div>
    );
}

export default Footer;