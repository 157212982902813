import { Card } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { StyledTreeItem } from '../components/StyledTreeItem';
import ExpandContext from '../services/expandContext';
import AccordeonCardStruktura from './accordions/StrukturaAccordion';
import '../styles/struktura.css';

const TreeRender = (props) => {
    const state = useContext(ExpandContext)
    const isChildren = props.data.children !== null;
    const [refresh, setRefresh] = useState(false);
    const [currentPath, setCurrentPath] = useState(props.currentPath);
    const [shouldRender, setShouldRender] = useState(false);
    let index = 0;
    useEffect(() => {


        setCurrentPath(oldArray => {
            return [...oldArray, props.data.idStrukt.toString()]
        });
        setShouldRender(true);
    }, [])

    // obsluga zapisywanie id klikanych i otwieranych akordeonow, potrzebne do osblugi otwierania i zamykania
    function expandCard(id) {
        const searchId = id.toString()
        if (!state.expanded.includes(searchId)) {
            state.setExpanded((prevState) => [...prevState, searchId]);
        } else {
            for (var i = 0; i < state.expanded.length; i++) {
                if (state.expanded[i] === searchId) {
                    const index = state.expanded.indexOf(searchId)
                    if (index > -1) {
                        state.expanded.splice(index, 1)
                    }
                }
            }
            setRefresh(!refresh);
        }
    }

    if (isChildren) {
        return (
            <>
                {shouldRender &&
                    <Card className='strukturaCard' sx={{ padding: '10px', }}>
                        <StyledTreeItem onClick={() => expandCard(props.data.idStrukt)} variant={'h3'} nodeId={props.data.idStrukt.toString()} fontWeight={'bold'} label={`${props.data.nazwa} - ilość osób: ${props.data.osoby.length}`}>
                            <AccordeonCardStruktura
                                isFiltered={props.isFiltered}
                                currentPath={currentPath}
                                persons={props.data.osoby}
                                expandAccordion={props.expandAccordion}
                                setExpandAccordion={props.setExpandAccordion}
                                id={props.data.idStrukt}
                                copyPerson={props.copyPerson}
                            />
                            {props.data && props.data.children.map(node => {
                                index = index + 1;
                                return (<TreeRender
                                    key={index}
                                    currentPath={currentPath}
                                    data={node}
                                    expandAccordion={props.expandAccordion}
                                    setExpandAccordion={props.setExpandAccordion}
                                    id={props.data.idStrukt}
                                    copyPerson={props.copyPerson}
                                />)
                            }

                            )}
                        </StyledTreeItem>
                    </Card>
                }
            </>
        )
    }
}

export default TreeRender;
