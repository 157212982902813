import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { propsToClassKey } from "@mui/styles";
import React, { useEffect, useState } from "react";
import PaginationComponent from "../../components/PaginationComponent";
import SearchTextField from "../../submodules/template/components/SearchTextField";
import FiltersWnioskiAccordion from "../accordions/FiltersWnioskiAccordion";
import NaglowekTablecell from "./NaglowekTablecell";
import moment from "moment";
import { StatusyWnioskow } from "../../services/statusyWnioskow";

function MojeWnioskiTable({ wnioski, wnioskiFiltered, setWnioskiFiltered, getKontrolkaStatusu, Status, selectedWniosek, setUrlopByWniosek, setSelectedWniosek }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(true);

    useEffect(() => {
        setSelectedWniosek(wnioski[0]);
    }, [])
    useEffect(() => {
        setPage(0);
    }, [wnioskiFiltered])
    const handleRowSelect = (wniosek) => {
        // apiService.getDostepnyUrlopByWniosek(wniosek.idWniosek).then((res) => {  //TO DO tego na razie nie ma w api
        //     setUrlopByWniosek(res.data);
        // })
        setSelectedWniosek(wniosek);
    }

    return (
        <Paper>
            <FiltersWnioskiAccordion
                setOpen={setOpen}
                open={open}
                setWnioskiFiltered={setWnioskiFiltered}
                wnioski={wnioski}
                wnioskiFiltered={wnioskiFiltered} />
            {/* <Grid container p={3} pt={4} sx={{ width: "100%" }}>
                <Grid item lg={9}>
                    <SearchTextField array={wnioski} setFilteredArray={setWnioskiFiltered}></SearchTextField>
                </Grid>
            </Grid> */}
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <NaglowekTablecell text="ID Prac" />
                            <NaglowekTablecell text="Zgłoszone" />
                            <NaglowekTablecell text="Termin" />
                            <NaglowekTablecell text="Rodzaj urlopu" />
                            <NaglowekTablecell text="Przebieg akceptacji" />
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        {wnioskiFiltered ? wnioskiFiltered
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                let bgColor = "";
                                let font;
                                if (selectedWniosek.idWniosek === row.idWniosek) {  //gdy wniosek jest wybrany to jest niebieski i pogrubiony
                                    bgColor = 'primary.50';
                                    font = 'bold';
                                }
                                else if (index % 2) {   //gdy wniosek jest parzysty to jest szary
                                    bgColor = 'neutral.50';
                                }
                                return (
                                    <TableRow sx={{
                                        backgroundColor: bgColor,
                                        cursor: 'pointer',
                                        "&:hover": { backgroundColor: "primary.50" }
                                    }}
                                        /*selected={selectedWniosek.idWniosek === row.idWniosek}*/
                                        onClick={() => handleRowSelect(row)}
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.idWniosek}>
                                        <TableCell sx={{ fontWeight: font }}>{row.idPrac}</TableCell>
                                        <TableCell sx={{ fontWeight: font }}>{moment(row.dataWn).format('DD.MM.YYYY')}</TableCell>
                                        <TableCell sx={{ fontWeight: font }}>{row.termin}</TableCell>
                                        <TableCell sx={{ fontWeight: font }}>{row.nazwaRodzajuNieobecnosci}</TableCell>
                                        <TableCell sx={{ fontWeight: font }}>
                                            <Grid container alignItems={'center'} sx={{ ml: -2.5 }}>
                                                <Grid item xs={3}>
                                                    <div id={getKontrolkaStatusu(row.status)}></div>
                                                </Grid>
                                                <Grid sx={{ fontWeight: font }} item xs={9}>
                                                    {StatusyWnioskow.getTextFromInt(row.status)}
                                                </Grid>
                                            </Grid></TableCell>
                                    </TableRow>
                                );
                            }) : ""}
                    </TableBody>
                </Table>
            </TableContainer>
            {wnioskiFiltered ? <PaginationComponent
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                data={wnioskiFiltered} /> : ""}
        </Paper>
    )
}

export default MojeWnioskiTable;