import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { ReactComponent as DownloadIcon } from '../assets/icons/download_arrow.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../styles/dostepnyUrlop.css';
import '../styles/kontrolki.css';
import Spinner from '../components/Spinner';
import { useNavigate } from 'react-router-dom';
import PaginationComponent from '../components/PaginationComponent';
import DostepneFormyUrlopow from '../components/DostepneFormyUrlopow';
import BackArrow from '../components/BackArrow';


const urlopy = [
    {
        "id": 1,
        "typAbstynencji": "Urlop Wypoczynkowy",
        "dostepnosc": 10,
        "oczekuje": 2,
        "zaakceptowane": 15,
        "status": 1,
        "szczegoly": {
            "przyrostowo": 20,
            "zPoprzedniego": 5,
            "zgloszoneNieob": 7
        }
    },
    {
        "id": 2,
        "typAbstynencji": "Choroba",
        "dostepnosc": 4,
        "status": 2
    },
    {
        "id": 3,
        "typAbstynencji": "Delegacja",
        "dostepnosc": "Nielimitowany",
        "status": 3
    },
    {
        "id": 4,
        "typAbstynencji": "Dzień wolny za nadgodziny",
        "dostepnosc": "Nielimitowany",
        "status": 1
    },
    {
        "id": 5,
        "typAbstynencji": "Odbiór za święto",
        "dostepnosc": "Nielimitowany",
        "status": 2
    },
    {
        "id": 6,
        "typAbstynencji": "Oddanie krwi",
        "dostepnosc": "Nielimitowany",
        "status": 1
    },
    {
        "id": 7,
        "typAbstynencji": "Opieka nad dzieckiem",
        "dostepnosc": "Nielimitowany",
        "status": 3
    },
    {
        "id": 8,
        "typAbstynencji": "Oddanie krwi godzinowo",
        "dostepnosc": "Nielimitowany",
        "status": 1
    },

]

function DostepnyUrlop() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [itemDetails, setItemDetails] = useState([urlopy[1]]);
    const [selectedRow, setSelectedRow] = useState();

    let navigate = useNavigate();


    useEffect(() => {
        setItemDetails(urlopy[0])
    }, []);

    const handleTableRow = (item) => {
        setItemDetails(item);
        setSelectedRow(item.id);
    }

    return (
        <>
            <Box mb={5} display={'flex'} alignItems={'center'}><BackArrow /><Typography variant="h1">Dostępne formy urlopów</Typography></Box>
            <DostepneFormyUrlopow />
            {/* {urlopy ?
                <>
                    <Grid container spacing={4} justifyContent='center' style={{ height: '100%' }}>
                        <Grid item xs={8}>
                            <Card elevation={2}>
                                <TableContainer component={Paper}>
                                    <Table aria-label='dostepne urlopy'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Typ abstynencji</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Dostępność</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {urlopy.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((item) => (
                                                    <>
                                                        <TableRow
                                                            selected={selectedRow === item.id}
                                                            key={item.id}
                                                            hover
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                                            onClick={() => handleTableRow(item)}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {item.typAbstynencji}
                                                            </TableCell>
                                                            <TableCell >{item.dostepnosc}</TableCell>
                                                            <TableCell>{item.status === 1 ?
                                                                <Grid spacing={4} className='statusUrlopu' container>
                                                                    <Grid item xs={2}><div id="circle_green"></div></Grid>
                                                                    <Grid item xs={10}><Typography variant='h7'>Dostępny</Typography></Grid>
                                                                </Grid>
                                                                : item.status === 2 ?
                                                                    <Grid spacing={4} className='statusUrlopu' container>
                                                                        <Grid item xs={2}><div id="circle_yellow"></div></Grid>
                                                                        <Grid item xs={10}><Typography variant='h7'>W trakcie akceptacji</Typography></Grid>
                                                                    </Grid> :
                                                                    <Grid spacing={4} className='statusUrlopu' container>
                                                                        <Grid item xs={2}><div id="circle_red"></div></Grid>
                                                                        <Grid item xs={10}><Typography variant='h7'>Wykorzystany</Typography></Grid>
                                                                    </Grid>
                                                            }</TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                                <PaginationComponent page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} data={urlopy} />
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Card elevation={2}>
                                {itemDetails ?
                                    <Stack mt={3} ml={2} mb={2} key={itemDetails.id}>
                                        <Typography mb={4} variant='h1'>{itemDetails.typAbstynencji}<IconButton>
                                            <DownloadIcon />
                                        </IconButton></Typography>
                                        <Grid container justifyContent={'space-around'} mb={2}>
                                            <Grid item xs={9}>
                                                <Typography style={{ fontWeight: 'bold' }}>Obecnie dostępne:</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography>{!isNaN(+itemDetails.dostepnosc) ? `${itemDetails.dostepnosc} dni` : itemDetails.dostepnosc}</Typography>
                                            </Grid>
                                        </Grid>
                                        {itemDetails.oczekuje ?
                                            <Grid container justifyContent={'space-around'} mb={2}>
                                                <Grid item xs={9}>
                                                    <Typography style={{ fontWeight: 'bold' }}>Oczekuje:</Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography>{itemDetails.oczekuje} dni</Typography>
                                                </Grid>
                                            </Grid>
                                            : null}
                                        {itemDetails.zaakceptowane ?
                                            <Grid container justifyContent={'space-around'} mb={2}>
                                                <Grid item xs={9}>
                                                    <Typography style={{ fontWeight: 'bold' }}>Zaakceptowane:</Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography>{itemDetails.zaakceptowane} dni</Typography>
                                                </Grid>
                                            </Grid>
                                            : null}
                                    </Stack>
                                    : null}
                                {itemDetails.szczegoly ?
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography variant='h3'>Historia</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container justifyContent={'space-around'} mb={2}>
                                                <Grid item xs={9}>
                                                    <Typography style={{ fontWeight: 'bold' }}>Przyrost roczny:</Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography>{itemDetails.szczegoly.przyrostowo} dni</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container justifyContent={'space-around'} mb={2}>
                                                <Grid item xs={9}>
                                                    <Typography style={{ fontWeight: 'bold' }}>Z poprzedniego roku:</Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography>{itemDetails.szczegoly.zPoprzedniego} dni</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container justifyContent={'space-around'} mb={2}>
                                                <Grid item xs={9}>
                                                    <Typography style={{ fontWeight: 'bold' }}>Zgłoszone nieobecności:</Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography>{itemDetails.szczegoly.zgloszoneNieob} dni</Typography>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    : null}
                                <Grid container direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                    mt={3}
                                    mb={3}
                                >
                                    <Grid item xs={5}>
                                        <Button variant="contained" size="large" onClick={() => navigate('/wnioski/nowy-wniosek')}>Złóż wniosek</Button>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Link underline="always" sx={{ cursor: 'pointer' }} variant="caption3" onClick={() => navigate('/wnioski/moje-wnioski')}>Zobacz złożone wnioski</Link>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid >
                </>
                : <Spinner />} */}

        </ >
    );
}

export default DostepnyUrlop;