import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../styles/kontrolki.css'
import { bindActionCreators } from 'redux';
import { actionCreators } from '../redux/actionCreators';
import SelectedMojWniosekCard from '../modules/cards/SelectedMojWniosekCard';
import Spinner from '../components/Spinner';
import MojeWnioskiTable from '../modules/tables/MojeWnioskiTable';
import PopupAlert from '../components/PopupAlert';
import BackArrow from '../components/BackArrow';

const urlop = { 'dostepnyUrlop': 10, 'maxLiczbaDniUrlopu': 27 }     //DO ZMIANY

//const Status = ['Oczekuje', 'Odrzucony', 'Zaakceptowany', 'Zaplanowany', 'Zrealizowany', 'Anulowany', 'Edytowany'];

function MojeWnioski() {
    const [succesPopup, setSuccessPopup] = useState(false);
    const [errorPopup, setErrorPopup] = useState(false);
    const [selectedWniosek, setSelectedWniosek] = useState("");
    const [urlopByWniosek, setUrlopByWniosek] = useState("");
    const [wnioskiFiltered, setWnioskiFiltered] = useState(null);
    const wnioski = useSelector((state) => state.listaMoichWnioskow);
    const dispatch = useDispatch();
    const { addListaMoichWnioskow } = bindActionCreators(actionCreators, dispatch);

    useEffect(() => {
        setWnioskiFiltered(wnioski);
    }, [wnioski])
    useEffect(() => {
        getDane();
    }, []);

    const getDane = () => {
        if (wnioski == null) {
            addListaMoichWnioskow();
        }
    }

    const refreshCurrentWniosek = () => {
        let idWniosek = selectedWniosek.idWniosek;
        let wniosek = wnioski.find(x => x.idWniosek === idWniosek)
        setSelectedWniosek(wniosek);
    }


    const removeSelectedWniosek = () => {
        setSelectedWniosek(wnioski[0]);
    }
    // const refreshCurrentWniosek = () => {
    //     let idWniosek = selectedWniosek.idWniosek;
    //     let wniosek = wnioski.find(x => x.idWniosek === idWniosek)
    //     setSelectedWniosek(wniosek);
    // }

    const getKontrolkaStatusu = (status) => {
        if (status === 0) {
            return 'circle_yellow';
        }
        else if (status === 1) {
            return 'circle_red'
        }
        else if (status === 2) {
            return 'circle_green'
        }
        else if (status === 3) {
            return 'circle_green'
        }
        else if (status === 4) {
            return 'circle_black';
        }
        else if (status === 5) {
            return 'circle_black';
        }
    }

    return (
        <>
            <Box mb={1} display={'flex'} alignItems={'center'}>
                <BackArrow />
                <Typography variant="h1">Złożone wnioski</Typography>
            </Box>
            {wnioski ?
                <Grid container spacing={0} columnSpacing={3} rowSpacing={2}>
                    <Grid item xl={8.5} lg={8.5} md={12} width={"100%"} order={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}>
                        <MojeWnioskiTable
                            wnioski={wnioski}
                            wnioskiFiltered={wnioskiFiltered}
                            setWnioskiFiltered={setWnioskiFiltered}
                            getKontrolkaStatusu={getKontrolkaStatusu}
                            selectedWniosek={selectedWniosek}
                            setUrlopByWniosek={setUrlopByWniosek}
                            setSelectedWniosek={setSelectedWniosek} />
                    </Grid>
                    <Grid item xl={3.5} lg={3.5} md={12} order={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                        {selectedWniosek ? <SelectedMojWniosekCard
                            errorPopup={errorPopup}
                            setErrorPopup={setErrorPopup}
                            succesPopup={succesPopup}
                            setSuccessPopup={setSuccessPopup}
                            removeSelectedWniosek={() => removeSelectedWniosek()}
                            selectedWniosek={selectedWniosek}
                            getKontrolkaStatusu={getKontrolkaStatusu}
                            refreshCurrentWniosek={refreshCurrentWniosek} />
                            : ""}
                    </Grid>
                </Grid>
                : <Spinner />}
            <PopupAlert severity="success" text="Wniosek został anulowany" open={succesPopup} setOpen={setSuccessPopup} />
            <PopupAlert severity="error" text="Wniosek nie został anulowany" open={errorPopup} setOpen={setErrorPopup} />
        </>
    );
}

export default MojeWnioski;