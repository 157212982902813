import React, { useRef, useEffect } from 'react';
import { ThemeProvider } from '@mui/material';
import { CircularProgress, Box, Typography } from '@mui/material';
import { useState } from 'react';
import Aside from './submodules/template/layout/Aside';
import Footer from './submodules/template/layout/Footer';
import Header from './submodules/template/layout/Header';
import MainRouter from './MainRouter';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from './submodules/template/loginRedux/actionCreators';
import { Routes, Route, Navigate } from "react-router-dom";
import asideData from './config/asideData';
import Login from './submodules/template/modules/Login';
import RecoverPass from './submodules/template/modules/RecoverPass';
import theme from './submodules/template/layout/styles/theme';
import axios from 'axios';
import apiurl from './apiurl';
import HeaderMenu from "./modules/HeaderMenu";



function App(props) {

  const [newAsideData, setNewAsideData] = useState(asideData);
  const token = useSelector((state) => state.loginData.token);
  const childRef = useRef();
  const [isOpened, setIsOpened] = useState(true);
  const [checkingToken, setChekToken] = useState(true);

  const dispatch = useDispatch();
  const { addExistingToken } = bindActionCreators(actionCreators, dispatch);

  const checkToken = () => {
    const LoginResponse = JSON.parse(localStorage.getItem(localStorage.getItem('appPrefix') + 'LoginResponse'));
    const tokenStorage = LoginResponse.token;

    return axios.post(apiurl + "api/auth/CheckToken", {},
      { headers: { 'Content-Type': 'application/json', 'Authorization': tokenStorage } })
      .then(res => {
        addExistingToken(LoginResponse);
        setChekToken(false);
      })
      .catch(error => {
        console.log('Error - token nieaktywny.');
        setChekToken(false);
      });
  }

  useEffect(() => { if (localStorage.hasOwnProperty(localStorage.getItem('appPrefix') + 'LoginResponse')) { checkToken() } else { setChekToken(false) } });

  useEffect(() => {
    removeNonAdminTabs();

  }, [token])

  const removeNonAdminTabs = () => { //non admin czyli tam gdzie nie ma zatwierdzania wnioskow
    removeSubasideTab(2, 5); //wnioski > wnioski pracownikow
    removeSubasideTab(2, 7); //wnioski > zestawienia
    removeSubasideTab(2, 8); //divider Pracownicy
  }
  const removeSubasideTab = (asideId, subasideId) => {
    const LoginResponse = JSON.parse(localStorage.getItem(localStorage.getItem('appPrefix') + 'LoginResponse'));
    if (token) {
      if (LoginResponse.isZatwierdzanieWnioskow === 0) {  //usuniecie zakładki wniosków pracowników
        let tempArr = newAsideData;
        let tempAside = newAsideData.find(item => item.id === asideId);
        let tempSubAside = tempAside.subaside.filter(item => item.id !== subasideId);
        tempAside.subaside = tempSubAside;
        let index = tempArr.findIndex(item => item.id === asideId);
        tempArr[index] = tempAside;
        setNewAsideData(tempArr);
      }
    }
  }



  if (checkingToken) {
    return (
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <Box sx={{ display: 'flex', minWidth: 1, minHeight: '100vh', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <CircularProgress sx={{ m: 10 }} />
            <Typography variant='h5'>Ładowanie aplikacji...</Typography>
          </Box>
        </ThemeProvider>
      </React.Fragment>
    )
  }

  else if (token === null) {
    return (
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route path="/odzyskiwanieHasla/:hash" element={<RecoverPass />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </ThemeProvider>
      </React.Fragment>
    )
  }

  else {
    return (
      <React.Fragment>
        <ThemeProvider theme={theme}>

          <div className="app" onClick={() => childRef.current.resetTimer()}>

            <div>
              {newAsideData.length > 0 && <Aside setIsOpened={setIsOpened} isOpened={isOpened} asideData={newAsideData} />}

            </div>

            <div className="mainContainer">
              <div className={`${isOpened ? "openedheaderfooter" : ""} header`}>
                <Header ref={childRef} menuModule={HeaderMenu} />
              </div>
              <div className="main">
                <MainRouter history={props.history} />
              </div>
              <div className={`${isOpened ? "openedheaderfooter" : ""} footer`}>
                <Footer />
              </div>
            </div>
          </div>

        </ThemeProvider>
      </React.Fragment>
    );
  }
}

export default App;
