import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import './styles/aside.css';
import { useNavigate, useLocation } from "react-router-dom";
import SubAside from './SubAside';
const useStyles = makeStyles(() => ({
    root: {
        width: '99%',
        backgroundColor: 'black'
    }
}));

function Aside(props) {
    let navigate = useNavigate();
    let location = useLocation();
    const [activeAsideId, setActiveAsideId] = useState(0);
    const [activeSubAsideId, setActiveSubAsideId] = useState(1);

    const classes = useStyles();
    useEffect(() => {

        setInitialAsideId();
    });

    const setInitialAsideId = () => {
        let url = setFirstTwoUrlSegments();
        props.asideData.forEach(aside => {
            aside.subaside.forEach(subaside => {
                let subasideUrl = getFirstTwoUrlSegments(subaside.url);
                if (subasideUrl == url) {
                    setActiveAsideId(aside.id);
                    setActiveSubAsideId(subaside.id);
                    return
                }
            });
        });
    }

    const handleClick = (id, url) => {
        setActiveAsideId(id)
        navigate(url);
        setActiveSubAsideId(0);
    };

    const setFirstTwoUrlSegments = () => {
        let url = location.pathname;
        let segments = url.split("/");
        let firstTwoUrlSegments = "/" + segments[1] + "/" + segments[2];

        return firstTwoUrlSegments;
    }
    const getFirstTwoUrlSegments = (url) => {
        let segments = url.split("/");
        let firstTwoUrlSegments = "/" + segments[1] + "/" + segments[2];

        return firstTwoUrlSegments;
    }


    return (
        <div className="structure" >
            <div className={`${props.isOpened ? "opened" : ""} aside`}>
                <div className="icon" onClick={() => props.setIsOpened(!props.isOpened)}>
                    {props.isOpened ? <ChevronLeftIcon /> : <MenuIcon />}
                </div>
                <div className={`${classes.root} asidecontent`}>
                    <Typography component={'span'} color='white' className='flexContainer'>
                        <div className='spaceFromTop'></div>
                        <List className='topList'>
                            {props.asideData.map((option) => {

                                if (option.position == "top") {
                                    return (
                                        <ListItem className='asideButton' color='white' button selected={activeAsideId === option.id} key={option.name} onClick={() => handleClick(option.id, option.url)} >
                                            <ListItemIcon style={{ height: "20px" }} color='white'>{option.icon}</ListItemIcon>
                                            <ListItemText className='asideButtonText' style={{ whiteSpace: 'nowrap' }} primary={option.name} />
                                        </ListItem>
                                    )
                                }
                            })}
                        </List>

                        <List className='bottomList'>
                            {props.asideData.map((option) => {

                                if (option.position == "bottom") {
                                    return (
                                        <ListItem className='asideButton' color='white' button selected={activeAsideId === option.id} key={option.name} onClick={() => handleClick(option.id, option.url)} >
                                            <ListItemIcon style={{ height: "20px" }} color='white'>{option.icon}</ListItemIcon>
                                            <ListItemText className='asideButtonText' style={{ whiteSpace: 'nowrap' }} primary={option.name} />
                                        </ListItem>
                                    )
                                }
                            })}
                        </List>
                    </Typography>

                </div >
            </div>

            <div className="subaside">
                <SubAside asideData={props.asideData} activeAsideId={activeAsideId} activeSubAsideId={activeSubAsideId} setActiveSubAsideId={setActiveSubAsideId} />
            </div>
        </div>


    );
};

export default Aside;