import React from 'react';
import { Typography, Box, List, ListItemText, Paper } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../redux/actionCreators";
import Spinner from '../components/Spinner';
import { getIdPrac } from '../redux/idPracFetch';

export default function DostepneFormyUrlopow() {
  const infoZKadr = useSelector((state) => state.infoZKadr);
  const dispatch = useDispatch();
  const { addInfoZKadr } = bindActionCreators(actionCreators, dispatch);
  const activeUmowa = getIdPrac();


  useEffect(() => {
    getDane();
  }, [infoZKadr]);

  const getDane = () => {
    if (infoZKadr === null) {
      addInfoZKadr({ idPrac: activeUmowa });
    }
  }

  function boldData(string) {
    let data = string.split(' ')
    let newString = ''
    data.map(item => {
      if ((/([01]?[0-9]|2[0-3]):[0-5][0-9]/).test(item) || (/([(][0-99][a-z][)])/).test(item)) {
        newString += item.bold().concat(' ')
      } else {
        newString += item.concat(' ')
      }
    })
    return newString
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', '& > :not(style)': { width: 711, height: 195 }, }}>
      {infoZKadr ?
        <Paper>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', ml: 6, mt: 4 }}>
            <List>
              <div></div>
              <ListItemText sx={{ mb: 2 }} primary={<Typography variant="h4"><div dangerouslySetInnerHTML={{ __html: boldData(infoZKadr.urlop) }} /></Typography>}></ListItemText>
              <ListItemText sx={{ mb: 2 }} primary={<Typography variant="h4"><div dangerouslySetInnerHTML={{ __html: boldData(infoZKadr.nadgodziny) }} /></Typography>}></ListItemText>
              <ListItemText sx={{ mb: 2 }} primary={<Typography variant="h4"><div dangerouslySetInnerHTML={{ __html: boldData(infoZKadr.opieka) }} /></Typography>}></ListItemText>
              <ListItemText sx={{ mb: 2 }} primary={<Typography variant="h4"><div dangerouslySetInnerHTML={{ __html: boldData(infoZKadr.choroba) }} /></Typography>}></ListItemText>
            </List>
          </Box>
        </Paper>
        : <Spinner />
      }
    </Box>
  );
}