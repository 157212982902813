import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
import { PatternService } from '../../services/pattern-service/patternService';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

toast.configure();
let patternService;
class PatternList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patterns: [],
            showModal: false,
            currentid_Pattern: 0,
            currentPatternName: '',
            currentPatternid_Systemu: 0,
            isLoading: true,
            permission: ''
        }
        //patternService = new PatternService(this.props.apiAuthHeader);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleShowModal = this.handleShowModal.bind(this);
        this.handleDeleteModal = this.handleDeleteModal.bind(this);

    }
    async componentDidMount() {
        await this.getListOfPatterns();


        await PatternService.getInstance(this.props.apiurl, this.props.apiAuthHeader).getRola().then(res => {
            if (res.data.dostepneRole.includes(1)) {
                this.setState({ permission: true })
            }
            else {
                this.setState({ permission: false })
            }

        })

        /*if (sessionStorage.getItem('isPatternCreated') === 'true') {
            sessionStorage.removeItem('isPatternCreated');
            window.location.reload();
        }*/
    }

    getListOfPatterns() {
        PatternService.getInstance(this.props.apiurl, this.props.apiAuthHeader).getAllPatternsByIdSystemu().then(response => {
            this.setState({ patterns: response.data })
            this.setState({ isLoading: false })
        });

    }

    handleShowModal(id, id_Systemu, name) {
        this.setState({
            showModal: true,
            currentid_Pattern: id,
            currentPatternName: name,
            currentPatternid_Systemu: id_Systemu
        });
    }

    handleCloseModal() {
        this.setState({ showModal: false })
    }

    async handleDeleteModal() {
        await PatternService.getInstance(this.props.apiurl, this.props.apiAuthHeader).deletePattern(this.state.currentid_Pattern);
        this.setState({ showModal: false });
        var patternsCopy = this.state.patterns;
        for (var i = 0; i < this.state.patterns.length; i++)
            if (patternsCopy[i].id_Pattern === this.state.currentid_Pattern) {
                patternsCopy.splice(i, 1);
                this.setState({ patterns: patternsCopy })
                break;
            }
    }

    render() {

        const { patterns } = this.state;

        if (this.state.permission == false) {
            return (
                <div>
                    Brak dostępu
                </div>
            )
        }
        else {

            return (

                <div className="d-flex-column">
                    <div className="d-flex flex-row-reverse" style={{ marginLeft: "auto", marginBottom: "5px" }}>
                        <Link to="/wzorce/patternCreator"><button type="button" className="btn btn-primary">Dodaj wzorzec</button></Link>
                    </div>

                    <div className="table-responsive-xl card">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Nazwa</th>
                                    <th scope="col">Typ Wzorca</th>
                                    <th scope="col">System</th>
                                    <th scope="col">Opis</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {(patterns.length > 0) ? patterns.map(pattern => {
                                    return (
                                        <tr key={pattern.id_Pattern}>
                                            <td>{pattern.id_Pattern}</td>
                                            <td>{pattern.name}</td>
                                            <td>{pattern.typ_Wzorca}</td>
                                            <td>{pattern.id_Systemu}</td>
                                            <td className="col-md-1 ml-auto">{pattern.description}</td>
                                            <td><Link to={"/wzorce/patternEditor/" + pattern.id_Pattern}><button type="button" className="btn btn-primary">Edytuj</button></Link></td>
                                            <td >
                                                <Button variant="danger" onClick={() => this.handleShowModal(pattern.id_Pattern, pattern.id_Systemu, pattern.name)}>
                                                    Usuń
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                }) :
                                    <tr><td colSpan="8">

                                        {(this.state.isLoading) ? <div style={{ display: "flex", justifyContent: "center" }} >
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div> : ""}

                                    </td></tr>
                                }
                            </tbody>
                        </table>
                    </div>

                    <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Czy na pewno chcesz usunąć wzorzec nr {this.state.currentid_Pattern} z ID Systemu {this.state.currentPatternid_Systemu} o nazwie "{this.state.currentPatternName}"? </Modal.Title>
                        </Modal.Header>
                        <Modal.Body><h5><p style={{ color: "red" }}><b>Tej akcji nie można cofnąć!</b></p></h5></Modal.Body>
                        <Modal.Footer style={{ display: "inline" }}>
                            <div className="row">
                                <div className="col-2" style={{ paddingLeft: "0px" }}>
                                    <Button variant="secondary" onClick={this.handleCloseModal}>
                                        Zamknij
                                    </Button>
                                </div>
                                <div className="col-8">

                                </div>
                                <div className="col-2">
                                    <Button variant="danger" onClick={this.handleDeleteModal}>
                                        Usuń!
                                    </Button>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
            )
        }
    }
}

export default PatternList;