import { createStore } from 'redux';
import reducers from './index';

const store = createStore(reducers);


export function getIdPrac() {
    const state = store.getState();
    if (state.activeUmowa) {
        return state.activeUmowa

    } else {
        return localStorage.getItem('ekadry2_idPrac')
    }
}