import React, { useEffect, useState } from 'react';
import { AccordionDetails, AccordionSummary, Step, StepConnector, StepLabel, Stepper, Typography, Grid, Divider, Box, SvgIcon, } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getIdPrac } from '../../redux/idPracFetch';
import { apiService } from '../../services/api.service';
import { ReactComponent as CreateIcon } from '../../assets/icons/CreateIcon.svg';
import { ReactComponent as RefuseIcon } from '../../assets/icons/RefuseIcon.svg';
import { ReactComponent as AcceptIcon } from '../../assets/icons/AcceptIcon.svg';
import { ReactComponent as ModifyIcon } from '../../assets/icons/ModifyIcon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/DeleteIcon.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/EditIcon.svg';
import { ReactComponent as CompletedIcon } from '../../assets/icons/CompletedIcon.svg';
import { withStyles } from '@mui/styles';
import { Converter } from '../../submodules/template/services/Converter';
import { CustomAccordion } from '../../components/CustomAccordion';
import moment from 'moment';
import SelectedMojWniosekCard from '../cards/SelectedMojWniosekCard';
import { StatusyWnioskow } from '../../services/statusyWnioskow';

const LineConnector = withStyles({
    alternativeLabel: {
        top: 22
    },
    line: {
        flexDirection: "column",
        height: 30,
        width: 3,
        border: 0,
        backgroundColor: "#eaeaf0",
        borderRadius: 1
    }
})(StepConnector);

function AccordionHistWniosku({ idWniosek, idPrac }) {
    const [historiaWniosku, setHistoriaWniosku] = useState();
    const activeUmowa = getIdPrac();

    useEffect(() => {
        apiService.getHistoriaWniosku({ idPrac: parseInt(activeUmowa || idPrac), idWniosk: idWniosek }).then(res => setHistoriaWniosku(res.data))
    }, [idWniosek, activeUmowa, idPrac]);

    // const switchIcon = (icon) => {
    //     switch (icon) {
    //         case 'Odrzucono':
    //             return RefuseIcon
    //         case 'Złożono':
    //             return CreateIcon
    //         case 'Zaakceptowano':
    //             return AcceptIcon
    //         case 'Zaplanowano':
    //             return ModifyIcon
    //         case 'Usunięto':
    //             return DeleteIcon
    //         case 'Edytowany':
    //             return EditIcon
    //         case 'Zrealizowano':
    //             return CompletedIcon
    //         default:
    //             return null
    //     }
    // }


    return (
        <CustomAccordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="historia-content"
                id="historia-header"
            >
                <Box ml={2} ><Typography variant='h3' fontWeight={'bold'}>Historia</Typography></Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    {historiaWniosku && historiaWniosku.sort(((a, b) => b.row - a.row)).map((row, index) => {
                        return <Grid container justifyContent="space-between" direction="row" key={index}>
                            <Grid item>
                                <Typography ml={2} variant='h4' color="neutral.500"><br />{moment(row.data).format("DD/MM/YYYY")}</Typography>
                                <Typography ml={2} variant='h4' color="neutral.500">{moment(row.data).format("HH:mm")}</Typography>
                            </Grid>
                            <Grid item>
                                <Box mr={1.5}><Divider sx={{ height: '14px' }} orientation="vertical" variant="middle"></Divider></Box >
                                <Box>
                                    <SvgIcon component={StatusyWnioskow.getIconFromInt(row.status)} />
                                    {/* {StatusyWnioskow.getIconFromInt(row.status)} */}
                                </Box>
                            </Grid>
                            <Grid item xs={6} >
                                <Typography variant='h4' color="neutral.900" fontWeight={'bold'}><br />{StatusyWnioskow.getTextFromInt(row.status)}</Typography>
                                <Typography variant='h4' color="neutral.900">{Converter.titleCase(row.osoba)}</Typography>
                            </Grid>
                        </Grid>
                    })}
                </Box>
            </AccordionDetails >
        </CustomAccordion >
    );
}

export default AccordionHistWniosku;