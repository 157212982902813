import { Paper, Table, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useState } from "react";
import PaginationComponent from "../../components/PaginationComponent";
import TableRowNadgodziny from "./TableRowNadgodziny";

const columns = [
    { id: 'nazwa', label: 'Nazwa', minWidth: 130, align: 'left' },
    { id: 'data', label: 'Data', minWidth: 130, align: 'left' },
    {
        id: 'razem',
        label: 'Razem (h)',
        minWidth: 80,
        align: 'center'

    },
    {
        id: 'odebrano',
        label: 'Odebrano (h)',
        minWidth: 80,
        align: 'center',
    },
    {
        id: 'doOdebrania',
        label: 'Pozostało (h)',
        minWidth: 80,
        align: 'center',
    },
];

function isEven(n) {
    return n % 2 === 0;
}

function NadgodzinyTable({ nadgodziny }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    return (
        <Paper>
            <TableContainer>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow >
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    sx={{fontWeight: 'bold', minWidth: column.minWidth}}>
                                
                                    {column.label}
                                </TableCell>
                            ))}
                            <TableCell sx={{ minWidth: "50px" }} />

                        </TableRow>
                    </TableHead>
                    {nadgodziny && nadgodziny.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                        let isEvenVar = false;
                        if (isEven(index)) {
                            isEvenVar = true;
                        }
                        return <TableRowNadgodziny isEven={isEvenVar} key={row.idNadgodzin} row={row} />
                    })}
                </Table>
            </TableContainer>
            {nadgodziny ? <PaginationComponent page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} data={nadgodziny} /> : ""}
        </Paper>
    )

}

export default NadgodzinyTable;