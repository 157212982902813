import React, { useEffect, useState } from 'react';
import PatternService from '../../services/pattern-service/patternService'
import PrinterButton from '../../common/printerButton';
import ExportPatternButton from '../../common/exportPatternButton';
import ImportPatternButton from '../../common/importPatternButton';
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import SpinnerWithoutText from '../../../../components/SpinnerWithoutText';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeIcon from '@mui/icons-material/Mode';
import SearchTextField from '../../../template/components/SearchTextField';
import DeleteModal from '../../common/modals/DeleteModal';
import { withStyles } from '@mui/styles';
import PaginationComponent from '../../../../components/PaginationComponent';
import ActionModal from '../../common/modals/actionModal';
import PatternPreviewer from '../../common/patternPreviewer';

const CustomIconButton = withStyles({
    root: {
        borderRadius: 4,

        "&:hover, &.Mui-focusVisible": { color: '#3879F6' }
    }
})(props => <IconButton {...props} />);

const columns = [
    {
        id: 1,
        name: 'ID',
        sx: { paddingLeft: "25px" }
    },
    {
        id: 2,
        name: 'Nazwa',
    },
    {
        id: 3,
        name: 'System',
    },
    {
        id: 4,
        name: 'Typ',
    },
    {
        id: 5,
        name: 'Opis',
    },
    {
        id: 6,
        name: 'Akcja',
        sx: { paddingRight: "25px" }
    },
]


const PatternListFunc = (props) => {
    const [showButtons, setShowButtons] = useState(-1);
    const [patterns, setPatterns] = useState([]);
    const [patternsFiltered, setPatternsFiltered] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [permission, setPermission] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [currentPatternIndex, setCurrentPatternIndex] = useState(0);
    const [currentid_Pattern, setCurrentid_Pattern] = useState(0);
    const [currentPatternName, setCurrentPatternName] = useState('');
    const [currentPatternid_Systemu, setCurrentPatternid_Systemu] = useState(0);
    const [isImportShown, setIsImportShown] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isOpenedDrawer, setIsOpenedDrawer] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            await PatternService.getInstance(props.apiurl, props.apiAuthHeader).getRola().then(res => {
                if (res.data.dostepneRole.includes(1)) {
                    setPermission(true)
                }
                else {
                    setPermission(false)
                }
            })
        }
        fetchData();
        getAllPatternsByIdSystemu();
    }, []);

    useEffect(() => {   //gdy zmieni się index wybranego wzorca w drawerze (przycisk 'poprzedni' lub 'następny')
        if (patternsFiltered.length > 0) {
            setCurrentid_Pattern(patternsFiltered[currentPatternIndex].id_Pattern)
            setCurrentPatternid_Systemu(patternsFiltered[currentPatternIndex].id_Systemu)
            setCurrentPatternName(patternsFiltered[currentPatternIndex].name)
        }

    }, [currentPatternIndex])

    const getAllPatternsByIdSystemu = () => {
        PatternService.getInstance(props.apiurl, props.apiAuthHeader).getAllPatternsByIdSystemu().then(response => {
            setPatterns(response.data)
            setPatternsFiltered(response.data)
            setIsLoading(false)
        });
    };

    const onAddButton = () => {
        props.navigation("/wzorce/lista/patternCreator")

    };

    function handleShowModal(id, id_Systemu, name) {
        setShowModal(true);
        setCurrentPatternid_Systemu(id_Systemu);
        setCurrentPatternName(name);
        setCurrentid_Pattern(id);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleDeleteModal = async () => {
        if (permission) {
            await PatternService.getInstance(props.apiurl, props.apiAuthHeader).deletePattern(currentid_Pattern);
            setShowModal(false);
            setCurrentPatternid_Systemu(null);
            setCurrentPatternName(null);
            setCurrentid_Pattern(null);
            getAllPatternsByIdSystemu();
        }

    };

    const handleShowButtons = (id) => {
        setShowButtons(id);
    };

    const showImport = () => {
        setIsImportShown(true);
    };

    const onChooseRow = (pattern, index) => {
        setCurrentid_Pattern(pattern.id_Pattern)
        setCurrentPatternid_Systemu(pattern.id_Systemu);
        setCurrentPatternName(pattern.name);
        setCurrentPatternIndex(index)
        setIsOpenedDrawer(true);
    };
    const SearchTextField = props.searchTextField;
    return (
        <>
            <Paper>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                    <Box m={3} minWidth={'600px'}>
                        {props.searchTextField && <SearchTextField array={patterns} setFilteredArray={setPatternsFiltered} />}
                        {/* <SearchTextField array={patterns} setFilteredArray={setPatternsFiltered} /> */}
                    </Box>
                    <Box m={3} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                        {(isImportShown === false)
                            ?
                            <Button startIcon={<DownloadIcon />} variant={'text'} sx={{ textTransform: 'none' }} onClick={showImport}>Importuj</Button>
                            :
                            <Box mb={1} ml={1}>
                                <ImportPatternButton apiurl={props.apiurl} apiAuthHeader={props.apiAuthHeader} getAllPatternsByIdSystemu={getAllPatternsByIdSystemu}></ImportPatternButton>

                            </Box>
                        }

                        <Button onClick={onAddButton} variant={'contained'} sx={{ textTransform: 'none', marginLeft: '10px' }}>Dodaj Wzorzec</Button>
                    </Box>
                </Box>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns?.map((column) => (
                                    <TableCell
                                        size='small'
                                        key={column.id}
                                        align={column.align}
                                        sx={column.sx}
                                    >
                                        <Typography textAlign={column.name === 'Akcja' && 'end'} variant="h4" fontWeight={'bold'}>{column.name}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                            {!isLoading && patternsFiltered
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((pattern, index) => {
                                    let bgColor = "";
                                    if (index % 2) {   //gdy wniosek jest parzysty to jest szary
                                        bgColor = 'neutral.50';
                                    }
                                    return (


                                        <TableRow key={index}
                                            onClick={() => onChooseRow(pattern, index)}
                                            onMouseEnter={() => handleShowButtons(pattern.id_Pattern)}
                                            onMouseLeave={() => handleShowButtons(-1)}
                                            sx={{
                                                cursor: 'pointer',
                                                backgroundColor: bgColor,
                                                "&:hover": { backgroundColor: "primary.50" },
                                                height: '65px',
                                            }}>
                                            <TableCell sx={{ paddingLeft: '25px' }}>{pattern.id_Pattern}</TableCell>
                                            <TableCell sx={{ wordWrap: "break-word", maxWidth: "100px" }}>{pattern.name}</TableCell>
                                            <TableCell sx={{ marginLeft: '5px' }}>{pattern.id_Systemu}</TableCell>
                                            <TableCell sx={{ marginLeft: '5px' }}>{pattern.typ_Wzorca}</TableCell>
                                            <TableCell sx={{ wordWrap: "break-word", maxWidth: "150px" }}>{pattern.description}</TableCell>
                                            <TableCell sx={{ minWidth: '120px', paddingRight: "15px" }}>{pattern.id_Pattern === showButtons ?
                                                <Box sx={{ float: 'right' }}>
                                                    <CustomIconButton sx={{ color: 'secondaryColors.500' }} size='small'><ExportPatternButton apiurl={props.apiurl} apiAuthHeader={props.apiAuthHeader} patternId={pattern.id_Pattern}></ExportPatternButton></CustomIconButton>
                                                    <CustomIconButton sx={{ color: 'secondaryColors.500' }} size='small'><PrinterButton apiurl={props.apiurl} printParams={props.printParams} apiAuthHeader={props.apiAuthHeader} patternId={pattern.id_Pattern}></PrinterButton></CustomIconButton>
                                                    {permission === true ? <CustomIconButton onClick={() => props.navigation("/wzorce/lista/patternEditor/" + pattern.id_Pattern)} sx={{ color: 'secondaryColors.500' }} size='small'><ModeIcon titleAccess='Edytuj' fontSize="inherit" /></CustomIconButton> : null}
                                                    {permission === true ? <CustomIconButton onClick={(e) => handleShowModal(pattern.id_Pattern, pattern.id_Systemu, pattern.name, e)} sx={{ color: 'secondaryColors.500' }} size='small'><DeleteIcon titleAccess='Usuń' fontSize="inherit" /></CustomIconButton > : null}
                                                </Box>
                                                : null}
                                            </TableCell>
                                        </TableRow>

                                    )
                                })
                            }
                        </TableBody>

                    </Table>
                    {isLoading &&
                        <Box width={'100%'} display={'flex'} justifyContent={'center'} >
                            <SpinnerWithoutText />
                        </Box>
                    }
                </TableContainer>
                {patterns ? <PaginationComponent
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    data={patterns} /> : ''}
            </Paper>
            <DeleteModal open={showModal} handleClose={handleCloseModal} currentid_Pattern={currentid_Pattern} currentPatternName={currentPatternName} currentPatternid_Systemu={currentPatternid_Systemu} handleDeleteModal={handleDeleteModal} />
            <PatternPreviewer apiurl={props.apiurl} apiAuthHeader={props.apiAuthHeader}
                isOpened={isOpenedDrawer} setIsOpened={setIsOpenedDrawer}
                handleShowModal={handleShowModal}
                patterns={patternsFiltered}
                setCurrentPatternIndex={setCurrentPatternIndex}
                permission={permission}
                pattern={{ idPattern: currentid_Pattern, idSystemu: currentPatternid_Systemu, name: currentPatternName, index: currentPatternIndex }}
            />
        </>
    );
}

export default PatternListFunc;