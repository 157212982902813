import { createContext } from 'react';


const ExpandContext = createContext({
    expanded: [],
    setExpanded: () => { }
});

export const ExpandProvider = ExpandContext.Provider

export default ExpandContext
