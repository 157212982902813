import Axios from 'axios'
import apiUrl from '../apiurl';
import { authHeader } from '../config/authHeader';

export const apiService = {

    getDaneRodzajeNieobecnosci, getDaneWnioskiPodrzedne, getWszystkieUrlopy, addWniosek, getZastepstwa,
    changeWniosekStatus, getDanePracownika, getFotoPracownikaRequest, getInfo, getBadania, getDaneWnioski, getNadgodziny, getNadgodzinySzczegolowe,
    getPodsumowanieRoku, getNotyfikacjeSystemowe, getPozyczki, getWynagrodzenia, getPity, getNieobecnosci, getDostepnyUrlopByWniosek,
    getDostepneNadgodziny, getZatrudnienie, getDaneStruktura, getDanePrac, changePassword, getSzkolenia, getInfoZKadr, getStrukturaSzczegolowe, getApiVersion, getHistoriaWniosku, getNieobecnosciZastepujacego, getPrzelozeni, getNieobecnosciPracownikow, getMojeNieobecnosci, getNieobecnosciWszystkichPracownikow, setNieobecnosciCSV, getStatusy

}


function getDaneRodzajeNieobecnosci() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/DaneRodzajeNieobecnosci`, requestOptions);
    return response
}

function getDaneWnioskiPodrzedne(props) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/Wniosek/GetWnioskiPodrzedne2?idPrac=` + props.idPrac, requestOptions);
    return response
}


function getDanePracownika() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/DanePracownika`, requestOptions);
    return response
}

// function getAktualnieDostepnyUrlop(props) {
//     const requestOptions = { method: 'GET', headers: authHeader() };
//     const response = Axios.get(`${apiUrl}api/WymiarUrlopu/GetAktualnieDostepnyUrlop2?idPrac=` + props.idPrac, requestOptions);

//     return response
// }

function getWszystkieUrlopy(props) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/WymiarUrlopu/GetWszystkieUrlopy2?idPrac=` + props.idPrac, requestOptions);
    return response
}

function addWniosek(wniosek) {
    const requestOptions = { method: 'POST', headers: authHeader() };
    return Axios.post(`${apiUrl}api/Wniosek/AddWniosek2`, JSON.stringify(wniosek), requestOptions);

}

function getZastepstwa() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/Wniosek/GetZastepstwa2`, requestOptions);
    return response
}

function changeWniosekStatus(wniosek) {
    const requestOptions = { method: 'PUT', headers: authHeader() };
    return Axios.put(`${apiUrl}api/Wniosek/Zatwierdzanie2`, JSON.stringify(wniosek), requestOptions);
}
function getFotoPracownikaRequest() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = new Request(`${apiUrl}api/DanePracownika/GetFoto`, requestOptions);
    return response
}

function getInfo(props) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/Wynagrodzenia/GetInfo?idPrac=` + props.idPrac, requestOptions);
    return response
}

function getBadania(props) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/Badania?idPrac=` + props.idPrac, requestOptions);
    return response

}

function getPozyczki(props) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/Pozyczki?idPrac=` + props.idPrac, requestOptions);
    return response
}

function getWynagrodzenia(props) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/Wynagrodzenia/GetCalyRok?idPrac=` + props.idPrac + `&rok=` + props.rok, requestOptions);
    return response
}

function getPity(props) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/Pity?idPrac=` + props.idPrac, requestOptions);
    return response
}
function getDaneWnioski() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/Wniosek/GetWnioski2`, requestOptions);
    return response
}

function getZatrudnienie(props) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/Zatrudnienie?idPrac=` + props.idPrac, requestOptions);
    return response
}


function getNadgodziny(props) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/Nadgodziny?idPrac=` + props.idPrac, requestOptions);
    return response
}
function getNadgodzinySzczegolowe(props) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/Nadgodziny/GetSzczegoly?idPrac=` + props.idPrac + `&idNadgodziny=` + props.idNadgodziny, requestOptions);
    return response
}

function getPodsumowanieRoku(props) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/WymiarUrlopu/GetPodsumowanieRoku2?idPrac=` + props.idPrac, requestOptions);
    return response
}

function getNotyfikacjeSystemowe(props) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/Notyfikacje/GetNotyfikacjeSystemowe?idPrac=` + props.idPrac, requestOptions);
    return response
}
function getNieobecnosci(props) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    var _kodRodz = ""
    if (props.KodRodzajuNieobecnosci)
        _kodRodz = '&rodzajeNieobecnosci=' + props.KodRodzajuNieobecnosci
    const response = Axios.get(`${apiUrl}api/Nieobecnosci?idPrac=` + props.idPrac + `&rok=` + props.rok + _kodRodz, requestOptions);
    return response
}


function getDostepnyUrlopByWniosek(idWniosku) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/WymiarUrlopu/GetDostepnyUrlopByWniosek2?idWniosku=` + idWniosku, requestOptions);
    return response
}

function getDostepneNadgodziny(props) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/WymiarUrlopu/GetDostepneNadgodziny2?idPrac=` + props.idPrac, requestOptions);
    return response
}
function getDaneStruktura() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/Struktura`, requestOptions);
    return response
}

function getDanePrac() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/DanePrac`, requestOptions);
    return response
}

function changePassword(model) {
    const requestOptions = { method: 'PUT', headers: authHeader() };
    return Axios.put(`${apiUrl}api/Auth/ChangePwd`, model, requestOptions);
}

function getSzkolenia(props) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/Szkolenie?idPrac=` + props.idPrac, requestOptions);

    return response
}
function getInfoZKadr(props) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return Axios.get(`${apiUrl}api/Info?idPrac=` + props.idPrac, requestOptions);
}

function getStrukturaSzczegolowe(props) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/Struktura/GetSzczegoly?idPrac=` + props, requestOptions);
    return response
}
function getApiVersion() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return Axios.get(`${apiUrl}api/VersionInfo`, requestOptions);
}

function getHistoriaWniosku(props) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return Axios.get(`${apiUrl}api/Wniosek/GetHistoria?idPrac=` + props.idPrac + `&idWniosek=` + props.idWniosk, requestOptions)
}

function getNieobecnosciZastepujacego(props) {
    const requestOptions = { method: 'POST', headers: authHeader() };
    const response = Axios.post(`${apiUrl}api/Wniosek/GetNieobecnosciZastepujacego`, JSON.stringify(props), requestOptions);
    return response
}

function getNieobecnosciPracownikow(props) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return Axios.get(`${apiUrl}api/Nieobecnosci/NieobecnosciPracownika?idPracPracownika=` + props.idPracPracownik + `&idPrac=` + props.idPrac, requestOptions);
}

function getPrzelozeni(props) {
    const requestOptions = { method: 'POST', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/Wniosek/GetPrzelozeni?idPrac=` + props, requestOptions);
    return response
}

function getMojeNieobecnosci(props) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/Nieobecnosci/MojeNieobecnosci?idPrac=` + props, requestOptions)
    return response
}

function getStatusy() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/Wniosek/GetStatusy`, requestOptions)
    return response
}

function getNieobecnosciWszystkichPracownikow(props) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    const response = Axios.get(`${apiUrl}api/Nieobecnosci/Wszystkie?rok=` + props.year + `&miesiac=` + props.month, requestOptions);
    return response
}

function setNieobecnosciCSV(nieobecnosci) {
    const requestOptions = { method: 'POST', headers: authHeader() };
    const response = Axios.post(`${apiUrl}api/Nieobecnosci/GetNieobecnosciCsv`, nieobecnosci, requestOptions);
    return response

}