import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as ExpandIcon } from "../../assets/icons/Expand_up.svg";

function TwojAdresEmailAccordion(props) {

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography variant="h3" m={2} fontWeight={'700'}>Twój adres E-mail</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box ml={2} mb={1} mt={-2} style={{ maxWidth: "350px" }}>
                    <TextField
                        label="Twój obecny adres e-mail:"
                        value={props.email}
                        size="small"
                        disabled
                        fullWidth
                    />
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

export default TwojAdresEmailAccordion;