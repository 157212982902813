import React, { forwardRef, Suspense, useImperativeHandle, useRef } from 'react';
import { Box, Toolbar } from '@mui/material';
import NotificationIconContent from './NotificationIconContent';
import UserAvatarContent from './UserAvatarContent';
import { Timer } from '../services/Timer';


const HeaderContent = forwardRef((props, ref) => {
    const childRef = useRef();

    const MenuContent = props.menuModule;

    useImperativeHandle(ref, () => ({ resetTimer() { childRef.current.resetTimer() } }));

    return (
        <Toolbar>
            {props.config.logOutTime ? <Box mr={1}><Timer ref={childRef} /></Box> : null}
            {/* <NotificationIconContent /> */}
            {props.menuModule ? <MenuContent removeToken={props.removeToken} /> : <UserAvatarContent removeToken={props.removeToken} />}
        </Toolbar>
    )
})

export default HeaderContent