import axios from "axios";
import apiurl from "../../../apiurl";
const appPrefix = localStorage.getItem('appPrefix');

export function addToken (username, password, setFailLogin) {
    return function(dispatch) {
        return axios.post(apiurl + "api/Auth/Login", {
            username,
            password,
        }).then(response => {
            dispatch({
                type: "add",
                payload: response.data,
            });
            localStorage.setItem(appPrefix + 'LoginResponse', JSON.stringify(response.data));
        }).catch(error => {
            setFailLogin(true);
            dispatch({ 
                type: "remove",
            });
        });
    }
}

export const addExistingToken = (loginData) => {
    return (dispatch) => {
        dispatch({
            type: "add",
            payload: loginData,
        });
    }
}


export const removeToken = () => {
    return (dispatch) => {
        dispatch({
            type: "remove"
        });
        localStorage.removeItem(appPrefix + 'LoginResponse');
        if (localStorage.getItem(appPrefix + 'idPrac')) {
            localStorage.removeItem(appPrefix + 'idPrac');

        }
        window.location.reload()
    }
}